<div *ngIf="permissions?.staffCollections?.view"class="row mb-4 align-items-center" style="margin-top: -130px">
  <div class="col-lg-6">
    <h3 class="">Staff Collection</h3>
  </div>
</div>

<div class="card" *ngIf="permissions?.staffCollections?.view">
  <div class="card-header py-3">
    <div class="row">
      <div class="col">
        <h4>Interval</h4>
      </div>
      <div [ngClass]="searchVal ? 'd-none' : 'd-flex-inline'" class="col text-right">
        <p class="mb-0 small">Current Data</p>
        <h5 class="mr-0">All Time</h5>
      </div>
      <div [ngClass]="searchVal ? 'd-flex-inline' : 'd-none'" class="col text-right">
        <button (click)="clear()" class="btn btn-danger btn-sm"
                type="button">
          Clear
        </button>
      </div>
    </div>
  </div>
  <div class="card-body">
    <div class="row">
    <div class="col-md-6 col-sm-12">
      <input placeholder="Click to enter date" required type="text"
      class="form-control" type="date" [(ngModel)]="newStartDate" />
      <label>From:</label>
    </div>
    <div class="col-md-6 col-sm-12">
      <input placeholder="Click to enter date" required type="text"
      class="form-control" type="date" [(ngModel)]="newEndDate" />
      <label>To:</label>
    </div>
    </div>
    <div class="col-md-12 col-sm-12 d-flex justify-content-center">
      <button (click)="getInputDate()"
      [disabled]="false"
      class="btn btn-success"
      style="padding:10px 15%;"
      type="submit">

      <span class="spinner-border-sm" role="status"></span>
      Search
    </button>
  </div>
  <!-- <div class="col text-right" *ngIf="searchVal">
    <button (click)="clear()" class="btn btn-danger btn-sm"
            type="button">
      Clear
    </button>
  </div> -->
  <!-- <div class="card-body" *ngIf="permissions?.staffCollections?.view">
    <form (submit)="onIntervalSubmit($event)" action="">
      <div class="row">
        <div class="col-md-6 col-sm-12">
          <input class="form-control" id="from" onblur="(this.type='text')"
                 onfocus="(this.type='date')"
                 placeholder="Click to enter date" required type="text">
          <label for="from">From:</label>
        </div>
        <div class="col-md-6 col-sm-12">
          <input class="form-control" id="to" onblur="(this.type='text')"
                 onfocus="(this.type='date')"
                 placeholder="Click to enter date" required type="text">
          <label for="to">To:</label>
        </div>
        <div class="col-md-12 col-sm-12 d-flex justify-content-center">
          <button [disabled]="false"
                  class="btn btn-success"
                  style="padding:10px 15%;"
                  type="submit">

            <span *ngIf="this.loading" class="spinner-border spinner-border-sm" role="status"></span>
            Search<span *ngIf="this.loading" class="load-text">ing...</span>
          </button>
        </div>
      </div>
    </form>
  </div> -->
</div>

<div class="row justify-content-center" *ngIf="permissions?.staffCollections?.view">
  <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6">
    <div class="card">
      <div class="card-header bg-billed text-center border-top-radius" style="background-color: rgb(37, 37, 233);">
        <h5 class="">Total Amount billed</h5>
      </div>
      <div class="card-body text-center">
        <h5>{{ currencyBase }} {{getBilledTotal | number: "1.2"}}</h5>
      </div>
    </div>
  </div>
  <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6">
    <div class="card">
      <div class="card-header bg-paid text-center border-top-radius" style="background-color: rgb(25, 150, 25);">
        <h5 class="">Total In-App Payments Received</h5>
      </div>
      <div class="card-body text-center">
        <h5>{{ currencyBase }} {{getPaidInApp | number: "1.2"}}</h5>
      </div>
    </div>
  </div>

  <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6">
    <div class="card">
      <div class="card-header bg-portal text-center border-top-radius" style="background-color: rgb(251, 195, 40);">
        <h5 class="">Total Portal e-Payments Received</h5>
      </div>
      <div class="card-body text-center">
        <h5>{{ currencyBase }} {{getPortalPayment | number: "1.2"}}</h5>
      </div>
    </div>
  </div>
  <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6">
    <div class="card">
      <div class="card-header bg-arrears text-center border-top-radius" style="background-color: rgb(233, 38, 38);">
        <h5 class="">Total Arrears</h5>
      </div>
      <div class="card-body text-center">
        <h5>{{ currencyBase }} {{getArrears | number: "1.2"}}</h5>
      </div>
    </div>
  </div>
</div>

<div class="card" *ngIf="permissions?.staffCollections?.view">
  <div class="card-header">
    <div class="row">
      <div class="col">
        <h4>Details</h4>
      </div>
      <div class="col">
        <div class="d-flex justify-content-end align-items-center">
      <input class="form-control me-2" type="text" placeholder="Search..." [(ngModel)]="searchText" style="width: 200px;" />&nbsp;
      </div>
      </div>
      </div>
  </div>
  <div class="card-body" *ngIf="permissions?.staffCollections?.view">
    <table class="table table-bordered table-striped" id="report-table">
      <thead>
      <tr>
        <th style="padding-left: 10px; padding-right: 10px; text-transform: none">Staff</th>
        <th style="padding-left: 10px; padding-right: 10px; text-transform: none">Cases Created</th>
        <th style="padding-left: 10px; padding-right: 10px; text-transform: none">Billed </th>
        <th style="padding-left: 10px; padding-right: 10px; text-transform: none">Amount Collected</th>
        <!-- <th style="padding-left: 10px; padding-right: 10px; text-transform: none">Action</th> -->
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let staff of getStaffData
                            | filter: searchText
                            | paginate: { itemsPerPage: 10, currentPage: p };">
        <th> {{staff.staff}}</th>
        <td>{{staff.totalCasesBilled}}</td>
        <td>{{ currencyBase }}{{staff.amount_billed | number: "1.2"}}</td>
        <td>{{ currencyBase }} {{staff.amount_received | number: "1.2"}}</td>
        <!-- <td>
          <button (click)="view(staff)" [placement]="'top'" class="btn btn-success btn-sm" data-backdrop="static"
                  id="viewBtn" ngbTooltip="VIEW">
            <i class="feather icon-eye"></i> View
          </button>
        </td> -->

      </tr>
      </tbody>
    </table>
  </div>
  <div class="card-footer">
    <pagination-controls
      (pageBoundsCorrection)="p = $event"
      (pageChange)="p = $event"
      autoHide="false"
      directionLinks="true"
      maxSize="9"
      nextLabel="Next"
      previousLabel="Previous"
      responsive="true"
      screenReaderCurrentLabel="You're on page"
      screenReaderPageLabel="page"
      screenReaderPaginationLabel="Pagination"
      style="float: right"
    >
    </pagination-controls>
  </div>
</div>
<!-- SEARCH FOR RESULTS -->
<div class="card" *ngIf="permissions?.staffCollections?.view">
  
  <div class="card-header py-2">
    <div class="row align-items-center">
      <div class="col">
        <h4>Search For Patient</h4>
      </div>
      <div class="col-md-4">
        <input class="form-control" id="search"
               placeholder="Enter Patient Name" type="text" [formControl]="searchForm">
              </div>
            </div>
            
   </div>
   <!-- <div class="card-header">
      <div class="row">
        <h4>Search For Patient</h4>
      </div>
  </div> -->
  <div class="card-body">
    <table class="table table-bordered table-striped" id="report-table" >
      <thead>
      <tr>
        <th style="padding-left: 10px; padding-right: 10px; text-transform: none">Patient Name</th>
        <th style="padding-left: 10px; padding-right: 10px; text-transform: none">Reports</th>
        <th style="padding-left: 10px; padding-right: 10px; text-transform: none">Staff Name</th>
        <th style="padding-left: 10px; padding-right: 10px; text-transform: none">Billed Amount </th>
        <th style="padding-left: 10px; padding-right: 10px; text-transform: none">Amount Collected</th>
        <th style="padding-left: 10px; padding-right: 10px; text-transform: none">Arrears</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let patient of patientItems">
        <td> {{patient.patient_name}} </td>
        <td> {{patient.clinical_module_items[0].form_name}} </td>
        <td> {{patient.staff_name}} </td>
        <td> {{ currencyBase }} {{patient.total_amount | number: "1.2"}} </td>
        <td> {{ currencyBase }} {{patient.amount_paid | number: "1.2"}}</td>
        <td> {{ currencyBase }} {{patient.arreas | number: "1.2"}} </td>
      </tr>
      </tbody>
    </table>
  </div>
  <div class="card-footer">
    <pagination-controls
      (pageBoundsCorrection)="p = $event"
      (pageChange)="p = $event"
      autoHide="false"
      directionLinks="true"
      maxSize="9"
      nextLabel="Next"
      previousLabel="Previous"
      responsive="true"
      screenReaderCurrentLabel="You're on page"
      screenReaderPageLabel="page"
      screenReaderPaginationLabel="Pagination"
      style="float: right"
    >
    </pagination-controls>
  </div>
</div>

<!-- -------------------------------------------------------------------------------- VIEW --------------------------------------------------------------------------------------- -->
<!-- <app-ui-modal #modalView [containerClick]="true" [dialogClass]="'modal-xl btn-page'" class="doNotPrint">
  <div class="app-modal-header">
    <h4 class="modal-title" style="color:#0873ee;text-transform:uppercase">Staff Report Details</h4>
  </div>
  <div class="app-modal-header">
    <button (click)="printElement(); modalView.hide();" class="btn btn-secondary" type="button">Print</button>&nbsp;
    <button  (click)="modalView.hide()" class="btn btn-danger" data-dismiss="modal" type="button">Close</button>
  </div>
  <div class="app-modal-body main-look">
    <div id="contentToPrint">
      <div class="row mb-4">
        <div class="col-sm-12">
          <img *ngIf="companyData?.company_logo !== null" alt=""
               src="{{ companyData?.company_logo }}" style="width: 100%; height: 160px;">
        </div>
      </div>

      <div class="">
        <h3 class="mb-0">Staff Report Details</h3>
        <h4 class="">{{selectedStaff?.staff}}</h4>
        <p *ngIf="searchVal" class="mt-0">From: {{fromDate}} - To: {{toDate}}</p>
        <hr>
      </div>

      <table class="table table-bordered table-striped doNotPrint" id="report-table">
        <thead>
        <tr>
          <th style="padding-left: 10px; padding-right: 10px">Specimen</th>
          <th style="padding-left: 10px; padding-right: 10px">Report ID</th>
          <th style="padding-left: 10px; padding-right: 10px">Billed</th>
          <th style="padding-left: 10px; padding-right: 10px">Amount Collected</th>
          <th style="padding-left: 10px; padding-right: 10px">Received By</th>
          <th style="padding-left: 10px; padding-right: 10px">Date Created</th>
        </tr>
        </thead>
        <tbody>
        <tr
          *ngFor="let rep of selectedStaff?.reports | paginate: { itemsPerPage: 8, currentPage: detailPage, totalItems: selectedStaff?.reports.length, id: 'details' }">
          <td><span>{{ rep.clinical_module[0].form_name?.replace("Form", "").trim() }}</span></td>
          <td><span>{{rep.clinical_module[0].pathology_number}}</span>
          </td>
          <td><span>GH₵ {{rep.total_amount | number: "1.2"}}</span></td>
          <td><span>GH₵ {{ rep.amount_paid === null ? 0 : rep.amount_paid| number: "1.2"}}</span></td>
          <td>
            <span>{{rep.amount_paid === null ? "No Payment" : rep.received_by === null ? "Portal Payment" : rep.received_by}}</span>
          </td>
          <td><span
            style="font-size:inherit;color:#0a661f;font-weight:bold;">{{ rep.created_at | date: "MMM d, y":"GMT"}}</span>
          </td>
        </tr>
        </tbody>
      </table>

      <table class="table table-bordered table-striped toPrint">
        <thead>
        <tr>
          <th style="padding-left: 10px; padding-right: 10px">Specimen</th>
          <th style="padding-left: 10px; padding-right: 10px">Report ID</th>
          <th style="padding-left: 10px; padding-right: 10px">Billed</th>
          <th style="padding-left: 10px; padding-right: 10px">Amount Collected</th>
          <th style="padding-left: 10px; padding-right: 10px">Received By</th>
          <th style="padding-left: 10px; padding-right: 10px">Date Created</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let rep of selectedStaff?.reports">
          <td><span>{{ rep.clinical_module[0].form_name?.replace("Form", "").trim() }}</span></td>
          <td><span>{{rep.clinical_module[0].pathology_number}}</span>
          </td>
          <td><span>GH₵ {{rep.total_amount | number: "1.2"}}</span></td>
          <td><span>GH₵ {{ rep.amount_paid === null ? 0 : rep.amount_paid| number: "1.2"}}</span></td>
          <td>
            <span>{{rep.amount_paid === null ? "No Payment" : rep.received_by === null ? "Portal Payment" : rep.received_by}}</span>
          </td>
          <td><span
            style="font-size:inherit;color:#0a661f;font-weight:bold;">{{ rep.created_at | date: "MMM d, y":"GMT"}}</span>
          </td>
        </tr>
        </tbody>
      </table>

    </div>
  </div>
  <div class="app-modal-footer">
    <pagination-controls
      (pageBoundsCorrection)="detailPage = $event"
      (pageChange)="detailPage = $event"
      autoHide="false"
      directionLinks="true"
      id="details"
      maxSize="9"
      nextLabel="Next"
      previousLabel="Previous"
      responsive="true"
      screenReaderCurrentLabel="You're on page"
      screenReaderPageLabel="page"
      screenReaderPaginationLabel="Pagination"
      style="float: left"
    >
    </pagination-controls>
    <button (click)="modalView.hide();" class="btn btn-danger" type="button">Close</button>
  </div>
</app-ui-modal> -->
<!-- ------------------------------------------------------------------------------ END VIEW ------------------------------------------------------------------------------------- -->
