<div *ngIf="permissions?.clients?.view " class="row mb-4 align-items-center" style="margin-top: -130px">
  <div class="col-lg-6">
    <h3 class="">Client Reports</h3>
  </div>
</div>


<div class="card" *ngIf="permissions?.clients?.view ">
  <div class="card-header py-3">
    <div class="row">
      <div class="col">
        <h4>Interval</h4>
      </div>
      <!-- <div [ngClass]="searchVal ? 'd-flex-inline' : 'd-none'" class="col text-right">
        <button (click)="clear()" class="btn btn-danger btn-sm"
                type="button">
          Clear
        </button>
      </div> -->
    </div>
  </div>
  <!-- <div class="card-body">
    <form (submit)="onIntervalSubmit($event)" action="">
      <div class="row">
        <div class="col-md-6 col-sm-12">
          <input class="form-control" id="from" onblur="(this.type='text')"
                 onfocus="(this.type='date')"
                 placeholder="Click to enter date" required type="text">
          <label for="from">From:</label>
        </div>
        <div class="col-md-6 col-sm-12">
          <input class="form-control" id="to" onblur="(this.type='text')"
                 onfocus="(this.type='date')"
                 placeholder="Click to enter date" required type="text">
          <label for="to">To:</label>
        </div>
        <div class="col-md-12 col-sm-12 d-flex justify-content-center">
          <button [disabled]="false"
                  class="btn btn-success"
                  style="padding:10px 15%;"
                  type="submit">

            <span *ngIf="this.loading" class="spinner-border spinner-border-sm" role="status"></span>
            Search<span *ngIf="this.loading" class="load-text">ing...</span>
          </button>
        </div>
      </div>
    </form>
  </div> -->

  <div class="card-body">
    <div class="row">
    <div class="col-md-6 col-sm-12">
      <input placeholder="Click to enter date" required type="text"
      class="form-control" type="date" [(ngModel)]="newStartDate" />
      <label>From:</label>
    </div>
    <div class="col-md-6 col-sm-12">
      <input placeholder="Click to enter date" required type="text"
      class="form-control" type="date" [(ngModel)]="newEndDate" />
      <label>To:</label>
    </div>
    </div>
    <div class="col-md-12 col-sm-12 d-flex justify-content-center">
      <button (click)="getInputDate()"
      [disabled]="false"
      class="btn btn-success"
      style="padding:10px 15%;"
      type="submit">

      <span class="spinner-border-sm" role="status"></span>
      Search
    </button>
  </div>
  <div class="col text-right" *ngIf="searchVal">
    <button (click)="clear()" class="btn btn-danger btn-sm" type="button">
      Clear
    </button>
  </div>
  </div>
</div>

<div class="row" *ngIf="permissions?.clients?.view || permissions?.keyMetrics?.view">
  <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6">
    <div class="card">
      <div class="card-body text-center">
        <h5 class="">Total Clients</h5>
        <hr>
        <h5>{{clientReportsList.length}}</h5>
      </div>
    </div>
  </div>
  <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6">
    <div class="card">
      <div class="card-body text-center">
        <h5 class="">Total Cases</h5>
        <hr>
        <h5>{{searchedClientList.length}}</h5>
      </div>
    </div>
  </div>
</div>

<div class="card" *ngIf="permissions?.clients?.view || permissions?.keyMetrics?.view">
  <div class="card-header">
    <div class="row">
      <div class="col">
        <h4>Details</h4>
      </div>
      <div class="col">
        <div class="d-flex justify-content-end align-items-center">
      <input class="form-control me-2" type="text" placeholder="Search..." [(ngModel)]="searchText" style="width: 200px;" />&nbsp;
      </div>
      </div>
      </div>
  </div>
  <div class="card-body" *ngIf="clientReportsList?.length > 0">
    <table class="table table-bordered table-striped" id="report-table">
      <thead>
      <tr>
        <!-- <th width="40%">{{ selectedDebtor?(selectedDebtor=='requesting facility'?'Facility':'Patient'):'Debtor' }}</th> -->
        <th style="padding-left: 10px; padding-right: 10px; text-transform: none">Facility</th>
        <!-- <th style="padding-left: 10px; padding-right: 10px; text-transform: none">No. Doctor</th> -->
        <th style="padding-left: 10px; padding-right: 10px; text-transform: none">Cases</th>
        <th style="padding-left: 10px; padding-right: 10px; text-transform: none">Billed</th>
        <th style="padding-left: 10px; padding-right: 10px; text-transform: none">Paid</th>
        <th style="padding-left: 10px; padding-right: 10px; text-transform: none">Arrears</th>
        <th style="padding-left: 10px; padding-right: 10px; text-transform: none">Action</th>
        <!-- <th (click)="changeOrder()" class="cursor" style="padding-left: 10px; padding-right: 10px" width="5%">total tat -->
        <!-- <span [ngClass]="desc ? 'fa-arrow-circle-o-up' : 'fa-arrow-circle-o-down'" class="fa "></span></th> -->
      </tr>
      </thead>
      <tbody>
      <!-- <tr *ngFor="let search of selectedDebtor?(selectedDebtor=='requesting facility'? results.facility:results.patient):''"> -->
      <tr *ngFor="let fac of clientReportsList
                            | filter: searchText
                            | paginate: { itemsPerPage: 10, currentPage: p };">
        <!-- {{fac.doctor}} -->
        <th> {{fac.client}}</th>
        <!-- <th>{{fac?.doctor}}</th> -->
        <td>{{fac.reports.length}}</td>
        <td>{{ currencyBase }} {{generalServices.getBilled(fac.reports) | number: "1.2"}}</td>
        <td>{{ currencyBase }} {{generalServices.getPaid(fac.reports) | number: "1.2"}}</td>
        <td>{{ currencyBase }} {{generalServices.getArrears(fac.reports) | number: "1.2"}}</td>
        <td>
          <button (click)="view(fac)" [placement]="'top'" class="btn btn-success btn-sm" data-backdrop="static"
                  id="viewBtn" ngbTooltip="VIEW">
            <i class="feather icon-eye"></i> View
          </button>&nbsp;
          
        </td>

      </tr>
      </tbody>
    </table>
  </div>
  <div class="card-footer pb-0">
    <pagination-controls
      (pageBoundsCorrection)="p = $event"
      (pageChange)="p = $event"
      autoHide="false"
      directionLinks="true"
      maxSize="10"
      nextLabel="Next"
      previousLabel="Previous"
      responsive="true"
      screenReaderCurrentLabel="You're on page"
      screenReaderPageLabel="page"
      screenReaderPaginationLabel="Pagination"
      style="float: right"
    >
    </pagination-controls>
  </div>
</div>

<!-- -------------------------------------------------------------------------------- VIEW --------------------------------------------------------------------------------------- -->
<app-ui-modal #modalView [containerClick]="false" [dialogClass]="'modal-xl btn-page'" class="doNotPrint">
  <div class="app-modal-header">
    <h4 class="modal-title" style="color:#0873ee;text-transform:uppercase">Client Report Details</h4>
  </div>
  <div class="app-modal-header">
    <button (click)="csvMe()"  class="btn btn-secondary" type="button" ngbTooltip="EXPORT">
      Export
    </button>&nbsp;
    <button (click)="printElement(); modalView.hide();" class="btn btn-secondary" type="button">Print</button>&nbsp;
    <button  (click)="modalView.hide()" class="btn btn-danger" data-dismiss="modal" type="button">Close</button>
  </div>
  <div class="app-modal-body main-look">
    <div id="contentToPrint">
      <div class="row mb-4">
        <div class="col-sm-12">
          <img *ngIf="companyData?.company_logo !== null" alt=""
               src="{{ companyData?.company_logo }}" style="width: 100%; height: 160px;">
        </div>
      </div>

      <div class="">
        <h3 class="mb-0">Client Report Details</h3>
        <h4 class="" >{{selectedClient?.client}}</h4>
        <p *ngIf="searchVal" class="mt-0">From: {{fromDate}} - To: {{toDate}}</p>
        <hr>
      </div>
      <table class="table table-bordered table-striped doNotPrint" id="report-table">
        <thead>
        <tr>
          <!-- <th width="40%">{{ selectedDebtor?(selectedDebtor=='requesting facility'?'Facility':'Patient'):'Debtor' }}</th> -->
          <th style="padding-left: 10px; padding-right: 10px" width="25%">Doctor</th>
          <th style="padding-left: 10px; padding-right: 10px" width="25%">Specimen</th>
          <th style="padding-left: 10px; padding-right: 10px" width="20%">Case ID</th>
          <th style="padding-left: 10px; padding-right: 10px" width="5%">Billed</th>
          <th style="padding-left: 10px; padding-right: 10px" width="25%">Paid</th>
          <th style="padding-left: 10px; padding-right: 10px" width="25%">Arrears</th>
          <th style="padding-left: 10px; padding-right: 10px" width="15%">Date Updated</th>
        </tr>
        </thead>
        <tbody>
        <!-- <tr *ngFor="let search of selectedDebtor?(selectedDebtor=='requesting facility'? results.facility:results.patient):''"> -->
        <tr
          *ngFor="let rep of selectedClient?.reports | paginate: { itemsPerPage: 8, currentPage: detailPage, totalItems: selectedClient?.reports.length, id: 'details' }">
          <td *ngIf="rep.requesting_doctor === null">n/a</td>
          <td *ngIf="rep.requesting_doctor">{{rep.requesting_doctor}}</td>
          <td><span *ngIf="rep.form_name">{{ rep?.form_name.replace("Form", "").trim() }}</span>
            <span *ngIf="rep.form_name === null">n/a</span>
          </td>
          <td><span>{{rep.case_id}}</span>
          </td>
          <td><span>₵{{rep.total_amount | number: "1.2"}}</span></td>
          <td><span>₵{{rep.amount_paid ? rep.amount_paid : 0 | number: "1.2"}}</span>
          </td>
          <td><span>₵{{rep.arreas | number: "1.2"}}</span>
          </td>
          <td><span
            style="font-size:inherit;color:#0a661f;font-weight:bold;">{{ rep.updated_at | date: "MMM d, y":"GMT"}}</span>
          </td>
        </tr>
        </tbody>
      </table>

      <table class="table table-bordered table-striped toPrint">
        <thead>
        <tr>
          <!-- <th width="40%">{{ selectedDebtor?(selectedDebtor=='requesting facility'?'Facility':'Patient'):'Debtor' }}</th> -->
          <th style="padding-left: 10px; padding-right: 10px" width="25%">Doctor</th>
          <th style="padding-left: 10px; padding-right: 10px" width="25%">Specimen</th>
          <th style="padding-left: 10px; padding-right: 10px" width="20%">Case ID</th>
          <th style="padding-left: 10px; padding-right: 10px" width="5%">Billed</th>
          <th style="padding-left: 10px; padding-right: 10px" width="25%">Paid</th>
          <th style="padding-left: 10px; padding-right: 10px" width="25%">Arrears</th>
          <th style="padding-left: 10px; padding-right: 10px" width="15%">Date Updated</th>
        </tr>
        </thead>
        <tbody>
        <!-- <tr *ngFor="let search of selectedDebtor?(selectedDebtor=='requesting facility'? results.facility:results.patient):''"> -->
        <tr *ngFor="let rep of selectedClient?.reports">
          <td *ngIf="rep.requesting_doctor === null">n/a</td>
          <td *ngIf="rep.requesting_doctor">{{rep.requesting_doctor}}</td>
          <td><span *ngIf="rep.form_name">{{ rep?.form_name.replace("Form", "").trim() }}</span>
            <span *ngIf="rep.form_name === null">n/a</span>
          </td>
          <td><span>{{rep.case_id}}</span>
          </td>
          <td><span>₵{{rep.total_amount | number: "1.2"}}</span></td>
          <td><span>₵{{rep.amount_paid ? rep.amount_paid : 0 | number: "1.2"}}</span>
          </td>
          <td><span>₵{{rep.arreas | number: "1.2"}}</span>
          </td>
          <td><span
            style="font-size:inherit;color:#0a661f;font-weight:bold;">{{ rep.updated_at | date: "MMM d, y":"GMT"}}</span>
          </td>
        </tr>
        </tbody>
      </table>

    </div>
  </div>
  <div class="app-modal-footer">
    <pagination-controls
      (pageBoundsCorrection)="detailPage = $event"
      (pageChange)="detailPage = $event"
      autoHide="false"
      directionLinks="true"
      id="details"
      maxSize="9"
      nextLabel="Next"
      previousLabel="Previous"
      responsive="true"
      screenReaderCurrentLabel="You're on page"
      screenReaderPageLabel="page"
      screenReaderPaginationLabel="Pagination"
      style="float: left"
    >
    </pagination-controls>
    <button (click)="modalView.hide();" class="btn btn-danger" type="button">Close</button>
  </div>
</app-ui-modal>
<!-- ------------------------------------------------------------------------------ END VIEW ------------------------------------------------------------------------------------- -->


