import { Injectable } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import "src/assets/jconfirm/js/jquery-confirm";
import { AlertService } from "../others/services/alert.service";
import { AuthenticationService } from "../others/services/authentication.service";
import "jquery";
import Swal from 'sweetalert2';


declare let $: any;

@Injectable({
  providedIn: "root",
})
export class GeneralService {
  constructor(
    public auth: AuthenticationService,
    private alert: AlertService,
    private spinner: NgxSpinnerService
  ) {}

  errorAlert(content: any) {
    $.confirm({
      icon: "fa fa-warning",
      title: "Error",
      theme: "modern",
      closeIcon: true,
      animation: "scale",
      content: content,
      backgroundDismiss: true,
      type: "red",
      buttons: {
        cancel: {
          isHidden: true,
          isDisabled: true,
        },
      },
    });
  }

  delete(specimenID, api) {
    Swal.fire({
      icon: "warning",
      title: "Are you sure?",
      text: "This record will be deleted and moved to trash",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Proceed",
      cancelButtonText: "Cancel",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          icon: "warning",
          title: "Deleting Record",
          text: "Record deleted will include billing record, do you wish to proceed",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          confirmButtonText: "Delete Anyway",
          cancelButtonText: "Cancel",
          allowOutsideClick: false,
        }).then((deleteResult) => {
          if (deleteResult.isConfirmed) {
            this.softDeleteSpecimen(specimenID, api);
          }
        });
      }
    });
  }
  

  softDeleteSpecimen(specimenID, api) {
    this.spinner.show();
    this.auth.destroy(api, specimenID).subscribe(
      (response) => {
        this.spinner.hide();
        this.alert.success(response["message"]);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
        // this.getSpecimens();
      },
      (error) => {
        this.spinner.hide();
        this.alert.error("Error deleting data, please try again.");
      }
    );
  }

  onPermanentDel(specimenID, api) {
    const del = $.confirm({
      icon: "fa fa-warning",
      title: "Deleting Record",
      theme: "modern",
      closeIcon: true,
      animation: "scale",
      content: "This record will be deleted permanently",
      type: "red",
      buttons: {
        subs: {
          text: "Delete Anyway",
          btnClass: "btn-danger",
          keys: ["enter"],
          isHidden: false,
          isDisabled: false,
          action: () => {
            this.permanentDelFxn(specimenID, api);
            del.close();
          },
        },
        cancel: {
          text: "Cancel",
          action: () => {
            del.close();
          },
        },
      },
    });
  }

  permanentDelFxn(specimenID, api) {
    this.spinner.show();
    this.auth.destroy(api, specimenID).subscribe(
      (response) => {
        this.spinner.hide();
        this.alert.success(response["message"]);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      },
      (error) => {
        // console.log(error);
        this.spinner.hide();
        this.alert.error("Error deleting data, please try again.");
      }
    );
  }

  deletePerm(userId, api){
    this.spinner.show();
    this.auth.destroy(api, userId).subscribe(
      (response) => {
        this.spinner.hide();
        this.alert.success(response["message"]);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      },
      (error) => {
        this.spinner.hide();
        this.alert.error("Error deleting data, please try again.");
      }
    );
  }

  onPermanentDelUser(userId, api) {
    const del = $.confirm({
      icon: "fa fa-warning",
      title: "Deleting Record",
      theme: "modern",
      closeIcon: true,
      animation: "scale",
      content: "This record will be deleted permanently",
      type: "red",
      buttons: {
        subs: {
          text: "Delete Anyway",
          btnClass: "btn-danger",
          keys: ["enter"],
          isHidden: false,
          isDisabled: false,
          action: () => {
            this.deletePerm(userId, api);
            del.close();
          },
        },
        cancel: {
          text: "Cancel",
          action: () => {
            del.close();
          },
        },
      },
    });
  }

  /**
   * It takes an array of objects, loops through the array, and adds the value of the total_amount property of each object
   * to a variable
   * @param {any} lists - any - this is the array of objects that you want to get the total amount from.
   * @returns The total amount of all the lists.
   */
  getBilled(lists: any) {
    let a = 0;
    for (const list of lists) {
      a += list.total_amount;
    }

    return a;
  }

  /**
   * It takes an array of objects, loops through the array, and adds the amount_paid property of each object to a variable
   * @param {any} lists - any - this is the array of objects that you want to get the total of.
   * @returns The total amount paid for all the lists.
   */
  getPaid(lists: any) {
    let a = 0;
    for (const list of lists) {
      a += list.amount_paid;
    }

    return a;
  }

  /**
   * It takes an array of objects, loops through the array, and adds the value of the arreas property of each object to a
   * variable
   * @param {any} lists - any - this is the array of objects that we are going to loop through.
   * @returns The total amount of arrears for all the lists.
   */
  getArrears(lists: any) {
    let a = 0;
    for (const list of lists) {
      a += list.arreas;
    }

    return a;
  }

  emptyDateFieldError() {
    Swal.fire({
      icon: 'warning',
      title: 'Submission Error',
      text: 'The from and to fields must not be empty. Please select a date for each.',
      confirmButtonColor: '#d33', 
    });
  }
}
