export class NextConfig {
  public static config = {
    layout: "vertical", // vertical, horizontal
    subLayout: "", // horizontal-2
    collapseMenu: localStorage.getItem('collapseMenu')=="true" ? true : localStorage.getItem('collapseMenu')=="false" ? false : localStorage.setItem('collapseMenu','false'), // true of false
    layoutType: localStorage.getItem('layoutType') ? localStorage.getItem('layoutType') : localStorage.setItem('layoutType','menu-dark'), // menu-dark, menu-light, dark
    headerBackColor: localStorage.getItem('headerBackColor') ? localStorage.getItem('headerBackColor') : localStorage.setItem('headerBackColor','reset'), // background-blue, background-red, background-purple, background-info, background-dark
    rtlLayout: localStorage.getItem('rtlLayout')=="true" ? true : localStorage.getItem('rtlLayout')=="false" ? false : localStorage.setItem('rtlLayout','false'),
    navFixedLayout: localStorage.getItem('navFixedLayout')=="true" ? true : localStorage.getItem('navFixedLayout')=="false" ? false : localStorage.setItem('navFixedLayout','false'),
    headerFixedLayout: (localStorage.getItem('headerFixedLayout')=="true" || localStorage.getItem('headerFixedLayout')==null) ? false : localStorage.getItem('headerFixedLayout')=="false" ? false : localStorage.setItem('headerFixedLayout','true'),
    boxLayout: localStorage.getItem('boxLayout')=="true" ? true : localStorage.getItem('boxLayout')=="false" ? false : localStorage.setItem('boxLayout','false'),
  };
}
 