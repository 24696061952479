<div class="row">
    <div class="col-lg-6" *ngFor="let pathology of patient?.pathology">
        <div class="dio-card">
            <div class="dio-header">
                <div>
                    <p>
                        <a (click)="redirectTo(pathology)">
                            {{pathology?.form_name}}
                        </a>
                    </p>
                </div>
                <div>
                    <p>{{pathology?.created_at | date }}</p>
                </div>
            </div>
            <div *ngIf="pathology?.diagnosis">
                <p [innerHTML]="pathology?.diagnosis | sanitizer"></p>
                <!-- <p>{{ pathology?.diagnosis | sanitizer}}</p> -->
            </div>
        </div>
    </div>
</div>
<!-- <div class="row" >
    <div class="col-lg-6" *ngFor="let pathology of patient?.pathology">
        {{pathology?.form_name}}
    </div>
    
</div> -->