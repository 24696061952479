import { RouterModule, Routes } from "@angular/router";
import { NgModule } from "@angular/core";
import { TatComponent } from "./tat/tat.component";
import { ReceivablesComponent } from "./receivables/receivables.component";
import { StaffCollectionComponent } from "./staff-collection/staff-collection.component";
import { DiscountsComponent } from "./discounts/discounts.component";
import { PerformanceComponent } from "./performance/performance.component";
import { ClientReportsComponent } from "./client-reports/client-reports.component";
import { KeyMetricsComponent } from "./key-metrics.component";
import { ExpensesComponent } from "./expenses/expenses.component";
import { BalanceComponent } from "./balance/balance.component";
import { CasesComponent } from "./cases/cases.component";
import { CategoryComponent } from "./category/category.component";

const routes: Routes = [
  {
    path: "",
    component: KeyMetricsComponent,
  },
  {
    path: "tat",
    component: TatComponent,
  },
  {
    path: "receivables",
    component: ReceivablesComponent,
  },
  {
    path: "staff-collection",
    component: StaffCollectionComponent,
  },
  {
    path: "discounts",
    component: DiscountsComponent,
  },
  {
    path: "performance",
    component: PerformanceComponent,
  },
  {
    path: "client-reports",
    component: ClientReportsComponent,
  },
  {
    path: "expenses",
    component: ExpensesComponent,
  },
  {
    path: "balance",
    component: BalanceComponent,
  },
  {
    path: "cases",
    component: CasesComponent,
  },
  {
    path: "category",
    component: CategoryComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class KeyMetricsRouting {}
