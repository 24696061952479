import { Injectable } from '@angular/core';
import { AuthenticationService } from './authentication.service';
import { OnInit } from '@angular/core';
@Injectable({
  providedIn: 'root'
})



export class UserroutesService implements OnInit {


  ngOnInit(){
   
  }

  constructor(public auth: AuthenticationService) { }
  getPermission(){
   return this.auth.get("user_permissions/" + sessionStorage.getItem("userID")).pipe((a) => a)

  }


}
