import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ClientReportsComponent } from "./client-reports/client-reports.component";
import { DiscountsComponent } from "./discounts/discounts.component";
import { PerformanceComponent } from "./performance/performance.component";
import { ReceivablesComponent } from "./receivables/receivables.component";
import { StaffCollectionComponent } from "./staff-collection/staff-collection.component";
import { TatComponent } from "./tat/tat.component";
import { KeyMetricsComponent } from "./key-metrics.component";
import { KeyMetricsRouting } from "./key-metrics.routing";
import { NgxPaginationModule } from "ngx-pagination";
import { SharedModule } from "../../theme/shared/shared.module";
import {
  NgbButtonsModule,
  NgbDropdownModule,
  NgbTabsetModule,
  NgbTooltipModule,
} from "@ng-bootstrap/ng-bootstrap";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { NgxStripeModule } from "ngx-stripe";
import { ToastrModule } from "ngx-toastr";
import { NgxSpinnerModule } from "ngx-spinner";
import { HttpClientModule } from "@angular/common/http";
import { QuillModule } from "ngx-quill";
import { Ng2SearchPipeModule } from "ng2-search-filter";
import { NgSelectModule } from "@ng-select/ng-select";
import { DataTablesModule } from "angular-datatables";
import { ExpensesComponent } from './expenses/expenses.component';
import { BalanceComponent } from './balance/balance.component';
import { NameReplacePipe } from "src/app/pipe/name-replace.pipe";
import { AuthenticationService } from "src/app/others/services/authentication.service";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CasesComponent } from './cases/cases.component';
import { CategoryComponent } from './category/category.component';


@NgModule({
  declarations: [
    ClientReportsComponent,
    DiscountsComponent,
    PerformanceComponent,
    ReceivablesComponent,
    StaffCollectionComponent,
    TatComponent,
    KeyMetricsComponent,
    ExpensesComponent,
    BalanceComponent,
    NameReplacePipe,
    CasesComponent,
    CategoryComponent
  ],
  imports: [
    CommonModule,
    KeyMetricsRouting,
    SharedModule,
    NgbDropdownModule,
    NgbTooltipModule,
    NgbButtonsModule,
    NgbTabsetModule,
    PerfectScrollbarModule,
    ReactiveFormsModule,
    FormsModule,

    NgxStripeModule.forRoot("pk_test_ZA9wEYR4CLSY8vH0dKhU8XFs00vJ8hDytz"),
    ToastrModule.forRoot(),
    NgxSpinnerModule,
    HttpClientModule,
    QuillModule.forRoot(),
    NgxPaginationModule,
    Ng2SearchPipeModule,
    NgSelectModule,
    DataTablesModule,
  ],
  exports: [KeyMetricsComponent],
  providers: [AuthenticationService]
})
export class KeyMetricsModule {}
