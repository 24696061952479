import { FormControl, Validators, FormGroup } from '@angular/forms';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AuthenticationService } from 'src/app/others/services/authentication.service';
import { AlertService } from 'src/app/others/services/alert.service';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss'],
})
export class SupportComponent implements OnInit {
  user_array = (str) => {
    return str.trim().split(",");
   };
  singleCurrency = this.user_array(sessionStorage.getItem("currency"));
  currencyNme = this.singleCurrency[0];
  currencySym = this.singleCurrency[1];
  currencyBase = this.singleCurrency[2];

  @ViewChild('requestAttachmentFiles', {static: false}) requestAttachmentFiles: ElementRef; files  = [];

  // Set form data variables
  requestForm = new FormGroup({
    requestFormType: new FormControl('', Validators.required),
    requestType: new FormControl('', Validators.required),
    requestOtherType: new FormControl(''),
    requestTitle: new FormControl('', [Validators.required]),
    requestDetails: new FormControl('', Validators.required),
    requestFileAttachment: new FormControl(''),
    requestFileAttachment2: new FormControl(''),
    requestFileAttachment3: new FormControl('')
  });

  email = '';
  fileNames = '';
  fileNames2 = '';
  fileNames3 = '';
  userID = sessionStorage.getItem('userID');
  user = {};
  disableSubmitButton = false;

  // Form Data
  title = '';
  formType = '';
  typeOfRequest = '';
  otherTypeOfRequest = '';
  details = '';
  base64file = '';
  attachment1: any;
  attachment2: any;
  attachment3: any;
  attachment1type: any;
  attachment2type: any;
  attachment3type: any;

  constructor(private spinner: NgxSpinnerService, private auth: AuthenticationService, private alert: AlertService) { }

  ngOnInit() {
    // Get current user details
    this.auth.get('user/' + this.userID).subscribe(
      (response) => {
        this.user = response['data'];
      },
      (error) => {
        this.disableSubmitButton = true;
        this.alert.error('Something went wrong. Try again later');
      }
    );
  }

  // Get files
  getFiles() {
    document.getElementById('requestAttachmentFiles').click();
  }

  getFiles2() {
    document.getElementById('requestAttachmentFiles2').click();
  }

  getFiles3() {
    document.getElementById('requestAttachmentFiles3').click();
  }

  // Set  file names
  setFileNames() {
    // Reset file names
    this.fileNames = '';
    this.attachment1 = '';
    this.fileNames = document.getElementById('requestAttachmentFiles')['files'][0].name;
    this.attachment1type = document.getElementById('requestAttachmentFiles')['files'][0].type;
  }

  setFileNames2() {
    this.fileNames2 = '';
    this.attachment2 = '';
    this.fileNames2 = document.getElementById('requestAttachmentFiles2')['files'][0].name;
    this.attachment2type = document.getElementById('requestAttachmentFiles2')['files'][0].type;
  }

  setFileNames3() {
    this.fileNames3 = '';
    this.attachment3 = '';
    this.fileNames3 = document.getElementById('requestAttachmentFiles3')['files'][0].name;
    this.attachment3type = document.getElementById('requestAttachmentFiles3')['files'][0].type;
  }

  fileChangeEvent(event: any) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = this.touchup.bind(this);
    reader.readAsBinaryString(file);
  }

  touchup(data) {
    const binaryString = data.target.result;
    if(this.attachment1type == 'application/pdf'){
      this.attachment1 = "data:application/pdf;base64," + btoa(binaryString);
    } else {
      this.attachment1 = "data:image/jpeg;base64," + btoa(binaryString);
    }
  }

  fileChangeEvent2(event: any) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = this.touchup2.bind(this);
    reader.readAsBinaryString(file);
  }

  touchup2(data) {
    const binaryString = data.target.result;
    if(this.attachment2type == 'application/pdf'){
      this.attachment2 = "data:application/pdf;base64," + btoa(binaryString);
    } else {
      this.attachment2 = "data:image/jpeg;base64," + btoa(binaryString);
    }
  }

  fileChangeEvent3(event: any) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = this.touchup3.bind(this);
    reader.readAsBinaryString(file);
  }

  touchup3(data) {
    const binaryString = data.target.result;
    if(this.attachment3type == 'application/pdf') {
      this.attachment3 = "data:application/pdf;base64," + btoa(binaryString);
    } else {
      this.attachment3 = "data:image/jpeg;base64," + btoa(binaryString);
    }
  }

  // This is to get data from forms
  setFormData() {
    // this.email = "vansofori07@yahoo.com";
    this.email = "support@nubiaemr.com";
    this.title = this.requestForm.get('requestTitle').value;
    this.formType = this.requestForm.get('requestFormType').value;
    this.typeOfRequest = this.requestForm.get('requestType').value;
    this.otherTypeOfRequest = this.requestForm.get('requestOtherType').value;
    this.details = this.requestForm.get('requestDetails').value;
  }

  // SubmitRequest
  submitRequest() {
    document.getElementById('submitRequest').innerHTML = 'Submitting Request ...';
    document.getElementById('submitRequest').classList.add('disabled');
    this.setFormData();

    const formData = new FormData();
    this.spinner.show();
    formData.append('supportEmail', this.email);
    formData.append('user', sessionStorage.getItem('userID'));
    formData.append('name', this.user['othernames'] + ' ' + this.user['surname']);
    formData.append('phone', this.user['phone']);
    formData.append('email', this.user['email']);
    formData.append('request_title', this.title);
    formData.append('form_type', this.formType);
    formData.append('type_of_request', this.typeOfRequest);
    formData.append('other_type_of_request', this.otherTypeOfRequest);
    formData.append('details', this.details);

    // Check if user added attachments to request
    this.attachment1 != undefined ? formData.append('attachments1', this.attachment1) : '';
    this.attachment2 != undefined ? formData.append('attachments2', this.attachment2) : '';
    this.attachment3 != undefined ? formData.append('attachments3', this.attachment3) : '';

    this.auth.store('support', formData).subscribe(
      (response) => {
        this.alert.success(response['message']);
        document.getElementById('resetForm').click();
        document.getElementById('submitRequest').innerHTML = 'Submit Request';
        document.getElementById('submitRequest').classList.remove('disabled');
        document.getElementById('resetForm').click();
        this.spinner.hide();
      },
      (error) => {
        this.alert.error('Something went wrong. Please try again later');
        document.getElementById('submitRequest').innerHTML = 'Submit Request';
        document.getElementById('submitRequest').classList.remove('disabled');
        this.spinner.hide();
      }
    );
  }
}
