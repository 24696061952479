import { Component, OnInit } from "@angular/core";
import { isAfter, isBefore, isEqual } from "date-fns";
import { NgxSpinnerService } from "ngx-spinner";
import { AuthenticationService } from "src/app/others/services/authentication.service";
import { GeneralService } from "src/app/services/general.service";
import "jquery";

declare let $: any;

@Component({
  selector: "app-discounts",
  templateUrl: "./discounts.component.html",
  styleUrls: ["./discounts.component.scss"],
})
export class DiscountsComponent implements OnInit {
  discList: any = [];
  searchDiscList: any = [];
  searchFacList: any = [];
  selectedFacilities: any = [];
  searchText: "";
  p: string | number = 0;
  loading: any = false;
  desc: any = false;
  searchVal: any = false;

  singleCurrency = "";
  currencyNme: any;
  currencySym: any;
  currencyBase: any;
  companyData: any;

  fromDate: any;
  toDate: any;
  facilities: any;

  generalServices: any;

  actionButtons = true;

  permissions: any = [];

  constructor(
    public auth: AuthenticationService,
    private spinner: NgxSpinnerService,
    private generalService: GeneralService
  ) {}

  ngOnInit() {
    this.getPermissions();
    this.singleCurrency = this.userArray(sessionStorage.getItem("currency"));
    this.currencyNme = this.singleCurrency[0];
    this.currencySym = this.singleCurrency[1];
    this.currencyBase = this.singleCurrency[2];
    this.getDiscount();
    this.generalServices = this.generalService;
    this.getComProfile();
  }

  getPermissions() {
    this.auth.get('user_permissions/' + sessionStorage.getItem('userID')).subscribe(
      (response: any) => {
        this.permissions = response.permissions.permissions[0];
      },
      (error: any) => {

      }
    );
  }
    // Get user permissions
    // getPermissions() {
    //   this.permissions = {
    //     clients: {
    //       view: false
    //     },
    //   };

    //   this.auth
    //     .get("user_permissions/" + sessionStorage.getItem("userID")).toPromise()
    //     .then(
    //       (response: any) => {
    //         this.permissions = response.permissions.permissions[0];
    //         this.actionButtons = Object.keys(this.permissions.clients).every((key) => {
    //           return key == "create" ? false : this.permissions.clients[key];
    //         })
    //       }
    //     );
    // }


  userArray(str) {
    return str.trim().split(",");
  }

  /**
   * It gets the discount list from the server and then it loops through the list and pushes the data into an array
   */
  getDiscount() {
    this.spinner.show();
    this.auth.get("discounts").subscribe((response: any) => {
      const disc = response.discount;
      this.searchFacList = response.requesting_facilities;
      const a = [];
      for (const discElement of disc) {
        a.push({
          ...discElement.clinical_module_items[0],
          created_at: discElement.created_at,
          discount: discElement.discount,
          discountAmt:
            (parseFloat(discElement.discount) / 100) * discElement.total_amount,
          patient_name: discElement.patient_name,
          total_amount: discElement.total_amount,
        });
      }
      this.searchDiscList = this.discList = a;
    });

    this.spinner.hide();
  }

  /**
   * It takes an array of objects, and returns the average of the discount property of each object
   * @param {any} lists - any - this is the array of objects that we are going to pass in.
   * @returns The average discount of the list of items.
   */
  getAvgDisc(lists: any) {
    return this.getTotalDisc(lists) / lists.length;
  }

  /**
   * It gets the average discount per item in the list
   * @param {any} lists - the array of objects that you want to get the average discount percentage of.
   * @returns The average discount percentage of all the items in the list.
   */
  getAvgDiscPer(lists: any) {
    const a = (this.getAvgDisc(lists) / this.getTotalDisc(lists)) * 100;

    return a.toString().includes(".") ? a.toFixed(2) : a;
  }

  /**
   * It takes an array of objects, loops through each object, and adds the value of the discountAmt property to a variable
   * @param {any} lists - any - this is the array of objects that you want to get the total of.
   * @returns The total discount amount of all the items in the list.
   */
  // getTotalDisc(lists: any) {
  //   let a = 0;
  //   for (const list of lists) {
  //     a += list.discountAmt;
  //   }

  //   return a;
  // }

  getTotalDisc(lists: any) {
    let a = 0;
    for (const list of lists) {
      if (list && list.discountAmt !== undefined && !isNaN(list.discountAmt)) {
        a += list.discountAmt;
      }
    }
    return a;
  }
  

  /**
   * It takes the date from the input fields and filters the recList array to only include the elements that have a
   * created_at date that is equal to or after the from date and equal to or before the to date
   * @param {Event} $event - Event - This is the event that is triggered when the form is submitted.
   */
  onSearchSubmit($event: Event) {
    $event.preventDefault();
    const fromVal = $("#from").val();
    const toVal = $("#to").val();
    const facility = $("#facility").val();

    if (fromVal !== "" && toVal !== "") {
      this.searchVal = true;
      this.loading = true;

      const from = new Date(fromVal);
      const to = new Date(toVal);

      this.fromDate = from.toDateString();
      this.toDate = to.toDateString();

      this.searchDiscList = this.discList.filter((ele) => {
        const createdAt = new Date(new Date(ele.created_at).toDateString());
        return (
          ele.requesting_facility ===
            this.selectedFacilities.find(
              (fin) => fin.facility_full_name === ele.requesting_facility
            )?.facility_full_name &&
          (isEqual(from, createdAt) || isAfter(createdAt, from)) &&
          (isEqual(to, createdAt) || isBefore(createdAt, to))
        );
      });

      this.loading = false;
    } else {
      this.generalService.emptyDateFieldError();
    }
  }

  /**
   * We're creating a new div element, appending it to the body, copying the contents of the element we want to print into
   * the new div, printing the new div, and then removing the new div from the body
   */
  printElement() {
    const elem = document.getElementById("printContent");
    const domClone = elem.cloneNode(true);

    const $printSection = document.createElement("div");
    $printSection.style.padding = "15px 5px";
    $printSection.id = "printSection";
    document.body.appendChild($printSection);

    $printSection.innerHTML = "";
    $printSection.appendChild(domClone);
    window.print();
    $printSection.style.padding = "0px";
    $printSection.innerHTML = "";
  }

  /**
   * The function takes in a CSV file data as an argument, creates a CSV file data in an array, creates a user-defined
   * function to download CSV file, merges the data with CSV, displays the created CSV data on the web browser, provides
   * the name for the CSV file to be downloaded, and finally, reloads the page
   * @param {any} csvFileData - any - This is the data that will be converted to CSV.
   */
  csvMe(csvFileData: any) {
    // create CSV file data in an array
    let dataKey = null;
    let dataVal = null;

    dataVal = csvFileData.map((obj) => {
      dataKey = Object.keys(obj);
      return Object.values(obj);
    });

    // create a user-defined function to download CSV file
    let csv = `${dataKey}\n`;

    // merge the data with CSV
    dataVal.forEach((row) => {
      csv += row.join(",");
      csv += "\n";
    });
    // display the created CSV data on the web browser
    document.write(csv);
    const hiddenElement = document.createElement("a");
    hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csv);
    hiddenElement.target = "_blank";

    // provide the name for the CSV file to be downloaded
    const date = new Date().toLocaleDateString("en-GB");
    hiddenElement.download = `Discounts-[${date}].csv`;
    hiddenElement.style.visibility = "hidden";
    hiddenElement.style.display = "none";
    document.body.appendChild(hiddenElement);
    hiddenElement.click();
    document.body.removeChild(hiddenElement);
    location.reload();
  }

  /**
   * The function clears the search fields and resets the search results to the original list
   */
  clear() {
    $("#to").val("");
    $("#from").val("");
    this.searchDiscList = this.discList;
    this.searchVal = false;
    this.desc = true;
    // this.changeOrder();
  }

  /**
   * This function is used to get the company profile data from the database
   */
  getComProfile() {
    this.spinner.show();
    this.auth.get("facility_profile").subscribe(
      (response: any) => {
        if (response.company !== null) {
          this.companyData = response.company;
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        // console.log(error);
      }
    );
  }

  onChangeFacility($event: any) {
    this.selectedFacilities = [$event];
  }
}
