import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LabStudiesComponent } from './lab-studies.component';

const routes: Routes = [
  {
    path:"",
    component:LabStudiesComponent
  },
  {
    path:":id",
    component:LabStudiesComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LabStudiesRoutingModule { }
