<ngx-spinner *ngIf="permissions.clinicalModules.access" bdColor="rgba(51, 51, 51, 0.8)" size="medium" color="#fff"
  type="square-jelly-box">
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>

<div class="row" *ngIf="permissions.clinicalModules.access">
  <div class="col-sm-12">
    <app-card [hidHeader]="true" cardClass="card-datatable">
      <div *ngIf="page=='root'" class="row align-items-center m-l-0">
        <button *ngIf="permissions.radiology.delete" [routerLink]="archiveUrl"
          class="col-sm-2 offset-sm-10 btn btn-outline-danger btn-sm  has-ripple"><i class="feather icon-inbox"></i>
          View Trash</button>
          <div *ngIf="permissions.radiology.add" class="col-sm-10">
            <ng-select (search)="getPatients($event.term)" (clear)="getPatients()" (change)="patientSelected()"
              [(ngModel)]="selected_patient" [items]="patientsBuffer" [virtualScroll]="true" [loading]="loading"
              bindLabel="displayName" bindValue="id" placeholder="Select Patient" appendTo="body"
              (scroll)="onScroll($event)" (scrollToEnd)="onScrollToEnd()"></ng-select>
          </div>
        <!-- <div class="col-sm-2 text-right"> -->
        <button *ngIf="permissions.radiology.add" (click)="create();" [disabled]="!isSelected"
          class="col-sm-2 btn btn-success btn-sm btn-round has-ripple"><i class="feather icon-plus"></i> Add New
          Specimen <span *ngIf="fetching" class="spinner-border spinner-border-sm" role="status"></span></button>
        <!-- </div> -->

      </div>

      <p></p>
      <div class="table-responsive">
        <span style="margin-right: 2px;font-weight: bold;" (click)="toggleSidebar()">Edit Table Column(s):</span>
        <i class="fas fa-cog fa-1x" style="color: #0873ee;" (click)="toggleSidebar()"></i>

        <aside id="rightsidebar" class="right-sidebar" [ngClass]="{'show': showSidebar, 'closed': !showSidebar}">
          <div class="demo-skin">
            <button class="close-btn" (click)="toggleSidebar()" style="background-color: rgb(252, 67, 0);"><i
                class="fas fa-times"></i></button>

            <br>

            <p style="font-weight: bold; text-align: center;"> Hide Table Column(s)</p>


            <div class="inner-right-sidebar">
              <ul class="setting-list list-unstyled m-t-20">
                <li>
                  <div class="form-check">
                    <div class="form-check m-l-10">
                      <label class="form-check-label">

                        <input class="form-check-input" type="checkbox" value="" [checked]="setID"
                          (change)="onChange($event, 'one')"> Case ID
                        <span class="form-check-sign">
                          <span class="check"></span>
                        </span>
                      </label>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="form-check">
                    <div class="form-check m-l-10">
                      <label class="form-check-label">
                        <input class="form-check-input" type="checkbox" value="" [checked]="setName"
                          (change)="onChange($event, 'one2')"> Name

                        <span class="form-check-sign">
                          <span class="check"></span>
                        </span>
                      </label>
                    </div>
                  </div>
                </li>
                <!-- <li>
                          <div class="form-check">
                            <div class="form-check m-l-10">
                              <label class="form-check-label">
                                <input class="form-check-input" type="checkbox" value="" [checked]="setRadiologyNumber" (change)="onChange($event, 'one3')"> Radiology Number

                                <span class="form-check-sign">
                                  <span class="check"></span>
                                </span>
                              </label>
                            </div>
                          </div>
                        </li> -->

                <li>
                  <div class="form-check">
                    <div class="form-check m-l-10">
                      <label class="form-check-label">
                        <input class="form-check-input" type="checkbox" value="" [checked]="setRequestingDoctor"
                          (change)="onChange($event, 'one4')"> Requesting Doctor

                        <span class="form-check-sign">
                          <span class="check"></span>
                        </span>
                      </label>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="form-check">
                    <div class="form-check m-l-10">
                      <label class="form-check-label">
                        <input class="form-check-input" type="checkbox" value="" [checked]="setRequestingFacility"
                          (change)="onChange($event, 'one5')"> Requesting Facility

                        <span class="form-check-sign">
                          <span class="check"></span>
                        </span>
                      </label>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="form-check">
                    <div class="form-check m-l-10">
                      <label class="form-check-label">

                        <input class="form-check-input" type="checkbox" value="" [checked]="setLastEdit"
                          (change)="onChange($event, 'one6')"> Last Edit


                        <span class="form-check-sign">
                          <span class="check"></span>
                        </span>
                      </label>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="form-check">
                    <div class="form-check m-l-10">
                      <label class="form-check-label">
                        <input class="form-check-input" type="checkbox" value="" [checked]="setReportStatus"
                          (change)="onChange($event, 'one7')"> Report Status

                        <span class="form-check-sign">
                          <span class="check"></span>
                        </span>
                      </label>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="form-check">
                    <div class="form-check m-l-10">
                      <label class="form-check-label">
                        <input class="form-check-input" type="checkbox" value="" [checked]="setPeerStatus"
                          (change)="onChange($event, 'one10')"> Peer Review
                        <span class="form-check-sign">
                          <span class="check"></span>
                        </span>
                      </label>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="form-check">
                    <div class="form-check m-l-10">
                      <label class="form-check-label">
                        <input class="form-check-input" type="checkbox" value="" [checked]="setBillStatus"
                          (change)="onChange($event, 'one8')"> Bill Status

                        <span class="form-check-sign">
                          <span class="check"></span>
                        </span>
                      </label>
                    </div>
                  </div>
                </li>

                <li>
                  <div class="form-check">
                    <div class="form-check m-l-10">
                      <label class="form-check-label">
                        <input class="form-check-input" type="checkbox" value="" [checked]="setActions"
                          (change)="onChange($event, 'one9')"> Actions

                        <span class="form-check-sign">
                          <span class="check"></span>
                        </span>
                      </label>
                    </div>
                  </div>
                </li>
              </ul>
            </div>

          </div>
        </aside>


        <br>









        <table [dtOptions]="dtOptions" class="table table-bordered table-striped mb-0" datatable id="report-table">
          <thead>
            <tr>
              <th width="5%" [ngClass]="{'tabCol': setID}" class="one">CASE ID</th>
              <th width="20%" [ngClass]="{'tabCol': setName}" class="one2">Name</th>
              <!-- <th width="5%" [ngClass]="{'tabCol': setRadiologyNumber}" class="one3">Radiolology Number</th> -->
              <th width="10%" [ngClass]="{'tabCol': setRequestingDoctor}" class="one4">Requesting Doctor</th>
              <th width="10%" [ngClass]="{'tabCol': setRequestingFacility}" class="one5">Requesting facility</th>
              <th width="20%" [ngClass]="{'tabCol': setLastEdit}" class="one6">Last Edit</th>
              <th width="10%" [ngClass]="{'tabCol': setReportStatus}" class="one7">Report Status</th>
              <th width="10%" [ngClass]="{'tabCol': setPeerStatus}" class="one10">Peer Review</th>
              <th width="10%" [ngClass]="{'tabCol': setBillStatus}" class="one8">Bill Status</th>
              <th class="one9" [ngClass]="{'tabCol': setActions}" *ngIf="actionButtons" width="10%">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let selected_specimen of specimens">
              <td [ngClass]="{'tabCol': setID}">&nbsp;&nbsp;{{ selected_specimen.case_id }}&nbsp;&nbsp;</td>
              <td [ngClass]="{'tabCol': setName}">&nbsp;&nbsp;{{ selected_specimen?.patient}}&nbsp;&nbsp;</td>
              <!--  <td [ngClass]="{'tabCol': setRadiologyNumber}">&nbsp;&nbsp;{{ selected_specimen?.pathology_number }}&nbsp;&nbsp;</td> -->
              <td [ngClass]="{'tabCol': setRequestingDoctor}">&nbsp;&nbsp;{{ selected_specimen.requesting_doctor
                }}&nbsp;&nbsp;</td>
              <td [ngClass]="{'tabCol': setRequestingFacility}">&nbsp;&nbsp;{{
                selected_specimen.requesting_facility}}&nbsp;&nbsp;</td>
              <td [ngClass]="{'tabCol': setLastEdit}">&nbsp;&nbsp;{{ selected_specimen.updated_at|date:'medium' }} by {{
                selected_specimen.user }}&nbsp;&nbsp;</td>
              <!-- <td>&nbsp;&nbsp;<label class="badge badge-{{ selected_specimen?.case_status != 'pending' && selected_specimen?.form_ready == 'Checked' ? 'primary' : selected_specimen?.form_ready == 'Checked' && selected_specimen?.case_status == 'pending' ? 'warning' : 'secondary' }}">
                      {{ selected_specimen?.case_status != 'pending' && selected_specimen?.form_ready == 'Checked' ? 'Finalized' : selected_specimen?.form_ready == 'Checked' && selected_specimen?.case_status == 'pending'? 'Draft Complete' : selected_specimen?.case_status == 'pending'||selected_specimen?.form_ready != 'Checked'?'Draft':'Draft'}}
                    </label>&nbsp;&nbsp;</td> -->
              <td [ngClass]="{'tabCol': setReportStatus}">
                &nbsp;&nbsp;<label
                  class="badge badge-{{ selected_specimen?.case_status == 'pending' && selected_specimen?.form_ready == 'Checked' ? 'warning' : selected_specimen?.case_status != 'pending' && selected_specimen?.downloaded == '1' ? 'info' : selected_specimen?.case_status != 'pending' ? 'finalised' : 'dark' }}">
                  {{ selected_specimen?.case_status == 'pending' && selected_specimen?.form_ready == 'Checked' ? 'Draft
                  Complete' : selected_specimen?.case_status != 'pending' && selected_specimen?.downloaded == '1' ?
                  'Downloaded' : selected_specimen?.case_status != 'pending' ? 'Finalised' :
                  'Draft'}}</label>&nbsp;&nbsp;
              </td>
              <td [ngClass]="{'tabCol': setPeerStatus}">
                &nbsp;&nbsp;<label
                  class="badge"
                  [ngClass]="{
                    'badge-secondary': selected_specimen?.peer_review == null,
                    'badge-success': selected_specimen?.peer_review == 'completed',
                    'badge-info-2': selected_specimen?.peer_review != null && selected_specimen?.peer_review != 'completed'
                  }">
                  {{ selected_specimen?.peer_review || 'Not Required'}}
                </label>&nbsp;&nbsp;
              </td>
              <td [ngClass]="{'tabCol': setBillStatus}">&nbsp;&nbsp;<label
                  class="badge badge-{{ selected_specimen?.bill_status == 'pending' ? 'danger' :selected_specimen?.bill_status == 'deleted'? 'secondary':'success' }}">{{
                  selected_specimen.bill_status}}</label>&nbsp;&nbsp;</td>
              <td [ngClass]="{'tabCol': setActions}" *ngIf="actionButtons">
                <button id="viewBtn" *ngIf="(page=='root'||page=='amendments') && permissions.radiology.view"
                  (click)="page=='amendments'?view(selected_specimen,true):view(selected_specimen);"
                  class="btn waves-effect waves-dark btn-secondary btn-icon" data-backdrop="static" [placement]="'top'"
                  ngbTooltip="VIEW">
                  <i class="feather icon-eye"></i>
                </button>

                &nbsp;

                <button *ngIf="page=='root' && permissions.radiology.edit" (click)="edit(selected_specimen);"
                  class="btn waves-effect waves-dark btn-warning btn-icon" data-backdrop="static" [placement]="'top'"
                  ngbTooltip="EDIT">
                  <i class="feather icon-edit"></i>
                </button>

                &nbsp;


                <button *ngIf="page=='archive' && permissions.radiology.edit" (click)="restore(selected_specimen);"
                  class="btn waves-effect waves-dark btn-info btn-icon" data-backdrop="static" [placement]="'top'"
                  ngbTooltip="UNDO">
                  <i class="feather icon-rotate-ccw"></i>
                </button>

                &nbsp;
                <button *ngIf="(page=='root') && permissions.radiology.delete" (click)="delete(selected_specimen, page)"
                  class="btn waves-effect waves-dark btn-danger btn-icon" data-backdrop="static" [placement]="'top'"
                  ngbTooltip="DELETE">
                  <i class="feather icon-trash"></i>
                </button>
              </td>
            </tr>
          </tbody>
          <tbody *ngIf="specimens?.length == 0">
            <tr>
              <td colspan="8" class="no-data-available">No matching records found</td>
            </tr>
          <tbody>
        </table>
      </div>
      <!-- <app-radio-form #form [action]="action"  [specimen]="specimen" (submited)='submit($event);'></app-radio-form> -->
      <app-ecg-report-form #form [action]="action" [specimen]="specimen" (preview)="preview($event)"
        (submited)='submit($event);'></app-ecg-report-form>
      <app-ui-modal #modalView [containerClick]="false" [dialogClass]="'modal-xl btn-page'" class="doNotPrint">

        <div class="app-modal-header">
          <h4 style="color:#0873ee;text-transform:uppercase" class="modal-title">View Specimen</h4>
          <h5 class="modal-title" style="float: left;">{{specimen?.case_id}}</h5>
          <h5 *ngIf="specimen.bill_arreas != '0' && specimen.bill_status != 'pending' " class="modal-title"
            style="color: red; margin-left: 25em;"> <span class="blink">Amount Owing: {{ currencyBase }} {{ currencySym
              }} {{ specimen.bill_arreas }}.00</span></h5>

        </div>
        <p #myButton (click)="downloadPDF()"></p>
        <div class="app-modal-header">

          <button type="button" class="btn btn-danger" data-dismiss="modal"  (click)="closeModalView()"><span
              class="fa fa-times-circle"> </span> Close</button>
        </div>
        <div class="app-modal-body main-look" id="htmlData" #viewElem>
          <!-- <div class="print-first-page"> -->
          <div class="no-gutters" *ngIf="this.comData?.use_letter_head == 1">
            <div class="col-sm-12">
              <!-- <img src="../../../assets/logo.jpg" style="width:100%;"> -->
              <img *ngIf="comData?.company_logo !== null" [src]="comData?.company_logo_64"
                style="width: 100%; height: 160px;">
            </div>
          </div>
          <div class="pdf-title2 row" style="text-align:center;">
            <div class="col-sm-12">
              <h5 style="color:white; text-transform: capitalize; font-size: x-large;"> RADIOLOGY REPORT </h5>
            </div>
          </div>

          <div class="p_info">
            <app-table-date [patient_first_name]="specimen?.patient?.first_name"
                [patient_last_name]="specimen?.patient?.last_name" [patient_gender]="specimen?.patient?.gender"
                [patient_date_of_birth]="specimen?.patient?.date_of_birth"
                [patient_folder_num]="specimen?.folder_number" [pathology_number]="specimen?.pathology_number"
                [date_specimen]="specimen?.date_specimen_received" [date_reported]="specimen?.date_reported"
                [date_edited]="specimen?.updatedAt" [scan_date]="specimen?.scan_date" [due_date]="specimen?.due_date">
              </app-table-date>
          </div>
          <style>
            pre p {
              margin-bottom: 0px !important;
            }
          </style>
          <div class="table-striped watermark">

            <div class="row">
              <div class="col-sm-12"><b class="table-wrp2">INDICATION: </b><span *ngIf="specimen?.indication"
                  class="info-wrp">{{specimen?.indication}}</span></div>
            </div>
            <div class="row">
              <div class="col-sm-6"><b class="table-wrp2">REQUESTING DOCTOR: </b>&nbsp;<span class="info-wrp">{{
                  specimen?.requesting_doctor }}</span>&nbsp;</div>
              <div class="col-sm-6"><b class="table-wrp2">REQUESTING FACILITY: </b>&nbsp;<span class="info-wrp">{{
                  specimen?.requesting_facility }}</span>&nbsp;</div>
            </div>
          </div>
          <br>

          <div class="row" style="text-align:center;background-color: #639cb3;">
            <div class="col-sm-12" style="background-color: #639cb3;">
              <h5 style="color:white; text-transform: capitalize; font-size: x-large;">ECHOCARDIOGRAPHY REPORT</h5>
            </div>
          </div>

          <div class="table-striped">
            <!--
                  <div class="row">
                    <div class="col-sm-12">
                      <ng-container *ngIf="specimen?.radio_text !== null && specimen?.radio_text !== undefined">
                        <quill-view-html style="width:100%;" [content]="specimen.radio_text" formControlName="specimen.radio_text"></quill-view-html>
                      </ng-container>
                    </div>
                  </div> -->

            <div class="col-sm-12" *ngIf="specimen?.radio_text">
              <ckeditor [disabled]="readonly" [(ngModel)]="specimen.radio_text" [config]="{ toolbar: [] }"
                [editor]="Editor" class="custom-ckeditor"></ckeditor>
            </div>

          </div>
          <br>
          <div class="row" style="text-align:center;background-color: #639cb3;">
            <div class="col-sm-12" style="background-color: #639cb3;">
              <h5 style="color:white; text-transform: capitalize; font-size: x-large;">IMPRESSION</h5>
            </div>
          </div>

          <div class="table-striped">
            <div class="row">
              <div class="col-sm-12">
                <ng-container *ngIf="specimen?.impression_other !== null && specimen?.impression_other !== undefined">
                  <quill-view-html style="width:100%;" [content]="specimen.impression_other"
                    formControlName="specimen.impression_other"></quill-view-html>
                </ng-container>
              </div>
            </div>


            <div *ngIf="specimen?.recommendation" class="row" style="text-align:center;background-color: #639cb3;">
              <div class="col-sm-12" style="background-color: #639cb3;">
                <h5 style="color:white;">RECOMMENDATION</h5>
              </div>
            </div>
            <div class="row" *ngIf="specimen?.recommendation">
              <div class="col-sm-12"><b class="table-wrp3">{{specimen?.recommendation != 'Other Specify'?
                  specimen?.recommendation:specimen.recommended_other}}</b></div>
            </div>&nbsp;
          </div>


          <div class="table-striped">

            <div class="row" *ngIf="specimen?.comments">
              <div class="col-sm-12"><b class="table-wrp" style="font-weight: 600;">Comment(s): </b>&nbsp;</div>
              <div class="col-sm-12">
                <ng-container
                  *ngIf="specimen?.comments !== null && specimen?.comments !== undefined && specimen.comments !== 'null'">
                  <quill-view-html style="width:100%;" [content]="specimen.comments"
                    formControlName="specimen.comments"></quill-view-html>
                </ng-container>
              </div>

              &nbsp;
            </div>
            <div class="row">
              <div class="d-flex" style="margin-top: 10px;">
                <div *ngFor="let item of this.specimen.scan_image let i = index" class="thumbnail_box"
                  style="overflow: hidden;">
                  <!-- <div *ngIf="item"> -->



                  <img (click)="zoomImage($event)" *ngIf="item.content || item.type != 'null'"
                    [src]="item.preview?.backgroundImage" alt="" id="preview_box" width="90" height="90"
                    class="d-block mx-auto my-auto">
                  <div *ngIf="item.content || item.type != 'null'" width="90" height="90"
                    (click)="downloadFile(item,i)"><i [class]="item.content" style="font-size:4rem;"></i>
                  </div>
                  <small
                    style="overflow: hidden; white-space: nowrap; max-width: 100px !important;text-overflow: ellipsis;">{{item.name}}</small>



                  <!-- </div> -->

                  <!-- The image Modal for Image pre-view pop up -->
                  <div id="myModal" class="modal">

                    <button (click)="downloadFile(item,i)" class="btn btn-primary" style="background-color: grey;"
                      id="downloadbtn"> Download Image </button>
                    <span class="close" (click)="closeImage()" style="color: rgba(231, 0, 0, 0.671) ;">&times;</span>
                    <img class="modal-content" id="img01">
                    <div id="caption"></div>

                  </div>

                </div>


              </div>
            </div>
            <br><br>
          <!-- <div class="row" style="margin-top: 100px" *ngIf="finalBoolean">
              <div class="col-sm-7"><b class="table-wrp" style="font-size: large;">
                </b><span class="td-wrp">{{ authPathologist }}</span>
                <span class="td-wrp" *ngIf="authOtherPathologist"> <i> </i>/{{
                  authOtherPathologist }}</span>
              </div>
              <div class="col-sm-5"><b class="table-wrp" style="font-size: large;">Authorised Signature:
                </b>&nbsp;<span class="td-wrp"><img
                  [src]="finalAuthSignature64" style="width: auto;height: 40px;"></span>&nbsp;</div>
            </div>




            <div class="row" style="margin-top: 100px" *ngIf="!finalBoolean">
              <div class="col-sm-7"><b class="table-wrp" style="font-size: large;">
                </b><span class="td-wrp">{{ specimen?.pathologists }}</span>
                <span class="td-wrp" *ngIf="specimen?.other_pathologists !== null"> <i> </i>/{{
                  specimen?.other_pathologists }}</span>
              </div>
              <div class="col-sm-5"><b class="table-wrp" style="font-size: large;" >
                <span *ngIf="specimen?.auth_signature !== null">Authorised Signature: </span>
                </b>&nbsp;<span class="td-wrp"><img *ngIf="specimen?.auth_signature !== 'null'"
                  [src]="specimen?.base_64_url" style="width: auto;height: 40px;"></span>&nbsp;</div>
            </div> -->

            <div *ngIf="finalBoolean">
              <div class="row" style="margin-top: 30px">
                <div class="col-sm-6" *ngIf="pathologists"><b class="table-wrp" style="font-size: 20px;">
                  <div class="" style="font-size: 20px;">Authorised Signature:
               &nbsp;<span class="td-wrp"><img [src]="finalAuthSignature64"
                      style="width: auto;height: 40px;"></span>&nbsp;</div>
                  </b><span class="td-wrp" style="font-size: 20px;">{{ pathologists }}</span>
                    <p style="font-size: 20px;"> {{pathologists_designation}} </p>
                </div>
              </div>
            </div>

            <div *ngIf="!finalBoolean">
              <div class="row" style="margin-top: 20px" >
                <div class="col-sm-6" *ngFor="let authData of specimen?.signatories">
                  <b class="table-wrp tb-sign" style="font-size: 20px;">
                  <div class="">Authorised Signature:
               &nbsp;<span class="td-wrp"><img [src]="authData?.signature"
                      style="width: auto;height: 40px;"></span>&nbsp;</div>
                  </b><span class="td-wrp tb-sign" style="font-size: 20px;">{{ authData?.name }}</span>
                    <p style="font-size: 20px;"> {{authData?.designation}} </p>
                </div>
              </div>
            </div>
        </div>

        <div class="footera mt-4" *ngIf="this.comData?.use_footer_image == 1">
            <div class="row">
                <div class="col-sm-12 no-gutters">
                    <div class="">
                        <img *ngIf="comData?.company_footer !== null" [src]="comData?.company_footer_64"
                            style="width: 100%; height: 160px;">
                    </div>
                </div>
            </div>
        </div>


        <div class="footer-gutter">
        </div>
        <div class="image-container ">
            <div *ngFor="let item of specimen.scan_image" style="margin-top: 20px;">
                <img [src]="item.preview?.backgroundImage" alt="" width="450" height="450"
                    style="margin: 0 10px;">
                <div>{{ item.name }}</div>
            </div>
        </div>

    </div>
    <div>

    </div>



    <div class="app-modal-body main-look">
      <hr>
        <div>
          <app-finalize-radiology [(isChecked)]="isChecked" [peerReview]="specimen.peer_review" (finalizeBoolean)="finalizeBoolean($event)"
          (finalizePayload)="addItem2($event)" (finalizePay)="addItem($event)" #finalizeRadiologyComponent>
          </app-finalize-radiology>
        </div>
  </div>

  <div class="app-modal-footer">

    <button type="button" class="btn btn-success" data-dismiss="modal"  (click)="openEdit(specimen);"><span
      class="fa fa-edit"> </span> Edit</button>

      <button *ngIf="permissions.radiology.add&&specimen.bill_status=='pending'" type="button"
          class="btn btn-warning doNotPrint" (click)="redirectToBill(specimen);"><span
              class="fa fa-money"> </span> Bill</button>

      <button type="button" class="btn btn-primary" (click)="updateDataFinalize(specimen);"
          [disabled]="!isButtonActive"><span class="fa-solid fa-circle-check"> </span> Finalize</button>


      <button
          *ngIf="permissions.radiology.add&&specimen.bill_status=='billed'&&specimen.payment_status != 'Full Payment'&&specimen.pay_method == 'Pre-Paid'"
          type="button" class="btn btn-info doNotPrint" (click)="redirectToPay(specimen);"><span
              class="fa fa-money"> </span> Pay Bill</button>
      <button
          *ngIf="permissions.radiology.print&&specimen.bill_status!='pending' && specimen.payment_status == 'Full Payment' && specimen.pay_method == 'Pre-Paid'"
          type="button" class="btn btn-secondary doNotPrint" (click)="printMe(); modalView.hide()"><span
              class="fa fa-print"> </span>
          Print</button>&nbsp;
      <button
          *ngIf="permissions.radiology.print&&specimen.bill_status!='pending' && specimen.pay_method == 'Post Paid'"
          type="button" class="btn btn-secondary doNotPrint" (click)="printMe(); modalView.hide()"><span
              class="fa fa-print"> </span>
          Print</button>&nbsp;
      <button
          *ngIf="specimen.bill_status!='pending' && specimen.pay_method == ' ' &&specimen.payment_status != 'Full Payment'"
          type="button" class="btn btn-info doNotPrint" (click)="redirectToPay(specimen);"><span
              class="fa fa-money"> </span> Pay Bill</button>&nbsp;
      <button
          *ngIf="permissions.radiology.print&&specimen.bill_status!='pending' && specimen.pay_method == ' ' &&specimen.payment_status == 'Full Payment'"
          type="button" class="btn btn-secondary doNotPrint" (click)="printMe(); modalView.hide()"><span
              class="fa fa-print"> </span>
          Print</button>&nbsp;
      <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="closeModalView()"><span
              class="fa fa-times-circle"> </span> Close</button>
  </div>
</app-ui-modal>
</app-card>
</div>
</div>
