import { Component, OnInit, ViewChild } from "@angular/core";
import { AuthenticationService } from "../../../others/services/authentication.service";
import { NgxSpinnerService } from "ngx-spinner";
import {
  addSeconds,
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInMonths,
  differenceInSeconds,
  differenceInWeeks,
  differenceInYears,
  isAfter,
  isBefore,
  isEqual,
} from "date-fns";
import "jquery";
import "src/assets/jconfirm/js/jquery-confirm";
import { GeneralService } from "../../../services/general.service";
import { Subject } from "rxjs";
import { DataTableDirective } from "angular-datatables";
import "rxjs/add/operator/map";

declare let $: any;

@Component({
  selector: "app-tat",
  templateUrl: "./tat.component.html",
  styleUrls: ["./tat.component.scss"],
})
export class TatComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;

  /* Lists or arrays */
  searchedPatientList: any = [];
  pathologyList: any = [];
  radiologyList: any = [];
  radiologySearchList: any = [];
  pathologySearchList: any = [];
  searchedTatList: any = [];
  specimenList: any = [];
  specimens: any = [];

  actionButtons = true;

  permissions: any = [];
  /* Figures */
  patientList = 0;
  lowestTat = "...";
  highestTat = "...";

  searchText: "";
  p: string | number = 0;
  loading: any = false;
  desc: any = false;
  searchVal: any = false;
  specPage: string | number = 0;
  tab = "pathology";
  year = new Date().getFullYear();

  dtOptions: DataTables.Settings = {};

  // We use this trigger because fetching the list of persons can be quite long,
  // thus we ensure the data is fetched before rendering
  dtTrigger: Subject<any> = new Subject<any>();

  /**
   * The constructor function is used to inject the AuthenticationService, NgxSpinnerService, and GeneralService into the
   * LoginComponent
   * @param {AuthenticationService} auth - AuthenticationService - This is the service that handles the authentication of
   * the user.
   * @param {NgxSpinnerService} spinner - NgxSpinnerService - This is the service that we imported from ngx-spinner.
   * @param {GeneralService} generalService - This is the service that will be used to make the API call.
   */
  constructor(
    public auth: AuthenticationService,
    private spinner: NgxSpinnerService,
    private generalService: GeneralService
  ) {}

  /**
   * The ngOnInit() function is a lifecycle hook that is called after Angular has initialized all data-bound properties of
   * a directive
   */
  ngOnInit() {
    this.getTat();
    this.getPermissions();
  }

 // Get user permissions
 getPermissions() {
  this.permissions = {
    tat: {
      view: false
    },
  };

  this.auth
    .get("user_permissions/" + sessionStorage.getItem("userID")).toPromise()
    .then(
      (response: any) => {
        this.permissions = response.permissions.permissions[0];
        this.actionButtons = Object.keys(this.permissions.tat).every((key) => {
          return key == "create" ? false : this.permissions.tat[key];
        })
      }
    );
}

  /**
   * It gets the TAT data from the API and then assigns the data to the variables in the component
   */
  getTat(): void {
    this.spinner.show();
    this.auth.get("tat").subscribe((response: any) => {
      const pat = response.pathology_forms;
      const rad = response.radiology_forms;
      // console.log(response);

      this.radiologySearchList = rad;
      this.pathologySearchList = pat;
      this.searchedTatList = this.pathologyList = this.getListYear(pat);
      if (this.searchedTatList.length > 0) {
        setTimeout(() => {
          this.specimenList = this.getSpecimens(this.searchedTatList);
          this.patientList = this.getPatientList(this.searchedTatList);
          this.getOrder();
        }, 1000);
      }
      this.radiologyList = this.getListYear(rad);
      this.dtTrigger.next();
      this.spinner.hide();
    });
  }

  /*ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }*/

  /*  trackByIndex(index) {
    return index;
  }

  trackByID(index, item) {
    return item.id;
  }*/

  /**
   * It takes an array of arrays as an argument and returns an array of objects
   * @param {any} lists - An array of arrays.
   * @returns an array of objects.
   */
  getListYear(lists: any) {
    const b = [];
    const date = new Date();

    const newList = lists.filter(
      (ele) => new Date(ele.created_at).getFullYear() === date.getFullYear()
    );

    /* Iterating through the array a and pushing the elements of a into b. It is also adding a new property called tat to
      each element of b. The value of the tat property is the difference between the updated_at and created_at
      properties of the element. */
    for (const aElement of newList) {
      const createdAt = new Date(aElement.created_at);
      const updatedAt = new Date(aElement.updated_at);
      const tat = differenceInSeconds(updatedAt, createdAt);
      const tatString = this.dateDiff(createdAt, updatedAt);
      b.push({ ...aElement, tat, tatString });
    }
    /* Sorting the array in descending order. */
    return b;
  }

  getList(lists: any) {
    const b = [];

    /* Iterating through the array a and pushing the elements of a into b. It is also adding a new property called tat to
      each element of b. The value of the tat property is the difference between the updated_at and created_at
      properties of the element. */
    for (const aElement of lists) {
      const createdAt = new Date(aElement.created_at);
      const updatedAt = new Date(aElement.updated_at);
      const tat = differenceInSeconds(updatedAt, createdAt);
      const tatString = this.dateDiff(createdAt, updatedAt);
      b.push({ ...aElement, tat, tatString });
    }

    return b;
  }

  /**
   * It takes two dates as arguments and returns a string with the difference between the two dates in years, months,
   * weeks, days, hours, minutes and seconds
   * @param {any} first - any, second: any
   * @param {any} second - The date to compare against.
   * @returns A string with the difference between the two dates.
   */
  dateDiff(first: any, second: any) {
    const a = new Date(first);
    const b = new Date(second);

    const week = differenceInWeeks(b, a);

    const secs = differenceInSeconds(b, a) % 60;
    const min = differenceInMinutes(b, a) % 60;
    const hrs = differenceInHours(b, a) % 24;
    const days = differenceInDays(b, a) % 7;
    const months = differenceInMonths(b, a) % 12;
    const weeks =
      months === 0 && week <= 4 ? week : differenceInWeeks(b, a) % 4;
    const yrs = differenceInYears(b, a);

    return yrs === 0 && months === 0 && days === 0
      ? "< day"
      : `${yrs !== 0 ? yrs + "yrs " : ""}${
          months !== 0 ? months + "mon " : ""
        }${weeks !== 0 ? weeks + "w " : ""}${
          days !== 0 ? days + "d " : ""
        }`.trim();
  }

  /**
   * It takes the date from the input fields and filters the tatList array to only include the elements that have a
   * created_at date that is equal to or after the from date and equal to or before the to date
   * @param {Event} $event - Event - This is the event that is triggered when the form is submitted.
   */
  onIntervalSubmit($event: Event) {
    $event.preventDefault();
    const fromVal = $("#from").val();
    const toVal = $("#to").val();

    if (fromVal !== "" && toVal !== "") {
      this.searchVal = true;
      this.loading = true;

      const from = new Date(new Date(fromVal).toDateString());
      const to = new Date(new Date(toVal).toDateString());

      /* Checking if the tab is pathology, and if it is, it is setting the selectedTabList to the pathologyList,
      and if it is not, it is setting the selectedTabList to the radiologyList. */
      const selectedTabList =
        this.tab === "pathology"
          ? this.pathologySearchList
          : this.radiologySearchList;

      /* Filtering the selectedTabList array to only include the elements that have a created_at date that is equal to or
      after the from date and equal to or before the to date. */
      const searchedList = selectedTabList.filter((ele) => {
        const createdAt = new Date(new Date(ele.created_at).toDateString());
        return (
          (isEqual(from, createdAt) || isAfter(createdAt, from)) &&
          (isEqual(to, createdAt) || isBefore(createdAt, to))
        );
      });

      this.searchedTatList = this.getList(searchedList);
      this.specimenList = this.getSpecimens(this.searchedTatList);
      this.patientList = this.getPatientList(this.searchedTatList);
      this.getOrder();
      this.clearPatientSearch();
      this.loading = false;
    } else {
      this.generalService.emptyDateFieldError();
    }
  }

  /**
   * The function clears the search fields and resets the search results to the original list
   */
  clear() {
    $("#to").val("");
    $("#from").val("");
    this.searchedTatList =
      this.tab === "pathology" ? this.pathologyList : this.radiologyList;
    this.specimenList = this.getSpecimens(this.searchedTatList);
    this.patientList = this.getPatientList(this.searchedTatList);
    this.getOrder();
    this.clearPatientSearch();
    this.searchVal = false;
    this.desc = true;
    // this.changeOrder();
  }

  /**
   * It takes an array of objects, loops through each object, and pushes the value of the patient key into a new array if
   * the value of the patient key is not already in the new array
   * @param {any} lists - any - this is the array of objects that we are going to loop through.
   * @returns An array of patients.
   */
  getPatientList(lists: any) {
    const patients = [];
    for (const list of lists) {
      if (!patients.includes(list.patient)) {
        patients.push(list.patient);
      }
    }

    return patients.length;
  }

  /**
   * It takes an array of objects, and returns an array of objects, where each object contains a form name and an array of
   * objects that have that form name
   * @param {any} lists - any - this is the list of all the specimens that you want to group.
   * @returns An array of objects with the form name and the forms.
   */
  getSpecimens(lists: any) {
    const a = [];
    const b = [];

    for (const list of lists) {
      if (!a.includes(list.form_name)) {
        a.push(list.form_name);
        const forms = lists.filter((ele) => ele.form_name === list.form_name);
        b.push({
          form: list.form_name,
          average: this.getSpecimenTotalTat(forms),
          forms,
        });
      }
    }

    return b;
  }

  /**
   * It takes an array of objects, loops through the array, adds the value of the tat property of each object to a
   * variable, then uses that variable to calculate the difference between the current date and the date that is the
   * current date plus the value of the variable
   * @param {any} lists - any - this is the list of specimen that will be used to calculate the total TAT
   * @returns The difference between the current date and the date after adding the total tat to the current date.
   */
  getSpecimenTotalTat(lists: any) {
    let a = 0;
    for (const list of lists) {
      a += list.tat;
    }

    const now = new Date();
    const average = a / lists.length;
    const date = addSeconds(now, average);

    return this.dateDiff(now, date);
  }

  /**
   * If the tab is not the same as the current tab, set the tab to the new tab and clear the form
   * @param {string} tab - string - The name of the tab that was clicked.
   */
  onTabChange(tab: string) {
    if (tab !== this.tab) {
      this.tab = tab;
      this.clear();
    }
  }

  getOrder(): void {
    const sorted = this.searchedTatList.sort((a, b) => {
      return b.tat - a.tat;
    });

    this.lowestTat = this.dateDiff(
      sorted[sorted.length - 1].created_at,
      sorted[sorted.length - 1].updated_at
    );
    this.highestTat = this.dateDiff(sorted[0].created_at, sorted[0].updated_at);
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

  onPatientSearch($event: Event) {
    const target = $event.target as HTMLTextAreaElement;
    const patient = target.value.trim();

    if (patient === "") {
      this.searchedPatientList = [];
    } else {
      this.searchedPatientList = this.searchedTatList.filter((ele) =>
        ele.patient.toLowerCase().includes(patient.toLowerCase())
      );
    }
  }

  clearPatientSearch() {
    this.searchedPatientList = [];
    $("#search").val("");
  }
}


