import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-table-date',
  templateUrl: './table-date.component.html',
  styleUrls: ['./table-date.component.scss']
})
export class TableDateComponent implements OnInit {
  constructor() {}
  @Input() patient_first_name = '';
  @Input() patient_last_name = '';
  @Input() patient_gender = '';
  @Input() patient_date_of_birth = '';
  @Input() patient_folder_num = '';
  @Input() pathology_number = '';
  @Input() date_specimen = '';
  @Input() date_reported = '';
  @Input() date_edited = '';
  @Input() scan_date = '';
  @Input() due_date = '';

  ngOnInit(): void {
    console.log(this.patient_first_name)
  }
}
