<!--====== NAVBAR TWO PART START ======-->
<section class="navbar-area sticky">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <nav class="navbar navbar-expand-lg">
          <a class="navbar-brand" href="#">
            <img [src]="img" alt="Logo" />
          </a>

          <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarTwo"
            aria-controls="navbarTwo"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="toggler-icon"></span>
            <span class="toggler-icon"></span>
            <span class="toggler-icon"></span>
          </button>

          <div class="collapse navbar-collapse sub-menu-bar" id="navbarTwo">
            <ul class="navbar-nav m-auto" style="margin-right: 0!important">
              <li data-click="home" class="nav-item active">
                <a
                routerLink=""
                >
                  home</a
                >
              </li>
            </ul>
          </div>
        </nav>
        <!-- navbar -->
      </div>
    </div>
    <!-- row -->
  </div>
  <!-- container -->
</section>
<!--====== NAVBAR TWO PART ENDS ======-->

<div class="container">
  <div style="position: relative; margin-top: 100px; margin-bottom: 40px;">
    <div>
      <h3 class="font-weight-bold mb-0 text-blue">
        NubiaEMR Privacy Policy
      </h3>
    </div>
    <hr>
    <p>
      NubiaEMR (or the App) respects the privacy of all users and collects only 
      the most important information needed to ensure quality user experience. 
      Through this privacy policy we intend to be transparent about what data we collect, 
      how we use it and how it is stored. By using NubiaEMR, you confirm your acceptance 
      of the following privacy policy. This app is offered to health service providers 
      (facilities) for the purposes of generating medical reports and distributing such 
      reports to their clients and patients. To register and use NubiaEMR the following 
      information is collected, stored and used.
      <br /><br />
      <strong class="text-center">Data We Collect</strong><br />
      We collect the following information from the facilities:<br />
      
          1. Email<br />
          2. Contact person details<br />
          3. Name<br />
          4. Phone number<br />
          5. List of services<br />
          6. Password<br />
          7. Mobile money number and name; Bank Account name and Bank Account Number. We do not store card details or One Time Passwords.<br />

      <br /><br />
      
      <strong class="text-center">How do we store Information?</strong><br />
      NubiaEMR uses globally renowned and secure cloud servers to store your information.<br />
      We use the latest and industry prescribed physical and digital security tools to ensure 
      all information you provide is secure and only available to you.<br />
      Employees of NubiaEMR are not permitted to change information you 
      provide and are not able to view any passwords or access codes provided by you.<br />
      We do not store payment information such as OTP (One Time Password) and CVV codes.<br />
      <br /><br />
      <strong class="text-center">For what do we use the collected information?</strong><br />
      Below is the specific purpose for each piece of information we collect.<br />
      1. Name: For identification of profile owners.<br />
      2. Email: As a primary identifier, for notifications and contacts.<br />
      3. Phone: For contact and One-Time Passwords (OTP).<br />
      4. Contact Person details: as the primary contact for an emergency.<br />
      5. List of services: to identify the type of services the facility provides. <br />
      6. Password: This is to secure user-data.<br />
      7. Mobile or bank details: for receipt of money payment for services.<br />
      8. Information collected during subscription permits secure payment processing and access to all subscription-linked services provided by the App.
      9. All User data is destroyed 60 days after subscription is terminated.<br />
      <br /><br />
  </div>
</div>

<!--====== FOOTER PART START ======-->

<section class="footer-area footer-dark">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-7">
          <div class="footer-logo text-center">
            <a class="mt-30" href=""
              ><img src="../../../assets/img/logo.png" alt="Logo"
            /></a>
          </div>
          <!-- footer logo -->
          <ul class="social text-center mt-60">
            <li>
              <a href="https://www.facebook.com/NubiaEMR/" target="_blank"
                ><i class="lni lni-facebook-filled"></i
              ></a>
            </li>
            <!-- <li><a href="#"><i class="lni lni-twitter-original"></i></a></li> -->
            <!-- <li><a href="#"><i class="lni lni-instagram-original"></i></a></li> -->
            <li>
              <a
                href="https://www.linkedin.com/company/73961138/admin/"
                target="_blank"
                ><i class="lni lni-linkedin-original"></i
              ></a>
            </li>
          </ul>
          <!-- social -->
          <div class="footer-support text-center">
            <span class="mail">support@nubiaemr.com</span>
          </div>
          <div class="copyright text-center mt-35">
            <h4 class="text">Copyright © {{copyright}} NubiaEMR™. All Rights Reserved.</h4>
          </div>
          <!--  copyright -->
        </div>
      </div>
      <!-- row -->
    </div>
    <!-- container -->
  </section>
  
  <!--====== FOOTER PART ENDS ======-->
