

<div class="container" *ngIf="userRole !== 'Administrator' && userRole !== 'Owner' && userRole !== 'Nurse' && userRole !== 'Staff'">
  <div class="form-group row">

  <div class="col-sm-6">
   
    <div class="custom-control custom-checkbox">
      <input type="checkbox" [checked]="isChecked" class="custom-control-input" id="ready_for_submission"
        (change)="checkSubmission($event) ; onPeerReviewCheck()" formControlName="submission_ready">
      <label class="custom-control-label" for="ready_for_submission">
        Check this box to confirm Report is Finalized and Ready (an SMS alert will be sent to Patient to
        pick-up or download report)
      </label>
    </div>
  </div>
  <br>
</div>

<form [formGroup]="pathSignature" *ngIf="finalize_form">
    <div class="form-group row">
      <div class="col-sm-6 col-md-3">
            <h5>Select Doctor/Reviewer</h5>
            <br>
            <div *ngIf="peerReviewMatch">
              <input style="border: 1px solid #ccc; background: #ccc;" type="text" class="form-control"
                formControlName="pathologists" id="pathologist">
              </div>
              <div *ngIf="!peerReviewMatch">
            <ng-select (change)="getUserName0($event)" style="border: 1px solid #ccc; background: #ccc;" class="form-control"
              formControlName="pathologists" [(ngModel)]="selectedDoctor" [items]="all_radiologists"
              placeholder="Select Doctor"></ng-select>
            </div>
      </div>
      <div class="col-sm-6 col-md-2">
       <h5>Designation</h5>
       <br>
       <div class="form-group"  style="font-size: 15px;">
        <select class="form-control" formControlName="designation" id="designation" (change)="designation();noReset()">
          <option value="" disabled>Select Designation</option>
          <option value="Reporting Radiologist">Reporting Radiologist</option>
          <option value="Releasing Radiologist">Releasing Radiologist</option>
          <option value="Consultant Radiologist">Consultant Radiologist</option>
        </select>
      </div>
      </div>

      <div class="col-sm-6 col-md-2">
        <h5>Signature</h5>
        <br>
        <img *ngIf="!(authSignature_=='null');else otherSignature" [src]="pathAuthSignature"
          style="border: #ebebeb 1px solid;width:auto;height: 50px;margin-top: 2px;">
        <ng-template #otherSignature>
          <img src="https://ui-avatars.com/api/?length=3&name=N/A"
            style="border: #ebebeb 1px solid;width: auto;height: 50px;margin-top: 2px;">
        </ng-template>
        <br />
        <br />
      </div>

      <div class="col-sm-6 col-md-2">
        <h5>Final Review</h5>
        <br>
        <div class="form-group">
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="finalReview" id="radioYes" value="yes"  formControlName="finalReview" (change)="noReset()">
              <label class="form-check-label" for="radioYes">Yes</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="finalReview" id="radioNo" value="no"
              formControlName="finalReview" [disabled]="pathSignature.get('designation').value === 'Reporting Pathologist'" (change)="noReset()" >
              <label class="form-check-label" for="radioNo">No</label>
            </div>
          </div>
      </div> 

      <div class="col-sm-6 col-md-3" *ngIf="pathSignature.get('finalReview').value === 'yes' " >
        <h5>Select Reviewer</h5>
        <br>
          <div >
            <ng-select style="border: 1px solid #ccc; background: #ccc;" class="form-control"
              formControlName="other_pathologists" [items]="all_radiologists_filtered" placeholder="Select Other Doctor"
              [(ngModel)]="otherSelectedDoctor" (change)="otherDoctor($event)">
            </ng-select>
            <label class="label" *ngIf="!pathSignature.get('other_pathologists').value"  style="background-color: white;">
            <span style="color: rgb(235, 12, 12);">* Please select a Reviewer</span>
          </label>
          </div>
        <br>
      </div>

      <div class="col-sm-6 col-md-3" *ngIf="pathSignature.get('finalReview').value === 'no' ">
        <h5>Select Co-Signer</h5>
        <br>
          <div>
            <ng-select style="border: 1px solid #ccc; background: #ccc;" class="form-control"
              formControlName="other_pathologists" [items]="all_radiologists_filtered" placeholder="Select Co-Signer"
              [(ngModel)]="otherSelectedDoctor" (change)="otherDoctor($event)">
            </ng-select>
          </div>
        <br>
      </div>
    </div>
  </form>
  </div>
  
