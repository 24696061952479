import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateTransform'
})
export class DateTransformPipe implements PipeTransform {
  wordFormat: any

  transform(value: any) {
    if (value) {
      const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

      // Split the input date into year, month, and day
      const [year, month, day] = value?.split('-');

      // Convert month to its word format
      const monthWord = monthNames[parseInt(month, 10) - 1];

      // Create the word format of the date
      this.wordFormat = `${monthWord} ${parseInt(day, 10)}, ${year}`;

      this.wordFormat;
    } else {
      this.wordFormat = ''
    }
    return this.wordFormat
  }

}
