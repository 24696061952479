<div *ngIf="permissions?.tat?.view" class="row mb-4 align-items-center" style="margin-top: -133px">
  <div class="col-lg-6 col-md-6">
    <h3 class="">Turn Around Time (TAT)</h3>
  </div>
  <div class="col-lg-6 col-md-6 text-right" *ngIf="permissions?.tat?.view">
    <button (click)="onTabChange('pathology')"
            [ngClass]="tab === 'pathology' ? 'btn-danger' : 'btn-outline-danger'" class="btn btn-round font-weight-bold text-uppercase mr-2">
      Pathology
    </button>
    <button (click)="onTabChange('radiology')"
            [ngClass]="tab === 'radiology' ? 'btn-danger' : 'btn-outline-danger'" class="btn btn-round font-weight-bold text-uppercase mr-lg-4">
      Radiology
    </button>
  </div>
</div>

<div class="card"  *ngIf="permissions?.tat?.view">
  <div class="card-header py-3">
    <div class="row">
      <div class="col">
        <h4>Interval</h4>
      </div>
      <div [ngClass]="searchVal ? 'd-none' : 'd-flex-inline'" class="col text-right">
        <p class="mb-0 small">Current Data</p>
        <h5 class="mr-0">{{year}}</h5>
      </div>
      <div [ngClass]="searchVal ? 'd-flex-inline' : 'd-none'" class="col text-right">
        <button (click)="clear()" class="btn btn-danger btn-sm"
                type="button">
          Clear
        </button>
      </div>
    </div>
  </div>
  <div class="card-body"  *ngIf="permissions?.tat?.view">
    <form (submit)="onIntervalSubmit($event)" action="">
      <div class="row">
        <div class="col-md-6 col-sm-12">
          <input class="form-control" id="from" onblur="(this.type='text')"
                 onfocus="(this.type='date')"
                 placeholder="Click to enter date" required type="text">
          <label for="from">From:</label>
        </div>
        <div class="col-md-6 col-sm-12">
          <input class="form-control" id="to" onblur="(this.type='text')"
                 onfocus="(this.type='date')"
                 placeholder="Click to enter date" required type="text">
          <label for="to">To:</label>
        </div>
        <div class="col-md-12 col-sm-12 d-flex justify-content-center">
          <button [disabled]="false"
                  class="btn btn-success"
                  style="padding:10px 15%;"
                  type="submit">
            <!-- <i class="fa fa-spinner fa-pulse" *ngIf="loading"></i> -->
            <span *ngIf="this.loading" class="spinner-border spinner-border-sm" role="status"></span>
            Search<span *ngIf="this.loading" class="load-text">ing...</span>
          </button>
        </div>
      </div>
    </form>
  </div>
</div>

<div class="row"  *ngIf="permissions?.tat?.view">
  <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6">
    <div class="card">
      <div class="card-body text-center">
        <h5 class="">Total Reports</h5>
        <hr>
        <h5>{{searchedTatList.length}}</h5>
      </div>
    </div>
  </div>
  <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6"  *ngIf="permissions?.tat?.view">
    <div class="card">
      <div class="card-body text-center">
        <h5 class="">Patients</h5>
        <hr>
        <h5>{{patientList}}</h5>
      </div>
    </div>
  </div>
  <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6"  *ngIf="permissions?.tat?.view">
    <div class="card">
      <div class="card-body text-center">
        <h5 class="">{{"Highest TAT"}}</h5>
        <hr>
        <h5>{{highestTat}}</h5>
      </div>
    </div>
  </div>
  <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6"  *ngIf="permissions?.tat?.view">
    <div class="card">
      <div class="card-body text-center">
        <h5 class="">{{"Lowest TAT"}}</h5>
        <hr>
        <h5>{{lowestTat}}</h5>
      </div>
    </div>
  </div>
</div>

<div *ngIf=" permissions?.tat?.view && specimenList.length > 0" class="card specimen-card">
 <div class="card-body pb-0">
   <div class="row align-items-center">
     <div class="pl-3">
       <h5 class="">Average Turn Around Time Per Template</h5>
     </div>
     <div class="col">
       <hr>
     </div>
     <div class="">
       <pagination-controls
         (pageBoundsCorrection)="specPage = $event"
         (pageChange)="specPage = $event"
         autoHide="false"
         directionLinks="true"
         id="specimens"
         maxSize="9"
         nextLabel="Next"
         previousLabel="Previous"
         responsive="true"
         screenReaderCurrentLabel="You're on page"
         screenReaderPageLabel="page"
         screenReaderPaginationLabel="Pagination"
         style="float: right"
       >
       </pagination-controls>
     </div>
   </div>
   <div class="row">
     <div
       *ngFor="let spec of specimenList | paginate: { itemsPerPage: 8, currentPage: specPage, totalItems: specimenList.length, id: 'specimens' }"
       class="col-xl-3 col-lg-4 col-md-4 col-sm-6">
       <div class="card">
         <div class="card-body text-center">
           <h5 class="">{{spec.form | nameReplace}}</h5>
           <hr>
           <h5>{{spec.average}}</h5>
         </div>
       </div>
     </div>
   </div>
 </div>
</div>

<div class="card"  *ngIf="permissions?.tat?.view">
  <div class="card-header py-2">
   <div class="row align-items-center">
     <div class="col">
       <h4>Search Patient</h4>
     </div>
     <div class="col-md-4">
       <input class="form-control" id="search"
              placeholder="Enter Patient Name" type="text" (input)="onPatientSearch($event)">
     </div>
   </div>
  </div>
  <div class="card-body">
    <table class="table table-bordered table-striped hover">
      <thead>
      <tr>
        <!-- <th width="40%">{{ selectedDebtor?(selectedDebtor=='requesting facility'?'Facility':'Patient'):'Debtor' }}</th> -->
        <th style="padding-left: 10px; padding-right: 10px; text-transform: none">Patient</th>
        <th style="padding-left: 10px; padding-right: 10px; text-transform: none">ReportID</th>
        <th style="padding-left: 10px; padding-right: 10px; text-transform: none">CaseID</th>
        <th style="padding-left: 10px; padding-right: 10px; text-transform: none">Created</th>
        <th style="padding-left: 10px; padding-right: 10px; text-transform: none">Finalized</th>
        <th class="cursor" style="padding-left: 10px; padding-right: 10px">total tat</th>
      </tr>
      </thead>
      <tbody>
      <!-- <tr *ngFor="let search of selectedDebtor?(selectedDebtor=='requesting facility'? results.facility:results.patient):''"> -->
      <tr *ngFor="let tat of searchedPatientList| paginate: { itemsPerPage: 10, currentPage: p, totalItems: searchedPatientList.length, id: 'patients' };">
        <th>{{tat.patient}}</th>
        <td>{{tat.pathology_number}}</td>
        <td>{{tat.case_id}}</td>
        <td>{{ tat.created_at | date: "MMM d, y":"GMT" }} at {{ tat.created_at | date: "h:mm a":"GMT" }}</td>
        <td>{{ tat.updated_at | date: "MMM d, y":"GMT" }} at {{ tat.updated_at | date: "h:mm a":"GMT" }}</td>
        <th>{{tat.tatString}}</th>
      </tr>
      </tbody>
    </table>

  </div>
  <div class="card-footer pt-3 pb-0">
    <pagination-controls
      (pageBoundsCorrection)="p = $event"
      (pageChange)="p = $event"
      autoHide="false"
      directionLinks="true"
      maxSize="9"
      id="patients"
      nextLabel="Next"
      previousLabel="Previous"
      responsive="true"
      screenReaderCurrentLabel="You're on page"
      screenReaderPageLabel="page"
      screenReaderPaginationLabel="Pagination"
      style="float: right"
    >
    </pagination-controls>
  </div>
</div>

<!--<div class="card">
  <div class="card-header">
    <h4>Details</h4>
  </div>
  <div class="card-body">
    <table class="table table-bordered table-striped" id="report-table">
      <thead>
      <tr>
        &lt;!&ndash; <th width="40%">{{ selectedDebtor?(selectedDebtor=='requesting facility'?'Facility':'Patient'):'Debtor' }}</th> &ndash;&gt;
        <th style="padding-left: 10px; padding-right: 10px; text-transform: none" >Patient</th>
        <th style="padding-left: 10px; padding-right: 10px; text-transform: none" >ReportID</th>
        <th style="padding-left: 10px; padding-right: 10px; text-transform: none" >CaseID</th>
        <th style="padding-left: 10px; padding-right: 10px; text-transform: none" >Created</th>
        <th style="padding-left: 10px; padding-right: 10px; text-transform: none" >Finalized</th>
        <th (click)="changeOrder()" class="cursor" style="padding-left: 10px; padding-right: 10px">total tat
          <span [ngClass]="desc ? 'fa-arrow-circle-o-up' : 'fa-arrow-circle-o-down'" class="fa "></span></th>
      </tr>
      </thead>
      <tbody>
      &lt;!&ndash; <tr *ngFor="let search of selectedDebtor?(selectedDebtor=='requesting facility'? results.facility:results.patient):''"> &ndash;&gt;
      <tr *ngFor="let tat of searchedTatList
                            | filter: searchText
                            | paginate: { itemsPerPage: 10, currentPage: p };">
        <th>{{tat.patient}}</th>
        <td>{{tat.pathology_number}}</td>
        <td>{{tat.case_id}}</td>
        <td>{{ tat.created_at | date: "MMM d, y":"GMT" }} at {{ tat.created_at | date: "h:mm a":"GMT" }}</td>
        <td>{{ tat.updated_at | date: "MMM d, y":"GMT" }} at {{ tat.updated_at | date: "h:mm a":"GMT" }}</td>
        <th>{{dateDiff(tat.created_at, tat.updated_at)}}</th>
      </tr>
      </tbody>
    </table>
  </div>
  <div class="card-footer">
    <pagination-controls
      (pageBoundsCorrection)="p = $event"
      (pageChange)="p = $event"
      autoHide="false"
      directionLinks="true"
      maxSize="9"
      nextLabel="Next"
      previousLabel="Previous"
      responsive="true"
      screenReaderCurrentLabel="You're on page"
      screenReaderPageLabel="page"
      screenReaderPaginationLabel="Pagination"
      style="float: right"
    >
    </pagination-controls>
  </div>
</div>-->


