import { DOCUMENT } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertService } from 'src/app/others/services/alert.service';
import { AuthenticationService } from 'src/app/others/services/authentication.service';
import { GeneralService } from 'src/app/services/general.service';
import Swal from 'sweetalert2';
import { EcgReportFormComponent } from './ecg-report-form/ecg-report-form.component';
 import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { PathologistWordService } from 'src/app/others/services/pathologist-word.service';
import { Component, ElementRef, Inject,Renderer2, OnInit, ViewChild } from '@angular/core';
import { Observable, Subject, from } from "rxjs";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { FinalizeComponent } from '../../finalize/finalize.component';
import { FinalizeRadiologyComponent } from '../../finalize-radiology/finalize-radiology.component';
import { FormStateService } from "src/app/others/services/form-state.service";


@Component({
  selector: 'app-ecg-report',
  templateUrl: './ecg-report.component.html',
  styleUrls: ['./ecg-report.component.scss']
})
export class EcgReportComponent implements OnInit {

  user_array = (str) => {
    return str.trim().split(",");
  };

  singleCurrency = this.user_array(sessionStorage.getItem("currency"));
  currencyNme = this.singleCurrency[0];
  currencySym = this.singleCurrency[1];
  currencyBase = this.singleCurrency[2];

  @ViewChild("form") form: EcgReportFormComponent;
  @ViewChild("modalView") viewModal;
  @ViewChild('htmlData') content:ElementRef;
   @ViewChild('myButton') myButton: ElementRef;
   @ViewChild("viewElem") viewElem:ElementRef;
     //  @ViewChild('finalizeComponent') finalizeComponent: FinalizeComponent;
   @ViewChild('finalizeRadiologyComponent') finalizeRadiologyComponent: FinalizeRadiologyComponent;
   isChecked: boolean = false;
  form_id: any;
  specimen_data: any = {};



  public Editor = ClassicEditor;
  public readonly: boolean = true;

  showSidebar = false;
   isClosed = false;
  isButtonActive: boolean = false;

  setID!: boolean;
  setName!: boolean;
  setRadiologyNumber!: boolean;
  setRequestingDoctor!: boolean;
  setRequestingFacility!: boolean;
  setLastEdit!: boolean;
  setReportStatus!: boolean;
  setBillStatus!: boolean;
  setActions!: boolean;
  patholoBoolean!: boolean;
  caseId: any;
  finalizePayload: any;
  authPathologist: any;
  authOtherPathologist: any;
  finalAuthSignature: any;
  finalAuthSignature64: any;
  setPeerStatus!: boolean;
  finalBoolean: boolean = false;
  finalizePay: any;
  pathologists: any;
  other_pathologists: any;
  auth_signature: any;
  other_pathologists_auth_signature: any;
  pathologists_designation: any;
  other_pathologists_designation: any;

  closeSidebar() {
    this.isClosed = true;
  }
  toggleSidebar() {
    this.showSidebar = !this.showSidebar;
  }

  showActions = true;

  getChecked(){
    const idChecked = localStorage.getItem("one");
    if(idChecked){
      this.setID = true;
    }else{
      this.setID = false;
    }

    const nameChecked = localStorage.getItem("one2");
    if(nameChecked){
      this.setName = true;
    }else{
      this.setName = false;
    }


     const RadiologyNumberChecked = localStorage.getItem("one3");
    if(RadiologyNumberChecked){
      this.setRadiologyNumber = true;
    }else{
      this.setRadiologyNumber = false;
    }


    const RequestingDoctorChecked = localStorage.getItem("one4");
    if(RequestingDoctorChecked){
      this.setRequestingDoctor = true;
    }else{
      this.setRequestingDoctor = false;
    }


    const RequestingFacilityChecked = localStorage.getItem("one5");
    if(RequestingFacilityChecked){
      this.setRequestingFacility = true;
    }else{
      this.setRequestingFacility = false;
    }


    const LastEditChecked = localStorage.getItem("one6");
    if(LastEditChecked){
      this.setLastEdit = true;
    }else{
      this.setLastEdit = false;
    }


    const ReportStatusChecked = localStorage.getItem("one7");
    if(ReportStatusChecked){
      this.setReportStatus = true;
    }else{
      this.setReportStatus = false;
    }


    const BillStatusChecked = localStorage.getItem("one8");
    if(BillStatusChecked){
      this.setBillStatus = true;
    }else{
      this.setBillStatus = false;
    }


    const ActionsChecked = localStorage.getItem("one9");
    if(ActionsChecked){
      this.setActions = true;
    }else{
      this.setActions = false;
    }

    const PeerReviewStatusChecked = localStorage.getItem("one10");
    if (PeerReviewStatusChecked) {
      this.setPeerStatus = true;
    } else {
      this.setPeerStatus = false;
    }



  }

  onChange(e: any, index: any){
    if(e.target.checked){
      localStorage.setItem(index, "tabCol")
      this.refresh();
    }else{
      localStorage.removeItem(index);
      this.refresh();
    }
  }

  refresh(){
    setTimeout(() => {
      location.reload();
    }, 2000)
  }

  toggleName(event:any, index) {
    // console.log(event.target.checked)
    var cl = document.getElementsByClassName(index);
    if(event.target.checked) {
      // console.log(index)
      for(let i = 0; i < cl.length; i++) {
        cl[i].classList.remove('tabCol')
      }

    } else {
      for(let i = 0; i < cl.length; i++) {
        cl[i].classList.add('tabCol')
      }
    }


  }


  specimen: any = {}; //holds single selected specimen's data
  specimens: any = []; //holds array of specimens data
  query_patient_id = null;
  query_pathology_number = null;
  Case = null;
  ID = null;

  // holds company data
  comData: any;

  // patient data
  patient: any = {}; //holds single selected patient's data
  patients: any = []; //holds array of patients data
  selected_patient = null;
  isSelected = false;
  fetching = false;
  patientsBuffer = [];
  bufferSize = 50;
  numberOfItemsFromEndBeforeFetchingMore = 10;
  loading = false;
  // holds the action to be performed ie(create/edit/amend/view)
  action: string;

  // holds the current page ie(root/archive/amendments)
  page: string = "root";

  // Url
  parentUrl = this.router.url; //holds the parent url
  amendmentsUrl = this.parentUrl + "/amendments"; //holds the amendments url
  archiveUrl = this.parentUrl + "/archive"; //holds archive url

  // boolean varaibles
  isLoading = false;
  isAmended = false;
  age: any;
  dateAge = "12/01/1991";

  // ================================Angular Table=====================================

  // angular table options
  dtOptions: DataTables.Settings = {
    pagingType: "full_numbers",
    pageLength: 25,
    serverSide: true,
    processing: true,
    ordering: false,
  };
  dtTrigger: Subject<any> = new Subject(); //triggers table initialization after every data load

  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective; //get the instance of the table being used

  isDtInitialized: boolean = false; //holds state of table to prevents multiple initialization

  // ==================================================================================

  actionButtons = true;

  permissions: any = [];

  serialize = function (obj, prefix?) {
    var str = [],
      p;
    for (p in obj) {
      if (obj.hasOwnProperty(p)) {
        var k = prefix ? prefix + "[" + p + "]" : p,
          v = obj[p];
        str.push(
          v !== null && typeof v === "object"
            ? this.serialize(v, k)
            : encodeURIComponent(k) + "=" + encodeURIComponent(v)
        );
      }
    }
    return str.join("&");
  };

  constructor(

    private spinner: NgxSpinnerService,
    public auth: AuthenticationService,
    private alert: AlertService,
    private route: ActivatedRoute,
    private generalService: GeneralService,
    private router: Router,
    private updateDateService: PathologistWordService,
    private render: Renderer2,
    private formStateService: FormStateService,
    @Inject(DOCUMENT) private document:Document
  ) {
    this.permissions = {
      radiology:{
        view: false,
        edit: false,
        add: false,
        delete: false,
        print: false
      },
      clinicalModules: {
        access: false,
        },
    };
   }

  ngOnInit() {
    this.getChecked();

    this.updateDateService.successMsg$.subscribe(
      (message) => this.patholoBoolean = message );

    this.query_patient_id = sessionStorage.getItem("pid");
    this.query_pathology_number = sessionStorage.getItem("case_id");
    sessionStorage.removeItem("pid");
    sessionStorage.removeItem("case_id");
    if (this.query_patient_id != null) {
      this.spinner.show();
      this.ID = this.query_patient_id;
      this.Case = this.query_pathology_number;
      // console.log(this.ID);
      // console.log(this.Case);
      if (this.ID) {
        let checkForButton = window.setInterval(function () {
          if (document.getElementById("viewBtn") != null) {
            clearInterval(checkForButton);
            let button: any;

            button = document.getElementById("viewBtn");
            button.click();
          }
        }, 1500);
      }
      // this.ID = this.query_patient_id;
      // this.Case = this.query_pathology_number;
    }
    this.getPermissions();
    // Set the current page
    this.setPage();

    // Get company data
    this.getComProfile();

    //check the page and load the corresponding data
    switch (this.page) {
      case "archive":
        this.getSpecimenArchives();
        break;
      case "amendments":
        this.getSpecimenAmendments();
        break;
      default:
        this.getPatients();
        this.getSpecimens();
        break;
    }

    this.formStateService.currentFormState.subscribe(isValid => {
      this.isButtonActive = isValid;
    });
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  redirectToBill(specimen) {
    sessionStorage.setItem("pid", specimen.patient_id);
    sessionStorage.setItem("scid", specimen.case_id);
    // this.router.navigate(["/billing/bills"]);
    this.router.navigate(['/billing/bills'], { queryParams: { patient_id: specimen.patient_id, case_id: specimen.case_id, pathology_number: specimen.pathology_number } });
  }

  redirectToPay(specimen) {
    sessionStorage.setItem("ppid", specimen.bill_id);
    sessionStorage.setItem("sscid", specimen.case_id);
    this.router.navigate(["/billing/bills"]);
  }

  getPermissions() {
    this.permissions = {
	  radiology:{
        view: false,
        edit: false,
        add: false,
        delete: false,
        print: false
      },
      clinicalModules: {
        access: false,
        },
    };
      this.auth
          .get("user_permissions/" + sessionStorage.getItem("userID")).toPromise()
          .then(
            (response: any) => {
              this.permissions = response.permissions.permissions[0];
              // this.actionButtons = Object.keys(this.permissions.clinicalModules).every((key) => {
              //   return key == "create" ? false : this.permissions.clinicalModules [key];
              // })
              if (!this.permissions.clinicalModules.access) {
                // Show a Swal alert if the user has no permissions
                Swal.fire({
                  icon: 'error',
                  title: 'Permission Denied',
                  text: 'You do not have permission to access this page.',
                });
              }
            });

    }

  onScrollToEnd() {
    this.fetchMore();
  }

  onScroll({ end }) {
    if (this.loading || this.patients.length <= this.patientsBuffer.length) {
      return;
    }

    if (
      end + this.numberOfItemsFromEndBeforeFetchingMore >=
      this.patientsBuffer.length
    ) {
      this.fetchMore();
    }
  }

  private fetchMore() {
    const len = this.patientsBuffer.length;
    const more = this.patients.slice(len, this.bufferSize + len);
    this.loading = true;
    // using timeout here to simulate backend API delay
    setTimeout(() => {
      this.loading = false;
      this.patientsBuffer = this.patientsBuffer.concat(more);
    }, 20);
  }

  loadTable() {
    if (this.isDtInitialized) {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtTrigger.next();
      });
    } else {
      this.isDtInitialized = true;
      this.dtTrigger.next();
    }
  }

  setPage() {
    // hold the child path
    const childPath = this.route.snapshot.routeConfig.path;
    switch (childPath) {
      // case "amendments":
      //   this.page = "amendments";
      //   this.route.queryParams.subscribe((params) => {
      //     this.specimen.id = parseInt(params["id"]);
      //     this.specimen.pathology_number = params["pathology_number"];
      //   });
      //   this.parentUrl = this.parentUrl.slice(
      //     0,
      //     this.parentUrl.indexOf(childPath) - 1
      //   );
      //   break;
      case "archive":
        this.page = "archive";
        this.parentUrl = this.parentUrl.slice(
          0,
          this.parentUrl.indexOf(childPath) - 1
        );
        break;
      default:
        this.page = "root";
        break;
    }
  }

  submit($event) {
    this.action = $event.action;
    this.specimen = $event.data;
    switch (this.action) {
      case "create":
        this.createSpecimen();
        break;
      case "edit":
        this.editSpecimen();
        break;
      case "amend":
        // this.amendSpecimen();
        break;
    }
  }

  setPatientInfo() {
    this.specimen.patient_id = this.patient.id;
    this.specimen.patient.id = this.specimen.patient_id;
    this.specimen.patient.patient_number = this.patient.patient_number;
    // this.specimen.patient.folder_number = this.patient.folder_number;
    // this.specimen.patient.pathology_number = this.patient.pathology_number;
    this.specimen.patient.first_name = this.patient.first_name;
    this.specimen.patient.last_name = this.patient.last_name;
    this.specimen.patient.date_of_birth = this.patient.date_of_birth;
    this.specimen.patient.age = this.patient.age;
    this.specimen.patient.age_option = this.patient.age_option;
    this.specimen.patient.gender = this.patient.gender;
    this.specimen.patient.email = this.patient.email;
    this.specimen.patient.address = this.patient.address;
    this.specimen.patient.phone = this.patient.phone;
    this.specimen.patient.national_health_insurance_id =
      this.patient.national_health_insurance_id;
  }

  async view(selected_specimen, isAmend = false) {
    this.spinner.show();
    if (this.query_patient_id == null && this.query_pathology_number == null) {
      this.ID = selected_specimen.id;
      this.Case = selected_specimen.case_id;
    }
    if (isAmend) {
      this.specimen = (await this.getAmendedSpecimen(this.ID))["data"];
    } else {
      this.query_patient_id = null;
      this.query_pathology_number = null;
      this.specimen = (await this.getSpecimen(this.ID))["data"];

      this.updateDateService.sendSuccessMsg(this.specimen.updatedAt);
      // this.checkAmendment(this.specimen.id);
    }
    this.action = "view";
    this.viewModal.show();
    this.spinner.hide();
    // console.log("this.speci",this.specimen);
  }

  async pdfView(selected_specimen, isAmend = false) {
    this.spinner.show();
    if (this.query_patient_id == null && this.query_pathology_number == null) {
      this.ID = selected_specimen.id;
      this.Case = selected_specimen.case_id;
    }
    if (isAmend) {
      this.specimen = (await this.getAmendedSpecimen(this.ID))["data"];
    } else {
      this.query_patient_id = null;
      this.query_pathology_number = null;
      this.specimen = (await this.getSpecimen(this.ID))["data"];

      this.updateDateService.sendSuccessMsg(this.specimen.updatedAt);
      // this.checkAmendment(this.specimen.id);
    }
     //  console.log(" pdf view", this.specimen);
     setTimeout(() => {
      // Delay the button click for 2 seconds
      const buttonElement: HTMLButtonElement = this.myButton.nativeElement;
      buttonElement.click();

      // Delay hiding the modal for an additional 3 seconds
      setTimeout(() => {
        this.viewModal.hide();
        this.spinner.show();
      }, 3000); // 3000 milliseconds = 3 seconds
    }, 2000); // 2000 milliseconds = 2 seconds
    // this.action = "view";
    this.viewModal.show();
    this.spinner.hide();
  }
  public async downloadPDF(): Promise<void> {
    this.spinner.show();
    this.render.setStyle(this.viewElem.nativeElement, 'width','1440px');

    const data = document.getElementById('htmlData');

    if (!data) {
      console.error('Document element with id \'htmlData\' not found.');
      return;
    }

    const img = new Image();

    img. onload = async () => {
      try {
        const canvas = await html2canvas(data, { logging: true });

        let imgWidth = 210;
        const pageHeight = 295;
        let imgHeight = canvas.height * imgWidth / canvas.width;
        console.log("PDF Height", imgHeight)

        const pdf = new jsPDF('p', 'mm', 'a4');
        // Convert canvas to a data URL
    const contentDataURL = canvas.toDataURL('image/jpeg');

    let heightLeft = imgHeight;
    let position = 0;

        // Draw the image and add new pages as needed
        while (heightLeft >= 0) {
            const pageContentHeight = heightLeft > pageHeight ? pageHeight : heightLeft;
            pdf.addImage(contentDataURL, 'JPEG', 0, position, imgWidth, imgHeight);

            heightLeft -= pageHeight;

            if (heightLeft > 0) {
                position = heightLeft - imgHeight; // Reset position to top of next page
                pdf.addPage(); // Add a new page
            }
        }
        const pdfOutput = pdf.output('dataurl');
        const payload = {
          pdf_report:pdfOutput,
          case_id: this.caseId
        };
        // console.log("Ths Auth", this.auth)
        this.spinner.show();
        this.auth.store('ecg_report_store_pdf', payload).subscribe(
          (response: any) => {
         console.log("pdf_storage_status",response);
        this.spinner.hide();
        // window.location.reload();
          },
          (error: any) => {
            console.log("error_pdf",error.message);
          }
        );

        // console.log('final', pdfOutput)
      } catch (error) {
        console.error('Error during PDF generation:', error);
      }
    };

    img.onerror = (error) => {
      console.error('Error loading image:', error);
    };

    // Assuming this.comData?.company_logo is the URL of the image
    img.src = this.comData?.company_logo !== null ? this.comData?.company_logo : 'assets/images/user/emr_logo.jpg';

  }
  getFormById(id: number): Observable<any> {
    return from(this.auth.get(`ecg_report/${id}`));
  }


  checkAmendment(id) {
    this.auth.show("ecg_report_amend", id).subscribe(
      (response) => {
        if (Object.keys(response["data"]).length) {
          this.isAmended = true;
        } else {
          this.isAmended = false;
        }
      },
      (error) => {
        // console.log(error);
      }
    );
  }

  defaultURL() {
    this.Case = null;
    this.ID = null;
    if (
      this.route.snapshot.queryParamMap.get("pid") ||
      this.route.snapshot.queryParamMap.get("ppid")
    ) {
      this.router.navigate([window.location.pathname]);
    }
  }

  amend() {
    this.spinner.show();
    this.action = "amend";
    this.form.show();
    this.spinner.hide();
  }

  create() {
    this.spinner.show();
    this.specimen = { user: {}, patient: {}, scan_image: [] };
    this.setPatientInfo();
    this.action = "create";
    this.form.show();
    this.spinner.hide();
  }

  // set action to edit and set initial data
  async edit(selected_specimen) {
    this.spinner.show();
    this.specimen = (await this.getSpecimen(selected_specimen.id))["data"];
    this.action = "edit";
    this.form.show();
    this.spinner.hide();
    // console.log("edit",this.specimen)
  }

   // Call the show method with options to set the spinner type
showSpinner() {
  this.spinner.show(undefined, {
    type: 'ball-running-dots', // Set the desired spinner type
  });
}
  async edit2(selected_specimen) {
    // console.log("Edit de", selected_specimen)
    this.showSpinner();
    this.specimen = (await this.getSpecimen(selected_specimen.id))["data"];
    this.action = "edit";
    this.form.show();
    this.spinner.hide();
  }
  // set action to edit and set initial data
  openEdit(specimen) {
   this.viewModal.hide()
   this.edit2(specimen)
  }


  preview(ev){
    this.viewModal.show()

  }

  delete(selected_specimen, page) {
    // Set the default alert options
    page === "root"
      ? this.generalService.delete(
          selected_specimen.id,
          "ecg_report_softdelete"
        )
      : this.generalService.onPermanentDel(
          selected_specimen.id,
          "ecg_report_delete_softdelete"
        );
  }

  restore(selected_specimen) {
    this.spinner.show();
    this.specimen = selected_specimen;
    this.restoreSpecimen();
    this.spinner.hide();
  }

  // get list of all patients
  getPatients(query = "") {
    this.auth.get("patients?query=" + query).subscribe(
      (response) => {
        if (response["data"] !== null || response["data"] !== undefined) {
          this.spinner.hide();
          this.patients = response["data"];
          this.patientsBuffer = this.patients.map(patient => {
            let ageString = "";
            if (patient.date_of_birth) {
              // Calculate age from date of birth
              const dob = new Date(patient.date_of_birth);
              const now = new Date();
              let yearsDiff = now.getFullYear() - dob.getFullYear();
              let monthsDiff = now.getMonth() - dob.getMonth();
              if (monthsDiff < 0 || (monthsDiff === 0 && now.getDate() < dob.getDate())) {
                yearsDiff--;
                monthsDiff += 12;
              }
              if (yearsDiff > 0) {
                ageString += `${yearsDiff} ${yearsDiff > 1 ? 'years' : 'year'}`;
              }
              if (monthsDiff > 0) {
                ageString += ` ${monthsDiff} ${monthsDiff > 1 ? 'months' : 'month'}`;
              }
              ageString += ' old';
            }
            return {
              ...patient,
              displayName: `${patient.full_name} | ${patient.phone} | ${ageString}`
            };
          });
        }
      },
      (error) => {
        this.spinner.hide();
        this.alert.error("Error loading patient specimen Data");
      }
    );
  }

  async patientSelected() {
    this.fetching = true;
    if (this.selected_patient === null) {
      this.isSelected = false;
    } else {
      this.patient = (await this.getPatient(this.selected_patient))["data"][
        "patient"
      ];
      this.isSelected = true;
      this.fetching = false;
    }
  }

  async getPatient(id) {
    return await this.auth.get("patient_info/" + id).toPromise();
  }

  searchChange($event) {}

  // Get company profile picture
  getComProfile() {
    this.spinner.show();
    this.auth.get("facility_profile").subscribe(
      (response) => {
        if (response["company"] !== null) {
          this.comData = response["company"];
          this.spinner.hide();
        } else {
          // this.alert.info('Response was null');
          this.spinner.hide();
        }
      },
      (error) => {
        this.spinner.hide();
        // console.log(error);
      }
    );
  }

  async getSpecimen(id) {
    return await this.auth.get("ecg_report/" + id).toPromise();
  }

  async getAmendedSpecimen(id) {
    return await this.auth.get("ecg_report/" + id).toPromise();
  }
  async getSpecimens() {
    this.spinner.show();
    this.dtOptions.ajax = (dataTablesParameters: any, callback) => {
      this.auth
        .get("ecg_report?" + this.serialize(dataTablesParameters))
        .subscribe((resp) => {
          this.specimens = resp["data"];
          //  console.log("specs",resp);
          callback({
            recordsTotal: resp["recordsTotal"],
            recordsFiltered: resp["recordsFiltered"],
            data: [],
          });
        });
    };
    let table = await this.dtElement.dtInstance;
    table.ajax.reload();
  }

  async getSpecimenArchives() {
    this.spinner.show();
    this.dtOptions.ajax = (dataTablesParameters: any, callback) => {
      this.auth
        .get(
          "ecg_report_view_softdelete?" +
            this.serialize(dataTablesParameters)
        )
        .subscribe((resp) => {
          this.specimens = resp["data"];
          // console.log(resp);
          callback({
            recordsTotal: resp["recordsTotal"],
            recordsFiltered: resp["recordsFiltered"],
            data: [],
          });
        });
    };
    let table = await this.dtElement.dtInstance;
    table.ajax.reload();
  }

  async getSpecimenAmendments() {
    this.spinner.show();
    this.dtOptions.ajax = (dataTablesParameters: any, callback) => {
      this.auth
        .get("ecg_report?" + this.serialize(dataTablesParameters))
        .subscribe((resp) => {
          this.specimens = resp["data"];
          // console.log(resp);
          callback({
            recordsTotal: resp["recordsTotal"],
            recordsFiltered: resp["recordsFiltered"],
            data: [],
          });
        });
    };
    let table = await this.dtElement.dtInstance;
    table.ajax.reload();
  }

  createSpecimen() {
    this.spinner.show();
    this.auth.store("ecg_report", this.specimen).subscribe(
      (response) => {
        // console.log(response);
        if (response !== null || response !== undefined) {
          this.spinner.hide();
          this.getSpecimens();
          this.alert.success(response["message"]);
          Swal.fire({
            title: "Submitted Successfully",
            icon: "success",
            showConfirmButton: false,
            timer: 2500,
          });

         }
      },
      (error) => {
        this.spinner.hide();
        if (error.status === 500) {
          this.alert.warning("Internal Server Error");
        } else if (error.status === 651) {
          this.alert.warning("Please check your connection");
        } else if (error.status === 409) {
          this.alert.warning(
            "A patient already has this pathology number, Please change it or delete the previous data!"
          );
        } else {
          this.alert.error("Can`t create a request now. Try again later");
        }
      }
    );
  }

  editSpecimen() {
    // console.log("check edit",this.specimen)
    this.spinner.show();
    this.auth
      .update("ecg_report", this.specimen.id, this.specimen)
      .subscribe(
        (response) => {
          // console.log(response);
          if (response !== null || response !== undefined) {
            this.spinner.hide();
            this.getSpecimens();
            this.alert.success(response["message"]);

         }
        },
        (error) => {
          // console.log(error);
          this.spinner.hide();
          if (error.status === 500) {
            this.alert.warning("Internal Server Error");
          } else if (error.status === 651) {
            this.alert.warning("Please check your connection");
          } else {
            this.alert.error("Can`t create a request now. Try again later");
          }
        }
      );
  }

  softDeleteSpecimen() {
    this.spinner.show();
    this.auth
      .destroy("ecg_report_softdelete", this.specimen.id)
      .subscribe(
        (response) => {
          this.spinner.hide();
          this.alert.success(response["message"]);
          this.getSpecimens();
        },
        (error) => {
          // console.log(error);
          this.spinner.hide();
          this.alert.error("Error deleting data, please try again.");
        }
      );
  }

  permamentDeleteSpecimen() {
    this.spinner.show();
    this.auth
      .destroy("ecg_report_delete_softdelete", this.specimen.id)
      .subscribe(
        (response) => {
          this.spinner.hide();
          this.alert.success(response["message"]);
          this.getSpecimenArchives();
        },
        (error) => {
          // console.log(error);
          this.spinner.hide();
          this.alert.error("Error deleting data, please try again.");
        }
      );
  }

  restoreSpecimen() {
    this.spinner.show();
    this.auth
      .restore("ecg_report_restore_softdelete", this.specimen.id)
      .subscribe(
        (response) => {
          // console.log(response);
          this.spinner.hide();
          this.alert.success(response["message"]);
          this.getSpecimenArchives();
        },
        (error) => {
          this.spinner.hide();
          // console.log(error);
          this.alert.error("Could not update info, please try again later");
        }
      );
  }

  printMe() {
    printElement(document.getElementById("htmlData"));
    function printElement(elem) {
      const domClone = elem.cloneNode(true);

      // const $printSection = document.getElementById("printSection");

      // if (!$printSection) {
      const $printSection = document.createElement("div");
      $printSection.id = "printSection";
      document.body.appendChild($printSection);
      // }

      $printSection.innerHTML = "";
      $printSection.appendChild(domClone);
      window.print();
      $printSection.innerHTML = "";
    }
  }

  downloadFile(item, i) {
    // const linkSource = `data:application/pdf;base64,${pdf}`;
    const downloadLink = document.createElement("a");
    const fileName = item["name"];
    downloadLink.href = item["preview"]["backgroundImage"];
    downloadLink.download = fileName;
    downloadLink.click();
  }
/**
 * When the user clicks on the image, the image is displayed in a modal.
 * @param {any} event - any - the event that triggered the function
 */
 zoomImage(event:any): void {
  console.log('here')
  let modal = this.document.querySelector("#myModal") as HTMLElement;

  // Get the image and insert it inside the modal - use its "alt" text as a caption
  let img = this.document.querySelector("#preview_box") as HTMLElement;
  let modalImg = this.document.querySelector("#img01") as HTMLImageElement;
  let captionText = document.querySelector("#caption") as HTMLElement;

  modal.style.display = "block";
  modalImg.src = event.target.src;
  captionText.innerHTML = event.target.alt;



}

closeImage(){
  let modal = this.document.querySelector("#myModal") as HTMLElement;
  modal.style.display = "none";
}




updateDataFinalize(specimen) {
  //this.downloadPDF();
  this.spinner.show();
  this.auth.update("ecg_report", specimen.id, this.finalizePayload).
    subscribe(
      (response) => {
        // console.log(response);
        if (response !== null || response !== undefined) {
          this.spinner.hide();
          this.getSpecimens();
          this.alert.success(response["message"]);
          this.caseId = response["case_id"];
          //this.render.setStyle(this.viewElem.nativeElement, 'width', ''); // Set to empty string to reset the style

          this.closeModalView()
        }
      },
      (error) => {
        // console.log(error);
        this.spinner.hide();
        if (error.status === 500) {
          this.alert.warning("Internal Server Error");
        } else if (error.status === 651) {
          this.alert.warning("Please check your connection");
        } else {
          this.alert.error("Can`t create a request now. Try again later");
        }
      }
    );
}



addItem(item: any) {
  this.finalizePay = item;
  this.pathologists = this.finalizePay?.pathologists;
  this.other_pathologists = this.finalizePay?.other_pathologists;
  this.finalAuthSignature64 = this.finalizePay?.auth_signature_64;
  this.auth_signature = this.finalizePay?.auth_signature;
  this.other_pathologists_auth_signature = this.finalizePay?.other_pathologists_auth_signature;
  this.pathologists_designation = this.finalizePay?.pathologists_designation;
}

addItem2(newItem: any) {
  this.finalizePayload = newItem;
  this.pathologists = this.finalizePayload?.pathologists;
  this.auth_signature = this.finalizePayload?.auth_signature;
  // console.log("Details", this.finalizePayload )
} 

closeModalView() {
  this.viewModal.hide();
  // this.isChecked = false;
  // this.authPathologist = '';
  // this.finalAuthSignature = '';
  // this.finalAuthSignature64 = '';
  this.authOtherPathologist = '';


  // Access the checkSubmission function from the child component
  this.finalizeRadiologyComponent.triggerCheckSubmission(false);
  console.log("FinalizeRadiologyComponent : ", this.finalizeRadiologyComponent.triggerCheckSubmission(false))

}
finalizeBoolean(event: any) {
  // console.log("Event Fin", event)
  this.finalBoolean = event

}

}
