<div class="container" *ngIf="this.nextConfig.layout === 'horizontal' && this.nextConfig.subLayout === 'horizontal-2'; else mainHeader">
  <ng-container *ngTemplateOutlet="mainHeader"></ng-container>
</div>
<ng-template #mainHeader>
  <div class="m-header">
    <a href="javascript:" class="mobile-menu" id="mobile-collapse" (click)="navCollapse()"><span></span></a>
    <a [routerLink]="['/dashboard/analytics']" class="b-brand">
      <img id="main-logo" src="assets/images/logo.png" alt="" class="logo">
      <img src="assets/images/logo-icon.png" alt="" class="logo-thumb">
    </a>
    <a class="mob-toggler" [ngClass]="{'on' : this.menuClass}" href="javascript:" (click)="toggleMobOption()"><i class="feather icon-more-vertical"></i></a>
  </div>
  <div class="collapse navbar-collapse" [style.display]="this.collapseStyle">
    <app-nav-left class="mr-auto"></app-nav-left>
    <div>
      <!-- <h2 style="color:#fff; font-family:inherit;text-transform:uppercase;background:#0000002b;"> -->
      <h2 class="text-white text-uppercase">
      {{ comData?.name_of_facility == null || comData?.name_of_facility == undefined ? company : comData?.name_of_facility }}
    </h2>
    </div>
    <!-- <div>
      <i class="fa-solid fa-bell fa-xs"></i>
     </div> -->
     <!-- <div class="dropdown">
      <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        Dropdown button
      </button>
      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
        <a class="dropdown-item" href="#">Action</a>
        <a class="dropdown-item" href="#">Another action</a>
        <a class="dropdown-item" href="#">Something else here</a>
      </div>
    </div> -->

    


    <app-nav-right class="ml-auto"></app-nav-right>
  </div>
</ng-template>
