import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProbDiagnosisRoutingModule } from './prob-diagnosis-routing.module';
import { ProbDiagnosisComponent } from './prob-diagnosis.component';


@NgModule({
  declarations: [
    ProbDiagnosisComponent
  ],
  imports: [
    CommonModule,
    ProbDiagnosisRoutingModule
  ]
})
export class ProbDiagnosisModule { }
