
import {
  Component,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';

import { AuthenticationService } from "src/app/others/services/authentication.service";
import { AlertService } from "src/app/others/services/alert.service";
import { UiModalComponent } from "src/app/theme/shared/components/modal/ui-modal/ui-modal.component";
import { FormGroup, FormBuilder, FormControl, Validators } from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';


@Component({
  selector: 'app-ecg-report-form',
  templateUrl: './ecg-report-form.component.html',
  styleUrls: ['./ecg-report-form.component.scss']
})
export class EcgReportFormComponent {


  user_array = (str) => {
    return str.trim().split(",");
   };
  singleCurrency = this.user_array(sessionStorage.getItem("currency"));
  currencyNme = this.singleCurrency[0];
  currencySym = this.singleCurrency[1];
  currencyBase = this.singleCurrency[2];
  @ViewChild("formModal") modal: UiModalComponent;
  @Output() submited = new EventEmitter();
  @Output() preview = new EventEmitter();
  @Input() action: string;
  @Input() specimen: any;

  title: string;
  case_number: any;
  activated: boolean = false;
  total_files_size = 0;
  fileSize = [];
  selected_report:any;
  recommendation_chk=false;
  all_radiologists = [];
  radiolgist_name_key = {};
  radiologists_sign ={};
  checkboxAndPathologistSelected: boolean = false;

  quillConfiguration = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    ['blockquote', 'code-block'],
    // [{ 'header': 1 }, { 'header': 2 }],               // custom button values
    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
    // [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
    [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
    [{ 'direction': 'rtl' }],                         // text direction
    [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
    [{ 'font': [] }],
    [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
    ['clean'],                                         // remove formatting button

    ],
  };

  commentStyle = {
    height: '150px',
    'font-size': '18px',
    color: '#373a3c',
    'font-weight': '400',
    border: '5px solid #556677',
    'background-color': '#fdfdfd;',
    // 'border-radius':'10%',
    position: 'relative',
  }

  public Editor = ClassicEditor;
  radioTextControl: FormControl;

  // report_type=[
  //   'Normal Brain CT Scan',
  //   'Other Create'
  // ]

  report_data =[
    {plain_radiograph:`<table>
    <tr>
      <th>Header</th>
    </tr>
    <tr>
      <td>Row 1, Column 1</td>
      <td>Row 1, Column 2</td>
    </tr>
    <tr>
      <td>Row 2, Column 1</td>
      <td>Row 2, Column 2</td>
    </tr>
    <tr>
      <td>Row 3, Column 1</td>
      <td>Row 3, Column 2</td>
    </tr>
  </table>
  `,
    impression:`Normal Brain CT Scan`},
  ]



  public isCollapsed: boolean;
  public multiCollapsed1: boolean;
  public multiCollapsed2: boolean;

  // this is the part for the requesting facilities
  requesting_facility: any = {}; //holds single selected facilities data
  requesting_facilities: any = []; //holds array of facilities data
  selected_facility: any;
  isSelected = false;
  requestingFacilitiesBuffer = [];
  bufferSize = 50;
  numberOfItemsFromEndBeforeFetchingMore = 10;
  loading = false;

    // this is for the doctor dropdown
    requesting_doctore: any ={};
    requesting_doctors: any = [];
    selected_doctor: any;
    doctorIsSelected = false;
    requestingDoctorBuffer = [];

     // for other pathologist dropdown
     new_pathologist: any= {};
     new_pathologists: any = [];
     selected_pathologist: any;
     otherPathologistIsSelected = false;
     otherPathologistBuffer = [];

    // Boolean variables
    isLoading = false;
    isAmended = false;
    tumorShow = false;
    nonTumorShow = false;
    turShow = false;
    enuclaetionShow = false;
    procedureOther = false;
    histologicalOther = false;
    inflammationOther = false;
    pathologyFindingsOther = false;

    readyForSubmit=0;

    authSignature = null;
    userRole = sessionStorage.getItem("role");


  // Formgroups
  form: FormGroup;
  page: string = "root";
  selectedFacility: string;
  fetching = false;
  speciality: any;

  setTitle() {
    switch (this.action) {
      case "create":
        this.getCase();
        this.getOtherPathologist();
        this.title = "Add New Specimen";
        break;
      case "edit":
        this.getOtherPathologist();
        this.title = "Edit Specimen";
        this.case_number = this.specimen.case_id;
      case "amend":
        this.title = "Amend Specimen";
        this.case_number = this.specimen.case_id;
        break;
      default:
      this.title = "Add New Specimen";
    }
  }

  // signSpecimen(value){
  //   console.log(value)
  //    this.authSignature= this.radiolgist_name_key[value]['digital_signature'];
  // }
  // signSpecimen(value) {
  //   const fullname = sessionStorage.getItem("fullname");
  //   const specialty = this.speciality && this.speciality.speciality ? `(${this.speciality.speciality})` : "";

  //   this.form.get("pathologists").setValue(fullname + " " + specialty);
  //   this.authSignature= this.radiolgist_name_key[value]['digital_signature'];
  //    this.specimen.auth_signature_64 = this.radiolgist_name_key[value]['digital_signature_64'];
  // }

  clearSignature(){
    this.form.get('pathologists').setValue(null);
    this.authSignature=null;
  }

    // GENERATE CASE NUMBER
    getCase() {
      // this.spinner.show();
      this.auth.get("ecg_report_caseid").subscribe((response) => {
        // console.log(response);
        if (response["data"] !== null || response["data"] !== undefined) {
          // this.spinner.hide();
          this.case_number = response["data"];
          // console.log(this.case_number);
        } else {
          this.alert.info("No data found.");
        }
      });
    }

    show() {
      this.activated = true;
      this.modal.show();
    }
    submit() {

      switch (this.action) {
        case "create":
          this.setData();
          break;
        case "edit":
          // if ((this.action === 'edit') && this.form.controls.pathologists.value === null) {
          //   // Display an error message using alert.error
          //   // this.alert.error('Error: Please select a doctor signature');
          //   return; // Prevent further execution
          // }
          this.setData();
          break;
        case "amend":
          this.setAmendmentData();
          break;
        default:
          this.setData();
      }
      this.submited.emit({
        action: this.action,
        data: this.specimen,
      });
    }


  constructor(
    private formBuilder: FormBuilder,
    private alert: AlertService,
    public auth: AuthenticationService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.speciality = JSON.parse(sessionStorage.getItem("user"))
    this.form = this.formBuilder.group({
      patient: this.formBuilder.group({
        patient_number: [{ value: null, disabled: true }],
        // folder_number: [{ value: null, disabled: true }],
        // pathology_number: [{ value: null, disabled: true }],
        first_name: [{ value: null, disabled: true }],
        last_name: [{ value: null, disabled: true }],
        date_of_birth: [{ value: null, disabled: true }],
        age: [{ value: null, disabled: true }],
        age_option: [{ value: null, disabled: true }],
        gender: [{ value: null, disabled: true }],
        email: [{ value: null, disabled: true }],
        address: [{ value: null, disabled: true }],
        phone: [{ value: null, disabled: true }],
        national_health_insurance_id: [{ value: null, disabled: true }],
      }),
      pathology_number: [null],
      folder_number: [null],
      date_specimen_received: [null],
      date_edited: [null],
      date_reported: [null],
      due_date: [null],
      scan_date: [null],
      indication: [null],
      clinical_history:[null],
      requesting_doctor: [null],
      requesting_facility: [null],

      impression:[null],
      impression_other:[null],
      recommendation:[null],
      recommended_other:[null],

      radio_text:[`
     <h4><b><u>DIMENSIONS (2D)</u></b></h4>

      <table>

      <h5><b>CARDIAC CHAMBERS:</b></h5>

      <tbody>
        <tr>
          <td>RA Length (mm): 45.1</td>

          <td>LA vol (ml): 21.56</td>

        </tr>
        <tr>
          <td>RV mid-Diam (mm): 28.1</td>

          <td>LV EDV A4C (ml): 35.81</td>
        </tr>
        <tr>
          <td>LVOT Diam (cm): 2.08</td>

          <td>LV mass (g): 175.74</td>
        </tr>

      </tbody>
    </table>



    <table>

      <h5><b>LV FUNCTION:</b></h5>

      <tbody>
        <tr>
          <td>EF (%) (Simpson’s bi-plane): 56.35</td>

          <td>CO(L/min): 4.92</td>

        </tr>
        <tr>
          <td>SV(ml): 44.36</td>

          <td>HR(bpm): 111</td>
        </tr>


      </tbody>
    </table>



    <table>

      <h5><b>RV FUNCTION:</b></h5>

      <tbody>
        <tr>
          <td>FAC (%): 46.19</td>

        </tr>
      </tbody>
    </table>



    <table>

      <h5><b>VESSELS:</b></h5>

      <tbody>
        <tr>
          <td>Main Pulmonary Artery Diam(cm): N/A	</td>

          <td>Aortic Root Diam(cm): 3.45</td>

        </tr>


      </tbody>
    </table>



    <table>

      <h5><b>AORTIC VALVE:</b></h5>
      <h5><b>2D:</b></h5>

      <tbody>
        <tr>
          <td>AV area (cm2): 2.89</td>

          <td>AV morphology: Trileaflet </td>

        </tr>

      </tbody>
    </table>



    <table>

      <h5><b>DOPPLER STUDY:</b></h5>

      <tbody>
        <tr>
          <td>AV Vmax (m/s): Unremarkable	</td>

          <td>AV Mean PG (mmHg): Unremarkable</td>

        </tr>
        <tr>
          <td>AR Retroflow (m/s): N/A</td>


        </tr>


      </tbody>
    </table>



    <table>

      <h5><b>MITRAL VALVE:</b></h5>
      <h5><b>2D:</b></h5>

      <tbody>
        <tr>
          <td>MV Area (PHT) (cm2): 4.31</td>

          <td>MV morphology: Normal</td>

        </tr>

      </tbody>
    </table>



    <table>

      <h5><b>DOPPLER STUDY:</b></h5>

      <tbody>
        <tr>
          <td>MV Peak A vel(cm/s): 58.07	</td>

          <td>MV E/A: 1.47</td>

        </tr>
        <tr>
          <td>MV Peak E vel(cm/s): 85.60	</td>

          <td>E/e’: 10.93</td>
        </tr>
        <tr>
          <td>MV Vmax (cm/s): Unremarkable	</td>

          <td>MV PG mean (mmHg): Unremarkable</td>
        </tr>

      </tbody>
    </table>



    <table>

      <h5><b>TRICUSPID VALVE:</b></h5>
      <h5><b>2D:</b></h5>


      <tbody>
        <tr>
          <td>TV morphology: normal</td>

        </tr>


      </tbody>
    </table>



    <table>

      <h5><b>DOPPLER STUDY:</b></h5>

      <tbody>
        <tr>
          <td>TR Vmax(m/s): N/A</td>

          <td>TR Max PG(mmHg): N/A</td>

        </tr>


      </tbody>
    </table>



    <table>

      <h5><b>PULMONARY VALVE:</b></h5>

      <tbody>
        <tr>
          <td>Pulmonary Valve Acceleration Time (PVAT) (m/s): N/A</td>

        </tr>

      </tbody>
    </table>

   `],




      comments: [null],
      form_ready: [null],
      form_control: [null],
      pathologists: [null, Validators.required],
      submission_ready: [false],
      other_pathologists: [null]

      // submission_ready: [{ value: null }]
    });
    this.getDoctors();
    switch (this.page) {
      default:
        // this.getRequestingDoctors();
        break;
    }
  }
  ngDoCheck() {
    if (this.activated) {
      this.getRequestingFacilities();
      // console.log(this.specimen);
      this.getRequestingDoctors(this.specimen.requesting_facility);
      this.prepareForm();
      this.readyForSubmit=this.specimen.submission_ready;
      this.authSignature=this.specimen.auth_signature;
      this.activated = false;
    }
  }

  prepareForm() {
    // console.log("h",this.specimen)/
    this.setTitle();
    Object.keys(this.specimen).forEach((key) => {
      let control = this.form.get(key);
      let specimen = this.specimen[key];
      if (control) {
        if (specimen !== null && typeof specimen === "object") {
          Object.keys(specimen).forEach((key) => {
            if (control.get(key)) {
              control.get(key).setValue(specimen[key]);
            }
          });
        } else {
          control.setValue(specimen);
        }
      }
    });
  }

  setData() {
    this.specimen.user_id = sessionStorage.getItem("userID");
    this.specimen.user.surname = sessionStorage.getItem("surname");
    this.specimen.user.othernames = sessionStorage.getItem("username");
    this.specimen.case_id = this.case_number;
    this.specimen.auth_signature = this.authSignature? this.authSignature : null;
    Object.keys(this.form.controls).forEach((key) => {
      this.specimen[key] = this.form.controls[key].value;
    });
  }

   // get list of other facilities
   getRequestingFacilities() {
    this.auth.get("doctor_names").subscribe(
      (response) => {
      // console.log(response);
        if (response["data"] !== null || response["data"] !== undefined) {
          this.spinner.hide();
          this.requesting_facilities = response["data"]["requesting_facility"];
   // console.log(this.requesting_facilities);
        }
      },
      (error) => {
        this.spinner.hide();
        this.alert.error("Error loading requesting facilities Data");
      }
    );
  }


  onFacilitySelect(value){
    this.fetching=true;
    this.selectedFacility = value.facility_full_name;
    // console.log(this.selectedFacility);
    this.form.get("requesting_doctor").setValue("");
    this.getRequestingDoctors(this.selectedFacility);
  }

    // function for the doctor returns
    getRequestingDoctors(value) {
      this.auth.get("doctor_names/" + value).subscribe(
        (response) => {
        // console.log(this.selectedFacility);
          if (response["data"] !== null || response["data"] !== undefined) {
            this.spinner.hide();
            this.requesting_doctors = response["data"]["data"];
          // console.log("req",this.requesting_doctors);
            this.fetching=false;
            //     this.allShow = this.requesting_doctors.doctor_email;
        //     console.log(this.allShow);
          }
        },
        (error) => {
          this.spinner.hide();
          this.alert.error("Error loading requesting doctors Data");
        }
      );
    }

    getOtherPathologist() {
      this.auth.get("all_doctors").subscribe(
        (response) => {
        // console.log(response);
         if (response["doctors"] !== null || response["doctors"] !== undefined) {
            this.spinner.hide();
            this.new_pathologists = response["doctors"];
        // console.log(this.new_pathologists);
           }
        },
        (error) => {
          this.spinner.hide();
          this.alert.error("Error loading other pathologist Data");
        }
      );
    }

	// setNewPathologists(event) {
	//   console.log(event)
	//   const specialty = document.getElementById("specialty").innerText
	//   this.form
	//   .get("other_pathologists")
	//   .setValue(
	// 	event +
	// 	  " " +
	// 	  this.speciality.speciality
	//   );
	// }

  setNewPathologists(event) {
    console.log(event);
    this.authSignature= this.radiolgist_name_key[event]['digital_signature'];
    this.specimen.auth_signature_64 = this.radiolgist_name_key[event]['digital_signature_64'];
    // const specialty = this.speciality && this.speciality.speciality ? `(${this.speciality.speciality})` : "";
    const specialty = document.getElementById("specialty").innerText
    this.form
      .get("pathologists")
      .setValue(
        event + " " + specialty
      );
  }
  setOtherPathologists(event) {
    console.log(event);
    const specialtyTwo = document.getElementById("specialty").innerText
    this.form
      .get("other_pathologists")
      .setValue(
        event + " " + specialtyTwo
      );
  }


    onScrollToEnd() {
      this.fetchMore();
    }

    onScroll({ end }) {
      if (this.loading || this.requesting_facility.length <= this.requestingFacilitiesBuffer.length) {
        return;
      }

      if (end + this.numberOfItemsFromEndBeforeFetchingMore >= this.requestingFacilitiesBuffer.length) {
        this.fetchMore();
      }
    }

    selectedReport(event){
      switch(event){
        case "Normal Brain CT Scan":
            this.selected_report = this.report_data[0];
            this.setDynamicFields();
           break;
        default :
          this.selected_report = {};
          this.setDynamicFields();
           break;
      }

    }

    setDynamicFields(){
      this.form.get("radio_text").setValue(this.selected_report?.plain_radiograph);
      this.form.get("impression_other").setValue(this.selected_report?.impression);
    }
    private fetchMore() {
      const len = this.requestingFacilitiesBuffer.length;
      const more = this.requesting_facility.slice(len, this.bufferSize + len);
      this.loading = true;
      // using timeout here to simulate backend  API delay
      setTimeout(() => {
        this.loading = false;
        this.requestingFacilitiesBuffer = this.requestingFacilitiesBuffer.concat(more);
      }, 20)
    }

    searchDoctorChange(_$event) { }

    onScrollToEndDoctor() {
      this.fetchMore();
    }

    recommendatioChange(ev){
      this.recommendation_chk =  (ev == "Other Specify")?true:false;
    }

    setAmendmentData() {
      this.specimen.amender_id = sessionStorage.getItem("userID");
      this.specimen.prostrate_turp_file_id = this.specimen.id;
      this.setData();
    }
    Reset() {
      // Boolean variables
    this.isLoading = false;
    this.isAmended = false;
    this.tumorShow = false;
    this.nonTumorShow = false;
    this.turShow = false;
    this.enuclaetionShow = false;
    this.procedureOther = false;
    this.histologicalOther = false;
    this.inflammationOther = false;
    this.pathologyFindingsOther = false;
    }

    fileSelection(event:Event){
      // console.log(this.specimen)
      // console.log(event)
      if(event.target?.['files'].length > 0){
        for(let i = 0;i < event.target?.['files'].length; i++){
          // get File name
          let file_name = event.target?.['files'][i].name;
          // get file type
          let just_type = file_name.slice((file_name.lastIndexOf(".") - 1 >>> 0) + 2)
           //Check File Type function
            let currentFileType:string[] = this.getFileType(just_type);
          //  Check if file type contain pic/jpeg
           let contain_pic = currentFileType.includes('true');

           this.specimen.scan_image.push({
            color:"#000000",
            content:contain_pic?'null':currentFileType[1],
            name:event.target?.['files'][i].name,
            preview:{backgroundImage:""},
            type:just_type
          })

          // check if file size in addition to what we have will exceeds 1MB before converting to base 64
          if(this.total_files_size  < 1){
            this.convertToBase64(event.target?.['files'],i)
          }else{
            let popped = this.fileSize.pop();
            this.specimen.scan_image.pop();
            this.total_files_size -= popped;
            this.alert.warning("File(s) size exceeds 1MB")
          }

            // Get File size for checks. So convert size to bytes for ease
            // this.fileSize.push(this.convertToBytes(event.target?.['files'][i].size));
            // use the reduce to sum up the sizes into the total_files_size variables
            // this.total_files_size = this.fileSize.reduce((a,b) => a +b , 0);
            // check if file size in addition to what we have will exceeds 1MB before converting to base 64

        }
      }
  }

  convertToBase64(files,i){
    var reader = new FileReader();

    reader.readAsDataURL(files[i])
    reader.onload=(event:any) =>{
      if(this.specimen.scan_image.length == 1){
        this.specimen.scan_image[i].preview.backgroundImage=event.target.result;
      }else if(this.specimen.scan_image.length > 1){
        this.specimen.scan_image[this.specimen.scan_image.length -(i+1)].preview.backgroundImage = event.target.result
      }

     }
  }

  getFileType(fileType):string[]{
    var all_File_Types = {
      jpg:["jpg", "jpeg", "png", "gif", "bmp", "tga", "tif", "tiff","image/jpeg"],
      doc: ["txt", "doc", "docx", "odt","csv"],
      xls: ["xls", "xlsx", "ods","xlsx"],
      pdf:['pdf']
      }

      if(all_File_Types['jpg'].includes(fileType)){
        return ['true'];
      }else if(all_File_Types['doc'].includes(fileType)){
        return ['false',"fa fa-file-word-o"];
      }else if(all_File_Types['xls'].includes(fileType)){
        return ['false',"fa fa-file-excel-o"];
      }else if(all_File_Types['pdf'].includes(fileType)){
        return ['false',"fa fa-file-pdf-o"];
      }else {
        return ['false']
      }
  }

  addFile() {
    // this.url = "";
    // this.fileNames = "";
    // this.attachment = "";
    // this.attachmenttype = "";
    // this.attachments().push(this.newFile());
  }
  removeFile(i: number) {
    this.specimen.scan_image.splice(i,1);
    let size_to_remove = this.fileSize.splice(i,1)
    this.total_files_size -= size_to_remove[0]
 }

 getDoctors() {
  this.auth.get('doctor_info').subscribe(
    (response) => {
      let rad_array = response['doctor'];
      console.log("New Docs", rad_array);

      this.all_radiologists = rad_array.map((el) => {
        const speciality1 = el.speciality !== null ? `(${el.speciality})` : '';
        return `${el.othernames} ${el.surname} ${speciality1}`;
      });

      rad_array.forEach(element => {
        let obj = {};
        const speciality2 = element.speciality ? `(${element.speciality})` : ''; // Wrap element.speciality in brackets
        obj['digital_signature'] = element.digital_signature;
        obj['digital_signature_64'] = element.digital_signature_64;
        this.radiolgist_name_key[`${element.othernames} ${element.surname} ${speciality2}`] = obj;
      });

    },
    (error) => {
      // Handle errors here
    }
  )
}

// selectadoctoralert(){
//   if ((this.action === 'create' || this.action === 'edit') && this.form.controls.pathologists.value === null) {
//     // Display an error message using alert.error
//     // this.alert.error('Error: Please select a doctor signature');

//     return; // Prevent further execution
//   }
// }

isSubmitButtonEnabled(): boolean {
  return !this.checkboxAndPathologistSelected;
}

checkSubmission(value) {

  // Check if the checkbox is checked and a pathologist is selected
  this.checkboxAndPathologistSelected = this.form.get('submission_ready').value &&
    (this.form.get('pathologists').value !== '' || this.form.get('other_pathologists').value !== '');
   // this.alert.warning('Error: You have not selected a doctor, so report not finalized');

  // Continue with the existing logic
  let g = this.form.get('submission_ready').value;
  if (g === true && !this.checkboxAndPathologistSelected) {
         // Display an error message using alert.error
    // // this.alert.error('Error: Please select a doctor signature');
    // Uncheck the checkbox to prevent form submission
    this.form.get('submission_ready').setValue(false);
  } else {
    this.readyForSubmit = g ? 1 : 0;
       if (this.checkboxAndPathologistSelected) {
      this.clearSignature();
    }
  }
  // Enable the submit button when the checkbox is checked and a pathologist is selected
  this.form.get('submission_ready').valueChanges.subscribe(() => {
    this.checkboxAndPathologistSelected = this.form.get('submission_ready').value &&
      (this.form.get('pathologists').value !== '');
      // (this.form.get('pathologists').value !== '' || this.form.get('other_pathologists').value !== '');
  });
}


prev() {
  this.modal.hide();
  this.preview.emit()
}



}
