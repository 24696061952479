import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/others/services/authentication.service';
@Component({
  selector: 'app-lab-studies',
  templateUrl: './lab-studies.component.html',
  styleUrls: ['./lab-studies.component.scss']
})
export class LabStudiesComponent implements OnInit {
  patient: any;
  constructor(
    private activeRoute: ActivatedRoute,
    private router: Router, 
    private sanitizer: DomSanitizer,
    private auth: AuthenticationService) { }

  ngOnInit(): void {
    this.activeRoute.params.subscribe((a) => {
      this.getPatientInfor(a.id)
    })
  }

  getPatientInfor(id: number) {
    this.auth.get("patient_info/" + id).subscribe((a) => {
      
      this.patient = a['data'];
      console.log(this.patient);
      // console.log(a['data']['patient']['allergies'])

    })
  }

  redirectTo(patient) {
    sessionStorage.setItem("pid", patient.id);
    sessionStorage.setItem("case_id", patient.case_id);
    this.router.navigate([patient.route_link]);
  }
}
