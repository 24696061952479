import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NextConfig } from '../../../../app-config';
import { AuthenticationService } from "src/app/others/services/authentication.service";

@Component({
  selector: "app-nav-bar",
  templateUrl: "./nav-bar.component.html",
  styleUrls: ["./nav-bar.component.scss"],
})
export class NavBarComponent implements OnInit {
  public nextConfig: any;
  public menuClass: boolean;
  public collapseStyle: string;
  public windowWidth: number;

  @Output() onNavCollapse = new EventEmitter();
  @Output() onNavHeaderMobCollapse = new EventEmitter();

  constructor(private auth: AuthenticationService) {
    this.nextConfig = NextConfig.config;
    this.menuClass = false;
    this.collapseStyle = "none";
    this.windowWidth = window.innerWidth;
  }

  ngOnInit() {
    this.id = sessionStorage.getItem("userID");
    this.getProfile();
  }

  toggleMobOption() {
    this.menuClass = !this.menuClass;
    this.collapseStyle = this.menuClass ? "block" : "none";
  }

  navCollapse() {
    if (this.windowWidth >= 992) {
      this.onNavCollapse.emit();
    } else {
      this.onNavHeaderMobCollapse.emit();
    }
  }

  id: string;
  username: string;
  company: any;
  comData: any;
  user: any;

  getProfile() {
    this.auth.get("facility_profile").subscribe(
      (response) => {
        if (response["company"] !== null) {
          this.comData = response["company"];
          // sessionStorage.removeItem("company_name");
          // sessionStorage.setItem("company_name", this.comData.name_of_facility);
          this.company = sessionStorage.getItem("company_name");
          // console.log(this.comData);
        }
      },
      (error) => {
        // console.log(error);
      }
    );
    this.auth.show("user", this.id).subscribe(
      (response) => {
        this.user = response["data"];
        // console.log(response["data"]);
        // sessionStorage.removeItem("othernames");
        // sessionStorage.setItem("othernames", this.user.othernames);
        this.username = sessionStorage.getItem("othernames");
      },
      (error) => {
        // console.log(error);
      }
    );
  }
}
