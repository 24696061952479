import { Component } from '@angular/core';

@Component({
  selector: 'app-prob-diagnosis',
  templateUrl: './prob-diagnosis.component.html',
  styleUrls: ['./prob-diagnosis.component.scss']
})
export class ProbDiagnosisComponent {

}
