<div class="row" *ngIf="permissions?.keyMetrics?.access">
  <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6">
    <a href="" routerLink="balance">
      <div class="card"  *ngIf=" permissions.balance.view">
        <div class="card-body text-center">
          <h4 class="">BALANCE</h4>
          <hr>
          <i class="fa-solid fa-scale-balanced fa-6x"></i>
        </div>
      </div>
    </a>
  </div>
  <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6" *ngIf=" permissions.clients.view">
    <a href="" routerLink="client-reports">
      <div class="card">
        <div class="card-body text-center">
          <h4 class="">CLIENTS</h4>
          <hr>
          <i class="fa-solid fa-user-doctor fa-6x"></i>
        </div>
      </div>
    </a>
  </div>
  <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6" *ngIf=" permissions.receivables.view">
    <a href="" routerLink="receivables">
      <div class="card">
        <div class="card-body text-center">
          <h4 class="">RECEIVABLES</h4>
          <hr>
          <i class="fa-solid fa-file-invoice-dollar fa-6x"></i>
        </div>
      </div>
    </a>
  </div>

  <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6" *ngIf=" permissions.discounts.view">
    <a href="" routerLink="discounts">
      <div class="card">
      <div class="card-body text-center">
        <h4 class="">DISCOUNTS</h4>
        <hr>
        <i class="fa-solid fa-percent fa-6x"></i>
      </div>
    </div>
    </a>
  </div>

  <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6" *ngIf=" permissions.expenses.view">
    <a href="" routerLink="expenses">
      <div class="card">
        <div class="card-body text-center">
          <h4 class="">EXPENSES</h4>
          <hr>
          <i class="fa-solid fa-circle-dollar-to-slot fa-6x"></i>
        </div>
      </div>
    </a>
  </div>
  <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6"  *ngIf=" permissions.performance.view">
    <a href="" routerLink="performance">
      <div class="card">
        <div class="card-body text-center">
          <h4 class="">PERFORMANCE</h4>
          <hr>
          <i class="fa-solid fa-person-digging fa-6x"></i>
        </div>
      </div>
    </a>
  </div>

  <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6" *ngIf=" permissions.staffCollections.view">
    <a href="" routerLink="staff-collection">
      <div class="card">
        <div class="card-body text-center">
          <h4 class="">STAFF COLLECTIONS</h4>
          <hr>
          <i class="fa-solid fa-people-carry-box fa-6x"></i>
        </div>
      </div>
    </a>
  </div>
  <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6"  *ngIf=" permissions.tat.view">
    <a href="" routerLink="tat">
      <div class="card">
        <div class="card-body text-center">
          <h4 class="">TAT</h4>
          <hr>
          <i class="fa-solid fa-hourglass-2 fa-6x"></i>
        </div>
      </div>
    </a>
  </div>
  <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6" *ngIf=" permissions.tat.view">
    <a href="" routerLink="cases">
      <div class="card">
        <div class="card-body text-center">
          <h4 class="">CASES
          </h4>
          <hr>
          <i class="fa-solid fa-file-invoice fa-6x"></i>
        </div>
      </div>
    </a>
  </div>
  <!-- <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6" *ngIf=" permissions.tat.view">
    <a href="" routerLink="category">
      <div class="card">
        <div class="card-body text-center">
          <h4 class="">CATEGORY
          </h4>
          <hr>
          <i class="fa-solid fa-file-invoice fa-6x"></i>
        </div>
      </div>
    </a>
  </div> -->
</div>
