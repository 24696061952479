<!--====== NAVBAR TWO PART START ======-->
<section class="navbar-area sticky">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <nav class="navbar navbar-expand-lg">
          <a class="navbar-brand" href="#">
            <img [src]="img" alt="Logo" />
          </a>

          <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarTwo"
            aria-controls="navbarTwo"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="toggler-icon"></span>
            <span class="toggler-icon"></span>
            <span class="toggler-icon"></span>
          </button>

          <div class="collapse navbar-collapse sub-menu-bar" id="navbarTwo">
            <ul class="navbar-nav m-auto" style="margin-right: 0!important">
              <li data-click="home" class="nav-item active">
                <a
                routerLink=""
                >
                  home</a
                >
              </li>
            </ul>
          </div>
        </nav>
        <!-- navbar -->
      </div>
    </div>
    <!-- row -->
  </div>
  <!-- container -->
</section>
<!--====== NAVBAR TWO PART ENDS ======-->

<div class="container">
  <div style="position: relative; margin-top: 100px; margin-bottom: 40px;">
    <div>
      <h3 class="font-weight-bold mb-0 text-blue">
        Terms of Use
      </h3>
    </div>
    <hr>
    <p>
      NubiaEMR is a product of Toldo Company Ghana Limited a registered Limited
      Liability Company in Ghana. The brand name NubiaEMR stands for Nubia
      Electronic Medical Records. The User (Health Institution or practitioner)
      will be referred to as ‘User’ and Toldo Limited will be referred to as
      NubiaEMR or Software. This Agreement (Terms of Use or Terms) is between
      the User and NubiaEMR. The services rendered by NubiaEMR to the User is
      subject to the following Terms and Conditions:
      <br /><br />
      <strong class="text-center">1. Acceptance of Terms</strong><br />
      By using the Software, the User agrees to the Terms of Use. The right to
      use the Software is personal to the User and not transferable to any
      entity or individual. The User is responsible for their account, including
      the password and must ensure that their actions comply fully with the
      Terms stated herein.<br /><br />
      <strong class="text-center">2. Services of NubiaEMR</strong><br />
      The Software allows healthcare practitioners and patients to input and
      access data, request and record payments for services and enjoy other
      services enabled by the features described in the Software.<br /><br />
      <strong class="text-center">3. Equipment</strong><br />
      The User is responsible for the provision, maintenance, and cost of any
      equipment they use to access the Software.<br /><br />
      <strong class="text-center">4. Account, Passwords and Security</strong
      ><br />
      To use the Software the User is required to enter accurate information in
      forms when prompted. Users are responsible for choosing the password and
      the email to be used to access the website including the confidentiality
      of such details. Users must not allow any third party to have access to
      such details and/or use their profile with or without the User’s
      permission. NubiaEMR cannot be responsible for any losses as a result of
      any other person accessing the User’s account or password. However, the
      User maybe held liable if NubiaEMR suffers losses as a result of the User
      allowing others to use their account or password. The User may not use any
      other person’s account and password.<br /><br />

      <strong class="text-center">5. User Conduct</strong><br />
      5.1. The User should use the Software for lawful purposes only. By
      subscribing to use the Software, all health practitioners certify that
      they are licensed by their State or National licensing authorities to
      provide healthcare services. The use of this Software for the generation
      or distribution of abusive, defamatory, invasive or other objectionable
      information is prohibited. The User should not use the Software to
      advertise any service or third-party website.
      <br />5.2. The User should not upload any private, copyrighted or
      trademarked data on the Software without the approval of the owner of such
      information. By uploading data, the User confirms that they have the right
      to upload, view, store, edit, distribute and translate the data over an
      unlimited period. <br />5.3. The data on the Software contains copyrights,
      trademarks and other proprietary information. The User grants NubiaEMR the
      right to rearrange, modify or reproduce User’s entered data to allow
      efficient use within the features of the Software. The User may download
      data from the Software for professional use only and is not allowed to
      redistribute, commercialize, publicize, or carry out other modifications
      without express approval from the copyright owner and NubiaEMR. <br />5.4
      The User is prohibited from using the Software for unlawful and immoral
      activities including (without limitation) the following: <br />a.
      collecting usernames, profiles, and other personal data for the purposes
      of sending soliciting emails, spam or other messages under false pretense.
      <br />b. offer and sell goods or services without permission from
      NubiaEMR. <br />c. disable or circumvent features aimed at securing the
      Software and all data therein. This includes disassembling or deciphering
      any measures used to protect User data and the integrity of the Software.
      <br />d. defrauding other Users and NubiaEMR or submitting false
      information and general misconduct. <br />e. extracting data for use in
      negative publicity, slander, fraud, harassment, and other immoral
      activities. <br />f. impersonating or attempting to impersonate another
      User or NubiaEMR. <br />g. abuse, harass, intimidate, insult or threaten
      any employee of NubiaEMR or its affiliates. <br />h. use the Software to
      hurt NubiaEMR or act in ways inconsistent with National Laws. <br />i.
      upload files that contain viruses, Trojan horses, worms, bots, corrupted
      files or any other file that harms the Software or other Users.<br /><br />

      <strong class="text-center">6. User Data</strong><br />
      6.1 In addition to the professional data inputted by the User, they may be
      requested to contribute to Chats with customer support, create, submit,
      post, display, transmit, publish or distribute some content
      (Contributions) with NubiaEMR. These contributions may be necessary to
      allow an accurate reporting of errors and help NubiaEMR improve the
      Software.
      <br />6.2. User Content such as Patient Information, Appointments,
      Prescriptions, Financial Information and other own Content is fully owned
      by the User and can be removed by the User at any time without need to
      seek approval from NubiaEMR. <br />6.3. NubiaEMR will store all User data
      on safe Cloud systems with access granted only to the User. NubiaEMR will
      perform regular back-up and maintenance to ensure data is safe as much as
      possible. The User agrees that NubiaEMR shall not be liable for any loss
      or corruption of data which occurs as a result of a ‘deletion’ or omission
      by the User or any event that is outside the control of NubiaEMR.<br /><br />

      <strong>7. Payments</strong><br />
      7.1 The User is responsible for reconciling all financial data they input
      or process through this Software. The accuracy of records of Cash payments
      by Customers is the responsibility of the User. Electronic payments
      processed through the Software’s third-party gateways will be
      automatically reconciled against actual receipts by the User, less (minus)
      processing fees.
      <br />7.2 Access to NubiaEMR is restricted by subscription over a limited
      period as determined by the subscription package. <br />a. the User may
      have access to the system only during their active subscription period.
      Prior the end of their subscription, the User may download or delete all
      their data without the need to inform NubiaEMR. <br />b. at the end of
      their subscription or the activation of termination, Users may no longer
      be granted access to the Software and NubiaEMR is under no obligation to
      store their data beyond the validity of their subscription. <br />c. upon
      uninterrupted renewal of subscription, Users will have access to their
      data and functionalities according their subscription package. <br />d.
      upon interrupted (such as termination) renewal, NubiaEMR will not be
      obliged to reproduce the data previously stored by the User. <br />7.3.
      Payments by Patients (Customers) to a User shall be made to a sub-account
      (Bank or Mobile Money) reserved for that institution after processing fees
      charged by the payment services provider and/or NubiaEMR. This amount will
      not exceed 7.5% of the total amount paid by the Patient a/Customer and
      applies only to non-cash and cheque payments.<br /><br />
      <strong class="text-center"
        >8. Disclaimer & Limitation of Liability</strong
      ><br />
      8.1. The User expressly agrees that their use of the Software is at their
      own risk. Neither NubiaEMR nor its affiliates service providers can
      guarantee an error free Software. Should errors be noticed, the User is
      adviced to inform NubiaEMR with reasonable detail and background to enable
      a rectification or improvement of that feature within reasonable time.
      <br />8.2. NubiaEMR is provided to the User as is and is only for the
      purposes of inputting or storing medical records. NubiaEMR cannot
      guarantee results such as improvement in health of patients, increased
      access to new customers or any other result not envisaged by the Software.
      <br />8.3. This disclaimer applies to any damages or injury caused by
      failure of performance, interruption, omission, deletion, defect, computer
      virus, communication failure, theft, destruction, unauthorized access,
      tortious behavior, negligence or similar acts. The User agrees that
      NubiaEMR is not liable for the defamatory, illegal or offensive conduct of
      third parties or other Users. <br />8.4. Force Majure – Neither party may
      be liable for failure or delay in performance due to circumstances beyond
      their reasonable control including, without limitation, natural disasters,
      war, riot, embargoes, accidents, terror attacks, health pandemics, service
      outages, acts of civil or military authority, power failure, software
      failure, telecommunication failure or failures of third parties. The party
      affected by such an event shall inform the other party within 10 working
      days from the date of such occurrence and the performance of this
      agreement can be suspended for as long as necessary if such an event
      prevents that party from performing their duties under this agreement.
      <br />8.5. The User agrees that NubiaEMR is not responsible for the loss
      of their account details, password, data and other information caused by
      User’s negligence or decisions to allow third parties to access their
      accounts or equipment or the use of equipment that is not compatible with
      the Software.<br /><br />

      <strong class="text-center">9. Links to third party Websites</strong
      ><br />
      Except for links to international classifications of symptoms or
      prescriptions and processing of non-cash payments, links in the Software
      will not let the User exit the Software. These links allow up to date use
      of medical terms and the secure processing of payments. With regards to
      payment, a gateway approved by the laws of the land and reputed for
      transparency, speed, security and efficiency will be used. This allows
      payers to interface with digital payment methods and make real time
      approved payments.<br /><br />
      <strong class="text-center">10. Governing Law</strong><br />
      The Terms listed in this Agreement are governed by and under the
      jurisdiction of the Laws of the Country suggested by NubiaEMR and where
      the Client operates. Should any aspect of these Terms be ruled invalid by
      a Court of competent Jurisdiction, that aspect may be removed and all
      other aspects of these Terms shall remain active.<br /><br />

      <strong class="text-center"
        >11. Copyright, Trademarks, and Intellectual Property Protection</strong
      ><br />
      11.1. The User is advised not to send any trademarked or copyrighted
      information including promotions, marketing ideas or business models
      through the Software. User is also advised not to send any unsolicited
      ideas or intellectual property.<br /><br />
      <br />11.2. NubiaEMR is the intellectual property of Toldo Company Ghana
      Limited (ToldoIT). The Software, forms, mode of operations, updates and
      all features are the intellectual property of ToldoIT and can thus not be
      copied or reproduced. By suggesting any improvements or additions the User
      agrees that such improvement is not confirmation of their part or whole
      ownership of the improvement or the Software.<br /><br />
      <strong class="text-center">12. Indemnification and Termination</strong
      ><br />
      12.1. The User agrees to hold harmless or defend NubiaEMR and its
      affiliates from and against all claims and expenses, including legal fees
      arising out of User’s use of their account including their (user’s)
      conflicts with third parties of their customers.
      <br />12.2. NubiaEMR is a Software as a Service (SaaS) and the User is at
      liberty to terminate use at any time without any reason with such a
      termination becoming active at the end of their subscription. In such an
      event, the User will not be billed at the end of the current subscription
      and 7.2 of these Terms will be activated. <br />12.3. NubiaEMR reserves
      the right to terminate the subscription of a User if they are deemed to
      have violated section 5 of these Terms or any other section or clause
      herein. In such an instance NubiaEMR will inform the User of a termination
      and request them to remove their data but NubiaMR is under no obligation
      to provide a detailed explanation for the termination just as the User
      does not need to explain the reasons for their termination in 12.2.<br /><br />
      <strong class="text-center">13. Variation of Terms</strong><br />
      NubiaEMR can vary the terms of this agreement whenever appropriate and on
      a regular basis with notice to the User to ensure clarity of all terms.
    </p>
  </div>
</div>

<!--====== FOOTER PART START ======-->

<section class="footer-area footer-dark">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-7">
          <div class="footer-logo text-center">
            <a class="mt-30" href=""
              ><img src="../../../assets/img/logo.png" alt="Logo"
            /></a>
          </div>
          <!-- footer logo -->
          <ul class="social text-center mt-60">
            <li>
              <a href="https://www.facebook.com/NubiaEMR/" target="_blank"
                ><i class="lni lni-facebook-filled"></i
              ></a>
            </li>
            <!-- <li><a href="#"><i class="lni lni-twitter-original"></i></a></li> -->
            <!-- <li><a href="#"><i class="lni lni-instagram-original"></i></a></li> -->
            <li>
              <a
                href="https://www.linkedin.com/company/73961138/admin/"
                target="_blank"
                ><i class="lni lni-linkedin-original"></i
              ></a>
            </li>
          </ul>
          <!-- social -->
          <div class="footer-support text-center">
            <span class="mail">support@nubiaemr.com</span>
          </div>
          <div class="copyright text-center mt-35">
            <h4 class="text">Copyright © {{copyright}} NubiaEMR™. All Rights Reserved.</h4>
          </div>
          <!--  copyright -->
        </div>
      </div>
      <!-- row -->
    </div>
    <!-- container -->
  </section>
  
  <!--====== FOOTER PART ENDS ======-->
