import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { SupportComponent } from "./components/support/support.component";
import { NgModule } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { SharedModule } from "./theme/shared/shared.module";
import { AppComponent } from "./app.component";
import { AdminComponent } from "./theme/layout/admin/admin.component";
import { AuthComponent } from "./theme/layout/auth/auth.component";
import { UserComponent } from "./theme/layout/user/user.component";
import { NavigationComponent } from "./theme/layout/admin/navigation/navigation.component";
import { NavContentComponent } from "./theme/layout/admin/navigation/nav-content/nav-content.component";
import { NavGroupComponent } from "./theme/layout/admin/navigation/nav-content/nav-group/nav-group.component";
import { NavCollapseComponent } from "./theme/layout/admin/navigation/nav-content/nav-collapse/nav-collapse.component";
import { NavItemComponent } from "./theme/layout/admin/navigation/nav-content/nav-item/nav-item.component";
import { NavBarComponent } from "./theme/layout/admin/nav-bar/nav-bar.component";
import { NavLeftComponent } from "./theme/layout/admin/nav-bar/nav-left/nav-left.component";
import { NavSearchComponent } from "./theme/layout/admin/nav-bar/nav-left/nav-search/nav-search.component";
import { NavRightComponent } from "./theme/layout/admin/nav-bar/nav-right/nav-right.component";
import { ConfigurationComponent } from "./theme/layout/admin/configuration/configuration.component";
import { ToggleFullScreenDirective } from "./theme/shared/full-screen/toggle-full-screen";
import { NavigationItem } from "./theme/layout/admin/navigation/navigation";
import { NgbButtonsModule, NgbDropdownModule, NgbTabsetModule, NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";
import { BrowserModule } from "@angular/platform-browser";
import { HttpClientModule } from "@angular/common/http";
import { NgxSpinnerModule } from "ngx-spinner";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ToastrModule } from "ngx-toastr";
import { QuillModule } from "ngx-quill";
import { NgxStripeModule } from "ngx-stripe";
import { TermsOfUsePageComponent } from "./terms-of-use-page/terms-of-use-page.component";
import { NgxPaginationModule } from "ngx-pagination";
import { Ng2SearchPipeModule } from "ng2-search-filter";
import { SelectModule } from "ng-select";
import { KeyMetricsModule } from "./components/key-metrics/key-metrics.module";
import { PrivacyPolicyPageComponent } from './privacy-policy-page/privacy-policy-page.component';
import { EcgReportRoutingModule } from './components/radiology/ecg-report/ecg-report-routing.module';
import { CurrencyService } from "./others/services/currency.service";
import { FormsModule } from '@angular/forms';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { AllergiesModule } from "./components/allergies/allergies.module";
import { HistoryModule } from "./components/historys/history.module";
import { ProbDiagnosisModule } from "./components/prob-diagnosis/prob-diagnosis.module";
import { LabStudiesModule } from "./components/lab-studies/lab-studies.module";
import { VitalsModule } from "./components/vitals/vitals.module";
import { MedicationsModule } from "./components/medications/medications.module";
import { TableDateModule } from "./table-date/table-date.module";


@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
    AuthComponent,
    UserComponent,
    NavigationComponent,
    NavContentComponent,
    NavGroupComponent,
    NavCollapseComponent,
    NavItemComponent,
    NavBarComponent,
    NavLeftComponent,
    NavSearchComponent,
    NavRightComponent,
    ConfigurationComponent,
    ToggleFullScreenDirective,
    SupportComponent,
    TermsOfUsePageComponent,
    PrivacyPolicyPageComponent,
    // Base64ImagePipe,
    // KeyMetricsComponent,
    // TatComponent,
    // StaffCollectionComponent,
    // DiscountsComponent,
    // ReceivablesComponent,
    // PerformanceComponent,
    // ClientReportsComponent,
    // UTObstetricsComponent,
    // TesticularBiopsyComponent,
    // RequestingFacilityFormComponent,
    // RequestingDoctorFormComponent,
    // SubscriptionComponent,
    // FacilityProfileComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    NgbDropdownModule,
    NgbTooltipModule,
    NgbButtonsModule,
    NgbTabsetModule,
    PerfectScrollbarModule,
    NgxStripeModule.forRoot("pk_test_ZA9wEYR4CLSY8vH0dKhU8XFs00vJ8hDytz"),
    ToastrModule.forRoot(),
    NgxSpinnerModule,
    HttpClientModule,
    QuillModule.forRoot(),
    // CKEditorModule,
    NgxPaginationModule,
    Ng2SearchPipeModule,
    SelectModule,
    KeyMetricsModule,
    EcgReportRoutingModule,
    NgxIntlTelInputModule,
    AllergiesModule,
    HistoryModule,
    ProbDiagnosisModule,
    LabStudiesModule,
    VitalsModule,
    MedicationsModule,
    GooglePlaceModule,
    TableDateModule

  ],
  providers: [NavigationItem, CurrencyService],
  bootstrap: [AppComponent],
  // exports:[Base64ImagePipe]
})
export class AppModule {
}
