import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-privacy-policy-page',
  templateUrl: './privacy-policy-page.component.html',
  styleUrls: [
    "../components/css/bootstrap.min.css",
    "../components/css/style.component.css",
    './privacy-policy-page.component.scss',
  ],
})
export class PrivacyPolicyPageComponent implements OnInit {

  constructor() { }

  img = "../../assets/img/logo.png";
  copyright: any = 2021;

  ngOnInit() {
    this.copyright = new Date().getFullYear();
  }

}
