import { Component, OnInit, ViewChild } from "@angular/core";
import { AuthenticationService } from "../../../others/services/authentication.service";
import { NgxSpinnerService } from "ngx-spinner";
import { isAfter, isBefore, isEqual } from "date-fns";
import { GeneralService } from "../../../services/general.service";
import { FormControl } from "@angular/forms";
import { debounceTime, distinctUntilChanged, filter, switchMap, tap } from "rxjs/operators";
import { KeyMetricsService } from "src/app/others/services/key-metrics.service";

declare let $: any;

@Component({
  selector: "app-staff-collection",
  templateUrl: "./staff-collection.component.html",
  styleUrls: ["./staff-collection.component.scss"],
})
export class StaffCollectionComponent implements OnInit {
  @ViewChild("modalView") viewModal;

  singleCurrency = "";
  currencyNme: any;
  currencySym: any;
  currencyBase: any;

  patientPortalList = [];
  allStaffList: any = [];
  staffList: any = [];
  searchedStaffList: any = [];
  yearList: any = [];

  searchText: "";
  actionButtons = true;

  permissions: any = [];
  loading: any = false;
  desc: any = false;
  searchVal: any = false;

  selectedStaff: any;
  companyData: any;
  fromDate: any;
  toDate: any;

  detailPage = 0;
  totalBilled = 0;
  totalPaid = 0;
  totalPortal = 0;
  totalArrears = 0;
  patientPortalReceived = 0;
  p = 0;

  year = new Date().getFullYear();
  // dtOptions: DataTables.Settings={};
  patientList: any;
  patientItems: any[];
  getAllClinicalModule: any;
  searchForm = new FormControl('');
  getStaffData: any;
  getPortalPayment: any;
  getBilledTotal: any;
  getArrears: any;
  getPaidInApp: any;
newStartDate: any;
newEndDate: any;

  constructor(
    public auth: AuthenticationService,
    private spinner: NgxSpinnerService,
    private generalService: GeneralService,
    private keyMetrics: KeyMetricsService
  ) {}

  ngOnInit() {
    this.getPermissions();
    this.getStaffs();
    this.singleCurrency = this.userArray(sessionStorage.getItem("currency"));
    this.currencyNme = this.singleCurrency[0];
    this.currencySym = this.singleCurrency[1];
    this.currencyBase = this.singleCurrency[2];
    this.getComProfile();
    // this.dtOptions={
    //   pagingType: "simple_numbers",
    //   pageLength: 10,
    //   lengthMenu: [10, 30, 50],
    //   processing: true,
    //   responsive: true,
    //   // "order": [[2, "desc"]],
    // }
    this.searchForm.valueChanges
    .pipe(
      tap((a) => a),
      filter(() => this.searchForm.valid),
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((text) => {
        return this.keyMetrics.onSearchForPatient(text);
      })
      ).subscribe({
        next: (response) =>{
          // console.log(response['data'])
          this.patientItems = response['data']
        }
      })
  }

   // Get user permissions
//  getPermissions() {
//   this.permissions = {
//     staffCollections: {
//       view: false
//     },
//   };

//   this.auth
//     .get("user_permissions/" + sessionStorage.getItem("userID")).toPromise()
//     .then(
//       (response: any) => {
//         this.permissions = response.permissions.permissions[0];
//         this.actionButtons = Object.keys(this.permissions.staffCollections).every((key) => {
//           return key == "create" ? false : this.permissions.staffCollections[key];
//         })
//       }
//     );
// }
getPermissions() {
  this.auth.get('user_permissions/' + sessionStorage.getItem('userID')).subscribe(
    (response: any) => {
      this.permissions = response.permissions.permissions[0];
    },
    (error: any) => {

    }
  );
}
  search(){
    // console.log(this.searchForm.value)
  }

  userArray(str) {
    return str.trim().split(",");
  }

  getInputDate() {
    if (this.newStartDate && !this.newEndDate) {
      this.searchVal = true;
      this.auth.get(`staff_collection?start_date=${this.newStartDate}`).subscribe({
        next: (response: any) => {
          // console.log("Data from API", response);
          this.getStaffData = response['data'];
          // console.log("getStaffData" , this.getStaffData)
          this.getPortalPayment = response.totalPortalPayment
          this.getBilledTotal = response.totalAmountBilled
          this.getArrears = response.totalAreas
          this.getPaidInApp = response.totalInAppPayment
        }
      });
      // console.log("New Start Date", this.newStartDate);

    } else if (this.newEndDate && !this.newStartDate) {
      this.searchVal = true;
      this.auth.get(`staff_collection?end_date=${this.newEndDate}`).subscribe({
        next: (response: any) => {
          // console.log("Data from API", response);
          this.getStaffData = response['data'];
          // console.log("getStaffData" , this.getStaffData)
          this.getPortalPayment = response.totalPortalPayment
          this.getBilledTotal = response.totalAmountBilled
          this.getArrears = response.totalAreas
          this.getPaidInApp = response.totalInAppPayment
        }
      });
      // console.log("New End Date", this.newEndDate);

    } else if (this.newStartDate && this.newEndDate) {
      this.searchVal = true;
      this.auth.get(`staff_collection?start_date=${this.newStartDate}&end_date=${this.newEndDate}`).subscribe({
        next: (response: any) => {
          // console.log("Data from API", response);
          this.getStaffData = response['data'];
          // console.log("getStaffData" , this.getStaffData)
          this.getPortalPayment = response.totalPortalPayment
          this.getBilledTotal = response.totalAmountBilled
          this.getArrears = response.totalAreas
          this.getPaidInApp = response.totalInAppPayment
        }
      });
      // console.log("New Start Date", this.newStartDate);
      // console.log("New End Date", this.newEndDate);
    } else {
      // Handle the case when start date or end date is missing.
      // console.log("Please enter both start date and end date.");
    }
  }


  getStaffs() {
    this.spinner?.show();
    this.auth.get("staff_collection").subscribe((response: any) => {
      //  console.log("New Data Response -> ", response);
      this.getStaffData = response['data'];
      // console.log("getStaffData" , this.getStaffData)
      this.getPortalPayment = response.totalPortalPayment
      this.getBilledTotal = response.totalAmountBilled
      this.getArrears = response.totalAreas
      this.getPaidInApp = response.totalInAppPayment

      // console.log("getPortal" , this.getPortalPayment)
      // console.log("getBilled" , this.getBilledTotal)
      // console.log("getArrears" , this.getArrears)
      // console.log("getPaid" , this.getPaidInApp)

      this.staffList = response.data.filter((ele) => ele.billed_by !== null);

      this.searchedStaffList = this.yearList = this.staffList.filter(
        (ele) => new Date(ele.created_at).getFullYear() === this.year
      );

      this.getStaffList();
      this.getPortalReceived();
      this.getTotalBilled();
      this.getTotalArrears();
      this.getTotalPaid();
    });

    this.spinner?.hide();
  }


  /**
   * It takes an array of objects and returns an array of objects that
   * contains the user and all the reports that belong to that user
   */
  getStaffList() {
    const a = [];
    const b = [];
    const lists = this.searchedStaffList;
    /* Looping through the array of objects and adding the user and all the reports that belong to that user to a new
    array. */
    for (const list of lists) {
      if (!a.includes(list.billed_by) && list.billed_by !== null) {
        /* Filtering the list array to only include the elements that have a user that is equal to the user of the current
        list element. */
        const reports = lists.filter((ele) => ele.billed_by === list.billed_by);
        const receiveList = lists.filter(
          (ele) => ele.received_by === list.billed_by
        );
        const billed = this.getStaffBilled(reports);
        const received = this.getStaffPaid(receiveList);
        b.push({
          staff: list.billed_by,
          billed,
          received,
          reports,
          receiveList,
        });
        a.push(list.billed_by);
      }
    }
    //  console.log(b);
    this.allStaffList = b;
  }

  getPortalReceived() {
    let amount = 0;
    const lists = (this.patientPortalList = this.searchedStaffList?.filter(
      (ele) => ele.received_by === null
    ));

    for (const list of lists) {
      amount += list?.amount_paid;
    }

    //  console.log("portal lists", lists);

    this.patientPortalReceived = amount;
  }

  /**
   * It loops through the array of objects and adds the value of the property "total_amount" to the variable "a"
   */
  getTotalBilled() {
    let a = 0;
    for (const list of this.searchedStaffList) {
      a += list?.total_amount;
    }

    this.totalBilled = a;
  }

  getStaffBilled(lists: any) {
    let a = 0;
    for (const list of lists) {
      a += list?.total_amount;
    }

    return a;
  }

  /**
   * It loops through the array of objects and adds the value of the amount_paid property to the variable a
   */
  getTotalPaid() {
    let a = 0;
    for (const list of this.searchedStaffList?.filter(ele => ele.received_by !== null) ){
      a += list?.amount_paid;
    }

    this.totalPaid = a;
  }

  getStaffPaid(lists: any) {
    let a = 0;
    for (const list of lists) {
      a += list?.amount_paid;
    }

    return a;
  }

  /**
   * It loops through the array of objects, and adds the value of the arrears property of each object to the variable a
   */
  getTotalArrears() {
    let a = 0;
    for (const list of this.searchedStaffList) {
      a += list?.arrears;
    }

    this.totalArrears = a;
  }

  /**
   * It clears the search fields and resets the staff list to the original list
   */
  clear() {
    this.newStartDate = (" ")
    this.newEndDate = (" ")
    this.getStaffData
    this.getPortalPayment
    this.getBilledTotal
    this.getArrears
    this.getPaidInApp
    this.getInputDate();
    // $("#to").val("");
    // $("#from").val("");
    // this.searchedStaffList = this.yearList;
    // this.searchVal = false;
    // this.getPortalReceived();
    // this.getTotalBilled();
    // this.getTotalArrears();
    // this.getTotalPaid();
    // this.changeOrder();
  }

  /**
   * It filters the staffList array to only include the elements that have a created_at date that is equal to or after the
   * from date and equal to or before the to date
   * @param {Event} $event - Event - The event object that is passed to the function when the form is submitted.
   */
  onIntervalSubmit($event: Event) {
    $event.preventDefault();
    const fromVal = $("#from").val();
    const toVal = $("#to").val();

    if (fromVal !== "" && toVal !== "") {
      this.searchVal = true;
      this.loading = true;

      /* Converting the date to a string and then converting it back to a date. */
      const from = new Date(new Date(fromVal).toDateString());
      const to = new Date(new Date(toVal).toDateString());

      this.fromDate = from.toDateString();
      this.toDate = to.toDateString();

      /* Filtering the tatList array to only include the elements that have a created_at date that is equal to or after the
     from date and equal to or before the to date. */
      this.searchedStaffList = this.staffList.filter((ele) => {
        const createdAt = new Date(new Date(ele.created_at).toDateString());
        return (
          (isEqual(from, createdAt) || isAfter(createdAt, from)) &&
          (isEqual(to, createdAt) || isBefore(createdAt, to))
        );
      });
      this.getStaffList();
      this.getPortalReceived();
      this.getTotalBilled();
      this.getTotalArrears();
      this.getTotalPaid();
      this.loading = false;
    } else {
      this.generalService.emptyDateFieldError();
    }
  }

  /**
   * This function is used to get the company profile data from the database
   */
  getComProfile() {
    this.spinner?.show();
    this.auth.get("facility_profile").subscribe(
      (response: any) => {
        if (response.company !== null) {
          this.companyData = response.company;
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        // console.log(error);
      }
    );
  }

  /**
   * We're creating a new div element, appending it to the body, copying the contents of the element we want to print into
   * the new div, printing the new div, and then removing the new div from the body
   */
  printElement() {
    const elem = document.getElementById("contentToPrint");
    const domClone = elem.cloneNode(true);

    const $printSection = document.createElement("div");
    $printSection.style.padding = "15px 5px";
    $printSection.id = "printSection";
    document.body.appendChild($printSection);

    $printSection.innerHTML = "";
    $printSection.appendChild(domClone);
    window.print();
    $printSection.style.padding = "0px";
    $printSection.innerHTML = "";
  }

  /**
   * The function is called when the user clicks on the "View" button in the table. It sets the selectedStaff variable to
   * the selected row in the table and then shows the viewModal
   * @param selected - The selected staff object
   */
  view(selected) {
    this.selectedStaff = selected;
    this.detailPage = 0;
    this.viewModal.show();
  }
}
