import { UtilityService } from './../../../../../others/services/utility.service';
import { Component, OnInit } from '@angular/core';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from "src/app/others/services/authentication.service";
import { AlertService } from 'src/app/others/services/alert.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from "@angular/router";

@Component({
  selector: "app-nav-right",
  templateUrl: "./nav-right.component.html",
  styleUrls: ["./nav-right.component.scss"],
  providers: [NgbDropdownConfig],
})
export class NavRightComponent implements OnInit {
  user_array = (str) => {
    return str.trim().split(",");
   };
  singleCurrency = this.user_array(sessionStorage.getItem("currency"));
  currencyNme = this.singleCurrency[0];
  currencySym = this.singleCurrency[1];
  currencyBase = this.singleCurrency[2];
  
  id: string;
  username: string;
  user: any;

  constructor(
    public auth: AuthenticationService,
    private alert: AlertService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private ulitity: UtilityService
  ) { }

  ngOnInit() {
    this.id = sessionStorage.getItem("userID");
    this.getUser();
  }

  //.............................................................. programmer added ...........................................................
  getUser() {
    this.auth.show("user", this.id).subscribe(
      (response) => {
        this.user = response["user"];
        // sessionStorage.removeItem("username");
        // sessionStorage.setItem("username", this.user.othernames);
        this.username = sessionStorage.getItem("username");
      },
      (error) => {
        // console.log(error);
      }
    );
  }

  // logout method
  // logout() {
  //   this.auth.logout()
  //   sessionStorage.clear();
  //   console.log("Logout success");
  //   this.router.navigate(["/auth/signin/"]);

  // }

  logout() {
    this.spinner.show();
    this.auth.logout().subscribe(
      (res) => {
        // this.utils.resetProfile();
        // console.log(res);
        sessionStorage.clear();
        this.router.navigate(['/auth/signin/']);
        // Swal.fire('', res['message'], 'success');
        this.alert.success(res['message']);
        this.spinner.hide();

        this.ulitity.stopTimer();
      },
      (err) => {
        // console.log(err);
        this.spinner.hide();
        this.alert.error('Could not log out, please try again');
      }
    );
  }

  checkLogin() {
    this.auth.isLoggedIn();
  }
  //.............................................................. programmer added ...........................................................
}
