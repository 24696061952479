import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-terms-of-use-page",
  templateUrl: "./terms-of-use-page.component.html",
  styleUrls: [
    "../components/css/bootstrap.min.css",
    "../components/css/style.component.css",
    "./terms-of-use-page.component.scss",
  ],
})
export class TermsOfUsePageComponent implements OnInit {
  constructor() {}

  img = "../../assets/img/logo.png";
  copyright: any = 2021;

  ngOnInit() {
    this.copyright = new Date().getFullYear();
  }
}
