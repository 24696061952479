<div class="card">
    <div class="card-body">
        <div class="col-sm-12 col-lg-6">
            <div class="row">
                <div class="col-sm-12" style="min-height: 10em;">
                    <div class="category-section"></div>
                    <hr>
                    <h5>Allergies</h5>
                    <hr>
                    <div>
                        <div>
                        </div>
                        <span
                         *ngIf="!patient">
                         <em>No Allergies</em></span>
                        <div>
                            {{patient}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>