import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'sanitizer'
})
export class SanitizerPipe implements PipeTransform {
  trustedHtmlImpression: any;
  constructor(private sanitizer: DomSanitizer) {}

  transform(value: any) {
    this.trustedHtmlImpression = this.sanitizer.bypassSecurityTrustHtml(value);
    return this.trustedHtmlImpression;
    // console.log(this.trustedHtmlImpression)
  }

}
