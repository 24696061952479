import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableDateComponent } from './table-date.component';
import { AgeTransformPipe } from '../pipe/age-transform.pipe';
import { DateTransformPipe } from '../pipe/date-transform.pipe';



@NgModule({
  declarations: [
    TableDateComponent,
    AgeTransformPipe,
    DateTransformPipe
  ],
  imports: [
    CommonModule,
    
  ],
  exports: [TableDateComponent]
})
export class TableDateModule { }
