import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import "sweetalert2/src/sweetalert2.scss";
import { AuthenticationService } from "src/app/others/services/authentication.service";
import Swal from "sweetalert2";
import 'jquery';
import 'src/assets/jconfirm/js/jquery-confirm';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

declare let $: any;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  // styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  loggedIn: boolean;
  requirePassword: any;
  name: any;
 


  constructor(
    private auth: AuthenticationService, 
  
    private router: Router) {}

  ngOnInit() {


   
   
    this.getProfile();
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
    // this.slider();
    // sessionStorage.clear();
  }




  checkLogin() {
    if (this.auth.isLoggedIn() === true) {
      sessionStorage.setItem("checkIn", "true");
      this.loggedIn = true;
    } else {
      this.loggedIn = false;
      // this.router.navigate(["auth/signin"]);
    }
  }

  checkPasswordChange() {
    this.requirePassword = JSON.parse(sessionStorage.getItem("user"));
    if (this.requirePassword["useraccount"]["requirepasswordchange"] === true) {
      this.loggedIn = false;
    } else {
      // console.log("User requires no password change");
    }
  }

  logout() {
    this.auth.logout();
  }

  getProfile() {
    this.auth.get("facility_profile").subscribe(
      (response) => {
        if (response["company"] !== null) {
          const comData = response["company"];
          // sessionStorage.removeItem("company_name");
          sessionStorage.setItem("company_name", comData.name_of_facility);
        }
      },
      (error) => {
        // console.log(error);
      }
    );
  }

  basicSwal() {
    Swal.fire("", "Hello world!");
  }

  successSwal() {
    Swal.fire("Good job!", "You clicked the button!", "success");
  }

  warningSwal() {
    Swal.fire("Good job!", "You clicked the button!", "warning");
  }

  dangerSwal() {
    Swal.fire("Good job!", "You clicked the button!", "error");
  }

  infoSwal() {
    Swal.fire("Good job!", "You clicked the button!", "info");
  }

  confirmAlert() {
    Swal.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this imaginary file!",
      icon: "warning",
      showCloseButton: true,
      showCancelButton: true,
    }).then((willDelete) => {
      if (willDelete.dismiss) {
        Swal.fire("", "Your imaginary file is safe!", "error");
      } else {
        Swal.fire("", "Poof! Your imaginary file has been deleted!", "success");
      }
    });
  }

  promptAlert() {
    Swal.fire({
      text: "Write something here:",
      input: "text",
    }).then((result) => {
      if (result.value) {
        Swal.fire("", `You typed: ${result.value}`);
      }
    });
  }

  ajaxAlert() {
    Swal.fire({
      text: "Submit your Github username",
      input: "text",
      inputAttributes: {
        autocapitalize: "off",
      },
      showCancelButton: true,
      confirmButtonText: "Look up",
      showLoaderOnConfirm: true,
      preConfirm: (login) => {
        return fetch(`//api.github.com/users/${login}`)
          .then((response) => {
            if (!response.ok) {
              throw new Error(response.statusText);
            }
            return response.json();
          })
          .catch((error) => {
            Swal.showValidationMessage(`Request failed: ${error}`);
          });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.value) {
        Swal.fire({
          title: `${result.value.login}'s avatar`,
          imageUrl: result.value.avatar_url,
        });
      }
    });
  }

  // public Editor = ClassicEditor;

  // slider() {
  //   let ok = document.getElementById(".aut-bg");
  //   // let i = 1; // = 1 because we want to load first color/image onload
  //   // const color = ["black", "blue", "brown", "green"]; //change colors to url's
  //   //     function change() {
  //   //   ok.style.backgroundColor = color[i];//change to background= url(color[i])
  //   //   i = (i + 1) % color.length;
  //   // }
  //   // document.body.style.backgroundColor = color[0]; // this will load first collor/image
  //   // setInterval(change,8000);

  //   var images = [
  //     './../assets/images/auth/lab.jpg',
  //     './../assets/images/auth/lab2.jpg',
  //     './../assets/images/auth/lab3.jpg',
  //  ];

  //  var count = 0
  //  setInterval(()=> {
  //     ok.style.backgroundImage =
  //        "url(" + images[count++] + ")";
  //     if (count == 3) {
  //        count = 0;
  //     }
  //  }, 8000);
  // }
}
