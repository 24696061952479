import { UtilityService } from "./../services/utility.service";
import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class SubGuard implements CanActivate {
  constructor(
    private router: Router,
    private utility: UtilityService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const subscription_data = JSON.parse(
      sessionStorage.getItem("subscription_data")
    );

    return !subscription_data.subscriptionExpired;//TODO: change is to subscription expire
  }
}
