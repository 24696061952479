<div  *ngIf="permissions?.balance?.view " class="row mb-4 align-items-center" style="margin-top: -133px">
  <div class="col-lg-6 col-md-6">
    <h3 class="">Balance</h3>
  </div>
</div>

<div class="card"  *ngIf="permissions?.balance?.view " >
  <div class="card-header py-3">
    <div class="row">
      <div class="col">
        <h4>Interval</h4>
      </div>
      <div [ngClass]="searchVal ? 'd-none' : 'd-flex-inline'" class="col text-right">
        <p class="mb-0 small">Current Data</p>
        <h5 class="mr-0">{{year}}</h5>
      </div>
      <div [ngClass]="searchVal ? 'd-flex-inline' : 'd-none'" class="col text-right">
        <button (click)="clear()" class="btn btn-danger btn-sm"
                type="button">
          Clear
        </button>
      </div>
    </div>
  </div>
  <div class="card-body">
    <form (submit)="onIntervalSubmit($event)" action="">
      <div class="row">
        <div class="col-md-6 col-sm-12">
          <input class="form-control" id="from" onblur="(this.type='text')"
                 onfocus="(this.type='date')"
                 placeholder="Click to enter date" required type="text">
          <label for="from">From:</label>
        </div>
        <div class="col-md-6 col-sm-12">
          <input class="form-control" id="to" onblur="(this.type='text')"
                 onfocus="(this.type='date')"
                 placeholder="Click to enter date" required type="text">
          <label for="to">To:</label>
        </div>
        <div class="col-md-12 col-sm-12 d-flex justify-content-center">
          <button [disabled]="false"
                  class="btn btn-success"
                  style="padding:10px 15%;"
                  type="submit">

            <span *ngIf="this.loading" class="spinner-border spinner-border-sm" role="status"></span>
            Search<span *ngIf="this.loading" class="load-text">ing...</span>
          </button>
        </div>
      </div>
    </form>
  </div>
</div>

<div class="row" *ngIf="permissions?.balance?.view">
  <div class="col-md-4 col-sm-6">
    <div class="card">
      <div class="card-header bg-paid text-center border-top-radius" style="background-color: rgb(25, 150, 25);">
        <h4 class="mb-0">Income</h4>
      </div>
      <div class="card-body">
        <h4 class="">{{currencyBase}} {{totalIncome | number: "1.2"}}</h4>
      </div>
    </div>
  </div>
  <div class="col-md-4 col-sm-6">
    <div class="card">
      <div class="card-header bg-arrears text-center border-top-radius" style="background-color: rgb(233, 38, 38);">
        <h4 class="mb-0">Expense</h4>
      </div>
      <div class="card-body">
        <h4 class="">{{currencyBase}} {{totalExpense | number: "1.2"}}</h4>
      </div>
    </div>
  </div>
  <div class="col-md-4 col-sm-6">
    <div class="card">
      <div class="card-header bg-portal text-center border-top-radius" style="background-color: rgb(251, 195, 40);">
        <h4 class="mb-0">Balance</h4>
      </div>
      <div class="card-body">
        <h4 class="">{{currencyBase}} {{totalIncome - totalExpense | number: "1.2"}}</h4>
      </div>
    </div>
  </div>
</div>
