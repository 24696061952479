import { Component, OnInit, ViewChild } from "@angular/core";
import { AuthenticationService } from "../../../others/services/authentication.service";
import { NgxSpinnerService } from "ngx-spinner";
import { GeneralService } from "../../../services/general.service";
import { isAfter, isBefore, isEqual } from "date-fns";
import "jquery";


declare let $: any;
@Component({
  selector: 'app-cases',
  templateUrl: './cases.component.html',
  styleUrls: ['./cases.component.scss']
})
export class CasesComponent {
  @ViewChild("modalView") viewModal;

  searchedClientList: any[] = [];
  clientList: any[] = [];
  clientReportsList: any[] = [];
  generalServices: any;
  searchVal = false;
  loading = false;
  actionButtons = true;
  doctorReportList: any;
  totalForms = 0;
  doctorFormsDetails: any = [];
  allDoctorData: any = [];
  doctorName2: any;
  speciality: any;
  doctorName: any;
  newStartDate: any;
  newEndDate: any;
  permissions: any = [];
  singleCurrency = "";
  currencyNme: any;
  currencySym: any;
  currencyBase: any;
  selectedClient: any;
  selectedDoctor: any;
  fromDate: any;
  toDate: any;
  getAllDoctors: any = [];
  getCases: any = [];
  searchText: "";
  companyData: any;
  p = 0;
  detailPage = 0;
  allDoctorDetailPage = 0;
  dtOptions: DataTables.Settings={};
  serviceTypeArray: any;

  constructor(
    public auth: AuthenticationService,
    private spinner: NgxSpinnerService,
    private generalService: GeneralService
  ) {}

  ngOnInit() {
    this.getPermissions();
    this.getDoctor();
    // this.getClientReports();
    this.generalServices = this.generalService;
    this.singleCurrency = this.userArray(sessionStorage.getItem("currency"));
    this.currencyNme = this.singleCurrency[0];
    this.currencySym = this.singleCurrency[1];
    this.currencyBase = this.singleCurrency[2];
    this.getComProfile();

    this.dtOptions={
      pagingType: "simple_numbers",
      pageLength: 10,
      lengthMenu: [10, 30, 50],
      processing: true,
      responsive: true,
      // "order": [[2, "desc"]],
    }
  }

getPermissions() {
  this.permissions = {
    clients: {
      view: false,
      },
  };
  this.auth.get('user_permissions/' + sessionStorage.getItem('userID')).subscribe(
    (response: any) => {
      this.permissions = response.permissions.permissions[0];
    },
    (error: any) => {

    }
  );
}

  userArray(str) {
    return str.trim().split(",");
  }

  getDoctor() {
    // this.spinner.show();
    this.auth.get("doctor_cases").subscribe((response: any) => {
      // console.log(response);
      this.getAllDoctors = response['doctors'];
      // console.log(this.getAllDoctors)
      this.getCases = response['data']
      // console.log(this.getCases)

      // this.getTotalCases();


    });
    this.spinner.hide();
  }

  // getCasesView(id: any){
  //   this.auth.get(`doctor_cases?id=${id}`).subscribe((response: any) => {
  //     console.log("Cases form View", response)
  //     let data = response['doctor']['data'][0];
  //     this.doctorName = data?.doctor;
  //     console.log("Docs Nameww", data?.doctor)
  //     this.doctorFormsDetails = response['doctor']['data']
  //     console.log("details", this.doctorFormsDetails)
  //   })
  //   this.detailPage = 0;
  //   this.viewModal.show();
  // }
  getCasesView(id: any) {
    if (this.newStartDate && !this.newEndDate) {
      this.auth.get(`doctor_cases?id=${id}&start_date=${this.newStartDate}`).subscribe({
        next: (response: any) => {
          this.doctorFormsDetails = response['doctor']['data'];
          let name = response['doctor']['doctor_info'];
          this.doctorName2 = name?.full_name
          this.speciality = name?.speciality
         // console.log("doc new", name?.full_name)
        }
      });
    } else if (this.newEndDate && !this.newStartDate){
      this.auth.get(`doctor_cases?id=${id}&end_date=${this.newEndDate}`).subscribe({
        next: (response: any) => {
          this.doctorFormsDetails = response['doctor']['data'];
          let name = response['doctor']['doctor_info'];
          this.doctorName2 = name?.full_name
          this.speciality = name?.speciality
         // console.log("doc new", name?.full_name)
        }
      });
    } else if (this.newStartDate && this.newEndDate) {
      this.auth.get(`doctor_cases?id=${id}&start_date=${this.newStartDate}&end_date=${this.newEndDate}`).subscribe((response: any) => {
        this.doctorFormsDetails = response['doctor']['data'];
        let name = response['doctor']['doctor_info'];
        this.doctorName2 = name?.full_name
        this.speciality = name?.speciality
      //  console.log("doc new", name?.full_name)
      });
    } else {
      this.auth.get(`doctor_cases?id=${id}`).subscribe((response: any) => {
        this.doctorFormsDetails = response['doctor']['data'];
        let name = response['doctor']['doctor_info'];
        this.doctorName2 = name?.full_name
        this.speciality = name?.speciality
       // console.log("doc new", name?.full_name)
      });
    }
    this.detailPage = 0;
    this.viewModal.show();
  }

  // getAllDoctorData() {
  //   this.spinner.show();
  //     this.auth.get(`doctor_cases`).subscribe((response: any) => {
  //       this.allDoctorData = response['data'];
  //       console.log('ALL DOCTOR DATA' , this.allDoctorData)
  //     });
  //   this.spinner.hide();
  // }
  

  getAllDoctorData() {
    if (this.newStartDate && !this.newEndDate) {
      // this.searchVal = true;
      this.auth.get(`doctor_cases?start_date=${this.newStartDate}`).subscribe({
        next: (response: any) => {
          this.allDoctorData = response['data'];
        //  console.log('ALL DOCTOR DATA' , this.allDoctorData)
        }
      });
    } else if (this.newEndDate && !this.newStartDate){
      this.auth.get(`doctor_cases?end_date=${this.newEndDate}`).subscribe({
        next: (response: any) => {
          this.allDoctorData = response['data'];
          // console.log('ALL DOCTOR DATA' , this.allDoctorData)
        }
      });
    } else if (this.newStartDate && this.newEndDate) {
      this.auth.get(`doctor_cases?start_date=${this.newStartDate}&end_date=${this.newEndDate}`).subscribe({
        next: (response: any) => {
          this.allDoctorData = response['data'];
        //  console.log('ALL DOCTOR DATA' , this.allDoctorData)
        }
      });
    } else { 
      this.auth.get(`doctor_cases`).subscribe((response: any) => {
        this.allDoctorData = response['data'];
       // console.log('ALL DOCTOR DATA' , this.allDoctorData)
      });
    }
    this.allDoctorDetailPage = 0;
  }
  
  // getInputDate() {
  //   if (this.newStartDate && this.newEndDate) {
  //     this.searchVal = true;
  //     this.auth.get(`doctor_cases?start_date=${this.newStartDate}&end_date=${this.newEndDate}`).subscribe({
  //       next: (response: any) => {
  //         console.log("Data from API", response);
  //         this.getAllDoctors = response['doctors'];
  //         console.log(this.getAllDoctors)
  //         this.getCases = response['data']
  //         console.log(this.getCases)
  //       }
  //     });
  //     console.log("New Start Date", this.newStartDate);
  //     console.log("New End Date", this.newEndDate);
  //   } else {
  //     // Handle the case when start date or end date is missing.
  //     console.log("Please enter both start date and end date.");
  //   }
  // }
  
  getInputDate () {
    if (this.newStartDate && !this.newEndDate) {
      this.searchVal = true;
      this.auth.get(`doctor_cases?start_date=${this.newStartDate}`).subscribe({
        next: (response: any) => {
         // console.log("First Date Data", response);
          this.getAllDoctors = response['doctors'];
       //   console.log(this.getAllDoctors)
          this.getCases = response['data']
        //  console.log(this.getCases)
        }
      });
    } else 
    if (this.newEndDate && !this.newStartDate){
      this.searchVal = true;
      this.auth.get(`doctor_cases?end_date=${this.newEndDate}`).subscribe({
        next: (response: any) => {
         // console.log("End Date Data", response);
          this.getAllDoctors = response['doctors'];
        //  console.log(this.getAllDoctors)
          this.getCases = response['data']
        //  console.log(this.getCases)
        }
      });
    } else 
    if (this.newStartDate && this.newEndDate) {
      this.searchVal = true;
      this.auth.get(`doctor_cases?start_date=${this.newStartDate}&end_date=${this.newEndDate}`).subscribe({
        next: (response: any) => {
        //  console.log("Data from API", response);
          this.getAllDoctors = response['doctors'];
         // console.log(this.getAllDoctors)
          this.getCases = response['data']
         // console.log(this.getCases)
        }
      });
    } else {
      // Handle the case when start date or end date is missing.
     // console.log("Please enter a date.");
    }
  }


  // getTotalCases() {
  //   let a = 0;
  //   for (const list of this.getAllDoctors) {
  //     a += list?.total_forms;
  //   }

  //   this.totalForms = a;
  // }

  // getClientReports() {
  //   this.spinner.show();
  //   this.auth.get("doctor_cases").subscribe((response: any) => {
  //     const a = [];
  //     for (const res of response) {
  //       a.push(...res);
  //     }

  //     this.searchedClientList = this.clientList = a;
  //     this.getClientList();
  //   });
  //   this.spinner.hide();
  // }

  // getClientList() {
  //   const lists = this.searchedClientList;
  //   const a = [];
  //   const b = [];

  //   for (const list of lists) {
  //     const client = list.requesting_facility;
  //     const doctor = list.requesting_doctor;
  //     if (!a.includes(client)) {
  //       const reports = lists.filter(
  //         (ele) => ele.requesting_facility === client
  //       );

  //       b.push({ client, reports, doctor });
  //       a.push(client);
  //     }
  //   }

  //   this.clientReportsList = b;
  // }

  /**
   * The function clears the search fields and resets the search results to the original list
   */
  // clear() {
  //   $("#to").val("");
  //   $("#from").val("");
  //   // this.searchedClientList = this.clientList;
  //   // this.searchVal = false;
  //   // this.getClientList();
  //   // this.changeOrder();
  // }
  clear() {
    this.newStartDate = (" ");
    this.newEndDate = (" ");
    this.getInputDate();
    this.getAllDoctors();
    this.getCases();
    this.doctorFormsDetails();
    this.searchVal = false;
  }

  /**
   * It filters the staffList array to only include the elements that have a created_at date that is equal to or after the
   * from date and equal to or before the to date
   * @param {Event} $event - Event - The event object that is passed to the function when the form is submitted.
   */
  // onIntervalSubmit($event: Event) {
  //   $event.preventDefault();
  //   const fromVal = $("#from").val();
  //   const toVal = $("#to").val();

  //   if (fromVal !== "" && toVal !== "") {
  //     this.searchVal = true;
  //     this.loading = true;

  //     /* Converting the date to a string and then back to a date. */
  //     const from = new Date(new Date(fromVal).toDateString());
  //     const to = new Date(new Date(toVal).toDateString());

  //     this.fromDate = from.toDateString();
  //     this.toDate = to.toDateString();

  //     /* Filtering the tatList array to only include the elements that have a created_at date that is equal to or after the
  //    from date and equal to or before the to date. */
  //     this.searchedClientList = this.clientList.filter((ele) => {
  //       const createdAt = new Date(new Date(ele.created_at).toDateString());
  //       return (
  //         (isEqual(from, createdAt) || isAfter(createdAt, from)) &&
  //         (isEqual(to, createdAt) || isBefore(createdAt, to))
  //       );
  //     });
  //     // this.getClientList();
  //     this.loading = false;
  //   } else {
  //     this.generalService.emptyDateFieldError();
  //   }
  // }

  view(selected: any) {
    this.selectedClient = selected;
    this.detailPage = 0;
    this.viewModal.show();
  }

  /**
   * This function is used to get the company profile data from the database
   */
  getComProfile() {
    this.spinner.show();
    this.auth.get("facility_profile").subscribe(
      (response: any) => {
        if (response.company !== null) {
          this.companyData = response.company;
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }

  csvAllData(){
    const csvAllFileData = [];

    let docData = this.allDoctorData
   // console.log('DOCTOR' , docData);

    for (const res of docData) {
      const serviceType = res?.service_type;
      if(serviceType && serviceType.length > 1){
        this.serviceTypeArray = serviceType.join(', ');
      //  console.log("service type" , this.serviceTypeArray)
      } else {
        this.serviceTypeArray = res?.service_type
      }
    //  console.log("res" , res)
      csvAllFileData.push({
        doctor: res?.doctor,
        specimen: res?.form_name,
        id: res?.case_id,
        patient: res?.patient_name,
        // service_type: this.serviceTypeArray,
        billed: res?.total_amount,
        paid: res?.amount_paid,
        arrears: res?.arreas,
        date_finalized: res?.updated_at,
      });
      
    }
  //  console.log("Doctor CSV List", csvAllFileData);
    // create CSV file data in an array
    let dataKey = null;
    let dataVal = null;

    dataVal = csvAllFileData.map((obj) => {
      dataKey = Object.keys(obj);
      return Object.values(obj);
    });

    // create a user-defined function to download CSV file
    let csv = `${dataKey}\n`;

    // merge the data with CSV
    dataVal.forEach((row) => {
      csv += row.join(",");
      csv += "\n";
    });
    // display the created CSV data on the web browser
    document.write(csv);
    const hiddenElement = document.createElement("a");
    hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csv);
    hiddenElement.target = "_blank";

    // provide the name for the CSV file to be downloaded
    const date = new Date().toLocaleDateString("en-GB");
    hiddenElement.download = `ALl Doctors Report Details-[${date}].csv`;
    hiddenElement.style.visibility = "hidden";
    hiddenElement.style.display = "none";
    document.body.appendChild(hiddenElement);
    hiddenElement.click();
    document.body.removeChild(hiddenElement);
    location.reload();
  }

  csvMe() {
    const csvFileData = [];

    /* Looping through the resData array and pushing the values of the properties of each object in the array into
      a new array called data. */
    let newdata = this.doctorFormsDetails
   // console.log("New data", newdata);
    for (const res of newdata) {
      const serviceType = res?.service_type;
      if(serviceType && serviceType.length > 1){
        this.serviceTypeArray = serviceType.join(', ');
      //  console.log("service type" , this.serviceTypeArray)
      } else {
        this.serviceTypeArray = res?.service_type
      }
    //  console.log("res" , res)
      csvFileData.push({
        doctor: res?.doctor,
        specimen: res?.form_name,
        id: res?.case_id,
        patient: res?.patient_name,
        // service_type: this.serviceTypeArray,
        billed: res?.total_amount,
        paid: res?.amount_paid,
        arrears: res?.arreas,
        date_update: res?.updated_at,
      });
      
    }
   // console.log("Doctor CSV List", csvFileData);
    // create CSV file data in an array
    let dataKey = null;
    let dataVal = null;

    dataVal = csvFileData.map((obj) => {
      dataKey = Object.keys(obj);
      return Object.values(obj);
    });

    // create a user-defined function to download CSV file
    let csv = `${dataKey}\n`;

    // merge the data with CSV
    dataVal.forEach((row) => {
      csv += row.join(",");
      csv += "\n";
    });
    // display the created CSV data on the web browser
    document.write(csv);
    const hiddenElement = document.createElement("a");
    hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csv);
    hiddenElement.target = "_blank";

    // provide the name for the CSV file to be downloaded
    const date = new Date().toLocaleDateString("en-GB");
    hiddenElement.download = `Doctor Report Details-[${date}].csv`;
    hiddenElement.style.visibility = "hidden";
    hiddenElement.style.display = "none";
    document.body.appendChild(hiddenElement);
    hiddenElement.click();
    document.body.removeChild(hiddenElement);
    location.reload();
  }

  /**
   * We're creating a new div element, appending it to the body, copying the contents of the element we want to print into
   * the new div, printing the new div, and then removing the new div from the body
   */
  printElement() {
    const elem = document.getElementById("contentToPrint");
    const domClone = elem.cloneNode(true);

    const $printSection = document.createElement("div");
    $printSection.style.padding = "15px 5px";
    $printSection.id = "printSection";
    document.body.appendChild($printSection);

    $printSection.innerHTML = "";
    $printSection.appendChild(domClone);
    window.print();
    $printSection.style.padding = "0px";
    $printSection.innerHTML = "";
  }
}
