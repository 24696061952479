import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'src/app/others/services/authentication.service';

@Component({
  selector: 'app-allergies',
  templateUrl: './allergies.component.html',
  styleUrls: ['./allergies.component.scss']
})
export class AllergiesComponent implements OnInit {
  patient: any;
  constructor(private activeRoute: ActivatedRoute, private auth: AuthenticationService) { }

  ngOnInit(): void {
    this.activeRoute.params.subscribe((a) => {
      this.getPatientInfor(a.id)
    })
  }

  getPatientInfor(id: number) {
    this.auth.get("patient_info/" + id).subscribe((a) => {
      
      this.patient = a['data']['patient']['allergies'];
      console.log(this.patient);
      // console.log(a['data']['patient']['allergies'])

    })
  }
}
