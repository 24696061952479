import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';


@Injectable()
export class CurrencyService {
constructor ( private http : HttpClient)
{



}

  getCurrencyRegion(){
    const APIKEY = '447bf4fc9a784cca82696b4ea23c8c59';
  
    return this.http.get(`https://ipgeolocation.abstractapi.com/v1/?api_key=${APIKEY}&fields=currency&ip_address = 92.184.105.98`).pipe(
     tap((a) => console.log(a)),
    map((a) => a)
      )
  
  }
  
  
  getCurrencyRatings(to:string, amount:number){
    const APIKEY = 'aeb58df92e-243f93ee91-qzse0c'
   return this.http.get(`https://api.fastforex.io/convert?from=USD&to=${to}&amount=${amount}&api_key=${APIKEY}`)
    .pipe(map((a) => a))
  }
  
  
  }  