
<div class="p_info">

    <div>
  </div>
    <table>
      <tr>
        <td colspan="4">PATIENT NAME:&nbsp;<span class="info-wrp1">{{ patient_first_name }}
            {{ patient_last_name}}</span>&nbsp;</td>
      </tr>
      <tr>
        <td>GENDER: &nbsp;<span class="info-wrp1">{{ patient_gender }}</span>&nbsp;</td>
        <td>AGE: &nbsp;<span class="info-wrp1">{{ patient_date_of_birth | ageTransform }}</span>&nbsp;
        </td>
        <td>FOLDER #: &nbsp;<span class="info-wrp1">{{ patient_folder_num }}</span>&nbsp;</td>
        <!-- <td>PATHOLOGY #: &nbsp;<span class="info-wrp">{{ specimen?.pathology_number }}</span>&nbsp;</td> -->
      </tr>
      <tr>
        <!-- <td>DATE RECEIVED: &nbsp;<span class="info-wrp">{{ specimen?.date_specimen_received }}</span>&nbsp;</td> -->
        <!-- <td>DATE REPORTED: &nbsp;<span class="info-wrp">{{ specimen?.date_reported }}</span>&nbsp;</td> -->
        <td>DATE EDITED: &nbsp;<span class="info-wrp1">{{ date_edited | dateTransform}}</span>&nbsp;</td>
        <td>SCAN DATE: &nbsp;<span class="info-wrp1">{{ scan_date | dateTransform }}</span>&nbsp;</td>
        <td>SUBMISSION DUE DATE: &nbsp;<span class="info-wrp1">{{ due_date | dateTransform }}</span>&nbsp;</td>
      </tr>
    </table>
  </div>

