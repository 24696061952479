<app-ui-modal #formModal [hideFooter]="false" [containerClick]="false" [dialogClass]="'modal-xl'">
  <div class="app-modal-header">
    <h4 style="color:#0873ee;text-transform:uppercase" class="modal-title">{{title}}</h4>
    <h5 class="modal-title">{{case_number}}</h5>
  </div>
  <div class="app-modal-header">
    <button *ngIf="action=='create'" type="button" class="btn btn-success" [disabled]="!isSubmitButtonEnabled() && !form.get('pathologists').value" (click)="submit();form.reset();formModal.hide();Reset();">Submit</button>&nbsp;
    <button *ngIf="action!='create' && !readyForSubmit" type="button" class="btn btn-success" (click)="submit()"
    style="margin-right: 15px;">Save</button>
    
    <button *ngIf="action!='create' && userRole !== 'Owner'&&  userRole !== 'Doctor' &&  userRole !== 'Lab Technologist' &&  userRole !== 'Sonographer' &&  userRole !== 'Radiographer'" type="button" class="btn btn-success" (click)="submit()"
      style="margin-right: 15px;">Save</button>

    <span *ngIf="userRole !== 'Administrator' && userRole !== 'Owner' && userRole !== 'Nurse' && userRole !== 'Staff'">
    <button *ngIf="action!='create'" (click)="submit()" type="button" class="btn btn-secondary" (click)="prev(); form.reset();" 
    style="margin-right: 15px;"> Preview & Finalize</button>
    </span>
    <button type="button" class="btn btn-danger" (click)="Reset();form.reset();formModal.hide();">Close</button>
  </div>
  <div class="app-modal-body">
    <form class="go-right" [formGroup]="form">
      <!-- Color Open Accordion ends -->
      <ngb-accordion [closeOthers]="true" activeIds="create-1" class="able-pro-accordion">
        <ngb-panel id="create-1">
          <ng-template ngbPanelTitle>
            <h5><a href="javascript:">RADIOLOGY REPORT</a></h5>
          </ng-template>
          <ng-template ngbPanelContent>
            <div formGroupName="patient">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="form-group">
                        <input type="text" class="form-control" formControlName="patient_number" id="Create Patient Number" placeholder="Patient Number">
                        <label class="label" for="Create Patient Number">Patient Number</label>
                      </div>
                    </div>

                    <div class="col-sm-6">
                      <div class="form-group">
                        <input type="text" class="form-control" formControlName="national_health_insurance_id" id="Create Id" placeholder="National Health Insurance ID">
                        <label class="label" for="Create Id">National Health Insurance ID</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header bg-secondary text-white" style="font-size: 20px;padding: 7px;">
                  Patient's Information
                </div>
                <div class="card-block">
                  <div class="row">
                    <div class="col-sm-6" style="margin-bottom: 20px;">
                      <div class="form-group">
                        <input type="text" class="form-control" formControlName="first_name" id="Create First Name" placeholder="First Name">
                        <label class="label" for="Create First Name">First Name</label>
                      </div>
                    </div>
                    <div class="col-sm-6" style="margin-bottom: 20px;">
                      <div class="form-group">
                        <input type="text" class="form-control" formControlName="last_name" id="Create Last Name" placeholder="Last Name">
                        <label class="label" for="Create Last Name">Last Name</label>
                      </div>
                    </div>
                    <div *ngIf="!specimen?.patient?.age_option" class="col-sm-6" style="margin-bottom: 20px;">
                      <div class="form-group">
                        <input type="date" class="form-control" formControlName="date_of_birth" id="date_of_birth">
                        <label class="label" for="date_of_birth">Birth Date</label>
                      </div>
                    </div>
                    <div *ngIf="specimen?.patient?.age_option" class="col-sm-6 form-group" style="margin-bottom: 20px;">
                      <input type="number" class="form-control" formControlName="age" id="age" placeholder="Age" required>
                      <label class="label" for="age">Age</label>
                    </div>
                    <div class="col-sm-6" style="margin-bottom: 20px;">
                      <div class="form-group">
                        <select class="form-control" formControlName="gender" id="Create Sex">
                          <option>Gender</option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                        </select>
                        <label class="label" for="Create Sex">Gender</label>
                      </div>
                    </div>
                    <div class="col-sm-8" style="margin-bottom: 20px;">
                      <div class="form-group">
                        <input type="email" class="form-control" formControlName="email" id="Create Email" placeholder="Email">
                        <label class="label" for="Create Email">Email</label>
                      </div>
                    </div>
                    <div class="col-sm-4" style="margin-bottom: 20px;">
                      <div class="form-group">
                        <input type="tel" class="form-control" formControlName="phone" id="Create Phone" placeholder="Phone">
                        <label class="label" for="Create Phone">Phone</label>
                      </div>
                    </div>
                    <div class="col-sm-12">
                      <div class="form-group">
                        <textarea class="form-control" formControlName="address" id="Create Address" placeholder="Address" rows="2"></textarea>
                        <label class="label" for="Create Address">Location</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-block">
                <div class="form-group row">
                  <div class="col-sm-6">
                    <input type="text" class="form-control" formControlName="folder_number" id="Create Folder Number" placeholder="Folder Number">
                    <label class="label" for="Create Folder Number">Folder Number</label>
                  </div>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-block">
                <div class="form-group row">
                  <div class="col-sm-6" style="margin-bottom: 20px;">
                    <input type="date" class="form-control" formControlName="scan_date" id="scan_date">
                    <label class="label" for="scan_date">Scan Date</label>
                  </div>
                  <div class="col-sm-6" style="margin-bottom: 20px;">
                    <input type="date" class="form-control" formControlName="due_date" id="due_date">
                    <label class="label" for="date_reported">Report Date</label>
                  </div>
                </div>
                <div class="form-group row">
                  <!-- <div class="col-sm-6">
                    <input type="text" class="form-control" formControlName="clinical_history" id="clinical_history">
                    <label class="label" for="clinical_history">Relevant Clinical History</label>
                  </div> -->
                  <div class="col-sm-12" >
                    <input type="text" class="form-control" formControlName="indication" id="indication">
                    <label class="label" for="indication">Indication</label>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-sm-6">
                    <br>
                      <div class="form-group">
                      <ng-select class="form-control" formControlName="requesting_facility" (change) = "onFacilitySelect($event)" [items]="requesting_facilities" bindLabel="facility_full_name" bindValue="facility_full_name" placeholder="Select Requesting Facility" required></ng-select>
                      <label class="label" for="Requesting facility" >Requesting facility</label>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <br>
                    <div class="spin-main form-group">
                      <ng-select class="form-control" formControlName="requesting_doctor" [items]="requesting_doctors" bindLabel="doctor_full_name" bindValue="doctor_full_name" placeholder="Select Requesting Doctor"></ng-select>
                      <div *ngIf="fetching" class="spin-icon"><span class="spinner-border spinner-border-sm" role="status"></span>Loading Doctors</div>
                      <label class="label" for="Requesting doctors">Requesting Doctor</label>
                    </div>
                  </div>
                </div>

              </div>
            </div>

          </ng-template>
        </ngb-panel>
        <ngb-panel id="create-2">
          <ng-template ngbPanelTitle>
            <h5 style="width: 287px;" style="margin-left: -5px;"><a href="javascript:">ECHOCARDIOGRAPHY REPORT </a></h5>
          </ng-template>
          <ng-template ngbPanelContent>
            <!-- Third Card -->
            <div class="card">
              <div class="card-block">
                  <div class="form-group row mb-3">
                      <div class="col-md-12">
                          <ckeditor formControlName="radio_text" style="width:100%;" [editor]="Editor"    class="custom-ckeditor"></ckeditor>
                      </div>
                  </div>
              </div>
            </div>
          </ng-template>
        </ngb-panel>
        <ngb-panel id="create-3">
          <ng-template ngbPanelTitle>
            <h5><a href="javascript:">IMPRESSION</a></h5>
          </ng-template>
          <ng-template ngbPanelContent>
              <!-- Third Card -->
              <div class="card">
                  <div class="card-block">
                      <div class="form-group row">
                          <div class="col-sm-12">
                             <quill-editor class="col-sm-12 max-textarea" [modules]="quillConfiguration" [styles]="commentStyle" placeholder="Write comment here"rows="4"
                            formControlName="impression_other">
                          </quill-editor>
                          </div>
                        </div>
                          <div class="row">
                            <div class="col-sm-12">
                              <select name="" class="form-control"  formControlName="recommendation" id="recommend" (change)="recommendatioChange($event.target.value)">
                                <option value="MRI is recommended for further evaluation">MRI is recommended for further evaluation</option>
                                <option value="CT scan of the chest recommended">CT scan of the chest recommended</option>
                                <option value="CT scan of the abdomen and pelvis recommended">CT scan of the abdomen and pelvis recommended</option>
                                <option value="FNA or core biopsy recommended">FNA or core biopsy recommended</option>
                                <option value="CT scan of the abdomen and pelvis recommended for staging">CT scan of the abdomen and pelvis recommended for staging</option>
                                <option value="CT Urography recommended">CT Urography recommended</option>
                                <option value="Culdocentesis recommended">Culdocentesis recommended</option>
                                <option value="Cystoscopy recommended">Cystoscopy recommended</option>
                                <option value="No further recommendation at this point">No further recommendation at this point</option>
                                <option value="Cystoscopy and CT scan of the abdomen and pelvis recommended for further evaluation">Cystoscopy and CT scan of the abdomen and pelvis recommended for further evaluation</option>
                                <option value="Anticoagulation recommended">Anticoagulation recommended</option>
                                <option value="Other Specify">Other Specify</option>
                              </select>
                              <label for="recommend" class="label">Recommendation</label>
                            </div>
                            <br>
                            <div class="col-sm-12 mt-4" *ngIf="recommendation_chk">
                              <input type="text" name="" class="form-control" formControlName="recommended_other" id="" placeholder="Specify Other Recommendation">
                            </div>
                          </div>


                      <div class="form-group row" style="padding: 10px; margin-top: 3rem;">
                          <div class="col-md-12">
                            <p>Comment(s)</p>
                            <quill-editor class="col-sm-12 max-textarea" [modules]="quillConfiguration" [styles]="commentStyle" placeholder="Write comment here"rows="4"
                            formControlName="comments">
                          </quill-editor>
                          </div>
                      </div>
                        <br>
                        <div class="form-group row">
                          <div class="col-sm-4"   *ngIf="userRole !== 'Owner' && userRole !== 'Doctor' && userRole !== 'Radiologist' && userRole !== 'Sonographer'"><br>
                            <div class="custom-control custom-radio custom-control-inline">
                              <input type="radio" class="custom-control-input" value="Checked" formControlName="form_ready" id="form_ready_radio">
                              <label class="custom-control-label" for="form_ready_radio">Mark as Draft Complete.</label>
                            </div>
                          </div>
                        </div>
                        <!--  -->
                        <div class="row">
                          <!-- Image upload has no form control name -->
                              <div class="form-group " style="margin-top: 1rem;">
                                  <div class="col-sm-12">
                                    <input type="file" name="" class="form-control d-none" formControlName="" id="uplodad_image" (change)="fileSelection($event)" title="Upload image" >
                                    <label for="uplodad_image" class="btn btn-primary py-3" style="background-color:#607d8b ;">Upload Image</label>
                                  </div>
                                  <div class="d-flex">
                                    <div class=" col-sm-12 preview" *ngFor="let item of this.specimen.scan_image let i = index" class="thumbnail_box"
                                  style="margin-left: 1rem;">
                                    <!-- {{item.preview}} -->
                                    <div class="remove_file" (click)="removeFile(i)"><i class="fa fa-window-close"></i></div>
                                      <img *ngIf="item.content == 'null' || item.content == undefined" [src]="item.preview?.backgroundImage" alt="" id="preview_box" width="90" height="90" class="d-block mx-auto my-auto">
                                      <div *ngIf="item.type != 'null'" width="90" height="90"><i [class]="item.content" style="font-size:4rem;"></i></div>
                                  </div>
                                  </div>

                                </div>

                      </div>
                      <br>
                      
                  </div>
              </div>
          </ng-template>
      </ngb-panel>

      </ngb-accordion>
      <!-- Color Open Accordion ends -->
    </form>
  </div>
  <div class="app-modal-footer">
    <button *ngIf="action=='create'" type="button" class="btn btn-success" [disabled]="!isSubmitButtonEnabled() && !form.get('pathologists').value" (click)="submit();form.reset();formModal.hide();Reset();">Submit</button>&nbsp;
    <button *ngIf="action!='create' && !readyForSubmit" type="button" class="btn btn-success" (click)="submit()"
    style="margin-right: 15px;">Save</button>
    
    <button *ngIf="action!='create' && userRole !== 'Owner'&&  userRole !== 'Doctor' &&  userRole !== 'Lab Technologist' &&  userRole !== 'Sonographer' &&  userRole !== 'Radiographer'" type="button" class="btn btn-success" (click)="submit()"
      style="margin-right: 15px;">Save</button>

    <span *ngIf="userRole !== 'Administrator' && userRole !== 'Owner' && userRole !== 'Nurse' && userRole !== 'Staff'">
    <button *ngIf="action!='create'" (click)="submit()" type="button" class="btn btn-secondary" (click)="prev(); form.reset();" 
    style="margin-right: 15px;"> Preview & Finalize</button>
    </span>
    <button type="button" class="btn btn-danger" (click)="Reset();form.reset();formModal.hide();">Close</button>
  </div>
</app-ui-modal>
