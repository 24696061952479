<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="medium" color="#fff" type="ball-clip-rotate-pulse"></ngx-spinner>
<div class="container">
  <div class="card">
    <div class="card-body">
      <div class="card-title">Support Request</div>
      <p class="sub-header">
        Please let us know if you notice a bug, want
        any change or addition in the Application. Fill
        the form below and add a screenshot of the problem
        you are facing or a PDF/Word version of the new
        Form you want. Our Team will respond within 2 working days.
      </p>
      <form [formGroup]="requestForm" (ngSubmit)="submitRequest()" id="requestForm">
        <div class="form-row">
          <div class="form-group col-lg-4">
            <input type="text" class="form-control" id="requestTitle" formControlName="requestTitle" placeholder="Title *" maxlength="50" [ngClass]="requestForm.get('requestTitle').touched && requestForm.get('requestTitle').hasError('required') ? 'invalid' : ''">
            <div class="input-info invalid" *ngIf="requestForm.get('requestTitle').touched && requestForm.get('requestTitle').hasError('required')">
              Provide title for your support request</div>
          </div>

          <div class="form-group col-lg-4">
            <select class="custom-select" id="requestFormType" formControlName="requestFormType" [ngClass]="requestForm.get('requestFormType').touched && requestForm.get('requestFormType').value == '' ? 'invalid' : ''">
              <option value="" selected disabled>Form Type *</option>
              <optgroup label="Pathology">
                <option value="Breast Core and Incision Biopsies">Breast Core and Incision Biopsies</option>
                <option value="Cervical">Cervical</option>
                <option value="Colon">Colon</option>
                <option value="Endometrial">Endometrial</option>
                <option value="Gastrectomy">Gastrectomy</option>
                <option value="General Part Biopsies">General Part Biopsies</option>
                <option value="General Template">General Template</option>
                <option value="Laryngectomy">Laryngectomy</option>
                <option value="Mastectomy">Mastectomy</option>
                <option value="Nephrectomy">Nephrectomy</option>
                <option value="Non Gynae Cytology">Non Gynae Cytology</option>
                <option value="Ovarian">Ovarian</option>
                <option value="Pap Request">Pap Request</option>
                <option value="Prostate Cancer Biopsy">Prostate Cancer Biopsy</option>
                <option value="Prostate Cancer Radical Prostatectomy">Prostate Cancer Radical Prostatectomy</option>
                <option value="Prostate Turp & Enucleation">Prostate Turp & Enucleation</option>
                <option value="wilm`s Tumor">wilm`s Tumor</option>
              </optgroup>
              <optgroup label="General Practise">
                <option value="Consultation Admission">Consultation Admission</option>
                <option value="Clinical Laboratory">Clinical Laboratory</option>
                <option value="Physical Examination">Physical Examination</option>
                <option value="Medical Certificate">Medical Certificate</option>
              </optgroup>
              <optgroup label="Others">
                <option value="Billing">Billing</option>
                <option value="Subscription">Subscription</option>
              </optgroup>

            </select>
            <div class="input-info invalid" *ngIf="requestForm.get('requestFormType').touched && requestForm.get('requestFormType').hasError('required') ? 'invalid' : ''">
              Select the form you're requesting support for</div>
          </div>

          <div class="form-group col-lg-4">
            <select class="custom-select" id="requestType" formControlName="requestType" [ngClass]="requestForm.get('requestType').touched && requestForm.get('requestType').value == '' ? 'invalid' : ''">
              <option value="" selected disabled>Type of Request *</option>
              <option value="Report Bug in Software">Report Bug in Software</option>
              <option value="Change Request">Change Request</option>
              <option value="New Feature Request">New Feature Request</option>
              <option value="Request creation of New Form">Request creation of New Form</option>
              <option value="Other (Specify)">Other (Specify)</option>
            </select>
            <div class="input-info invalid" *ngIf="requestForm.get('requestType').touched && requestForm.get('requestType').hasError('required') ? 'invalid' : ''">
              Select a type of request</div>
          </div>

          <div class="form-group col-lg-12" *ngIf="requestForm.get('requestType').value == 'Other (Specify)'">
            <input type="text" class="form-control" formControlName="requestOtherType" placeholder="Other (Specify)" maxlength="50" [ngClass]="requestForm.get('requestType').value == 'Other (Specify)' && requestForm.get('requestOtherType').touched && requestForm.get('requestOtherType').hasError('required') ? 'invalid' : ''" [required]="requestForm.get('requestType').value == 'Other (Specify)'">
            <div class="input-info invalid" *ngIf="requestForm.get('requestType').value == 'Other (Specify)' && requestForm.get('requestOtherType').touched && requestForm.get('requestOtherType').value == '' ? 'invalid' : ''">
              Provide type of request</div>
          </div>

          <div class="form-group col-lg-12">
            <textarea class="form-control" [ngClass]="requestForm.get('requestDetails').touched && requestForm.get('requestDetails').hasError('required') ? 'invalid' : '' " id="requestDetails" formControlName="requestDetails" placeholder="Details/Description *" rows="4" maxlength="500"></textarea>
            <div class="input-info invalid" *ngIf="requestForm.get('requestDetails').touched && requestForm.get('requestDetails').hasError('required') ? 'invalid' : '' ">Provide details of your request</div>
          </div>

          <div class="form-group col-lg-12">
            <label>Please attach a screenshot, word or pdf document to help us better understand the issue.</label>
            <input type="text" class="form-control" placeholder="Click to add file" readonly (click)="getFiles()" value="{{ this.fileNames }}">
            <input type="file" class="form-control-file d-none" #requestAttachmentFiles id="requestAttachmentFiles" accept=".png, .jpg, .jpeg, .pdf" formControlName="requestFileAttachment" (change)="setFileNames(); fileChangeEvent($event)">
          </div>

          <div class="form-group col-lg-12">
            <input type="text" class="form-control" placeholder="Click to add file" readonly (click)="getFiles2()" value="{{ this.fileNames2 }}">
            <input type="file" class="form-control-file d-none" id="requestAttachmentFiles2" formControlName="requestFileAttachment2" accept=".png, .jpg, .jpeg, .pdf" (change)="setFileNames2(); fileChangeEvent2($event)">
          </div>

          <div class="form-group col-lg-12">
            <input type="text" class="form-control" placeholder="Click to add file" readonly (click)="getFiles3()" value="{{ this.fileNames3 }}">
            <input type="file" class="form-control-file d-none" id="requestAttachmentFiles3" formControlName="requestFileAttachment3" accept=".png, .jpg, .jpeg, .pdf" (change)="setFileNames3(); fileChangeEvent3($event)">
          </div>

          <div class="forom-group col-lg-12">
            <button type="submit" class="btn btn-success close-button submit-request" id="submitRequest" [disabled]="disableSubmitButton || requestForm.invalid">Submit Request</button>
            <button type="reset" class="btn btn-danger close-button submit-request cancel" id="resetForm">Cancel</button>
          </div>
        </div>
      </form>

    </div>
  </div>
</div>
