import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nameReplace'
})
export class NameReplacePipe implements PipeTransform {
  newName: string;

  transform(value: any, ...args: unknown[])  {
    // return value
    if(value == "Breast Core Biopsy Form") {
      this.newName = "Breast Core Incision Biopsy"
    }
    else if(value == "Breast Core Biopsy") {
      this.newName = "Breast Core Incision Biopsy"
    }
    else if(value == "Cervical Cancer Form") {
      this.newName = "Cervical Cancer"
    }
    else if(value == "Colon Specimen Form") {
      this.newName = "Colon Specimen"
    }
    else if(value == "Cervical Cancer Form") {
      this.newName = "Cervical Cancer"
    }
    else if(value == "Gastrectomies Form") {
      this.newName = "Gastrectomy"
    }
    else if(value == "General Biopsy Form") {
      this.newName = "General Part Biopsies"
    }
    else if(value == "General Cancer Biopsies Form") {
      this.newName = "General Cancer Biopsy"
    }
    else if(value == "General Specimen Form") {
      this.newName = "General Template"
    }
    else if(value == "Immunohistochemistry Special Stains Form") {
      this.newName = "Immunohistochemistry Special Stains"
    }
    else if(value == "Immunohistochemistry Form") {
      this.newName = "Immunohistochemistry"
    }
    else if(value == "Immunohistochemistry Special Stains Form") {
      this.newName = "Immunohistochemistry Special Stains"
    }
    else if(value == "Laryngectomy Form") {
      this.newName = "Laryngectomy"
    }
    else if(value == "Leiomyomata Form") {
      this.newName = "TAH for Leiomyomata"
    }
    else if(value == "Mastectomy Form") {
      this.newName = "Mastectomy"
    }
    else if(value == "Nephrectomy Form") {
      this.newName = "Nephrectomy Main"
    }
    else if(value == "Nongynaecytology Form") {
      this.newName = "Non Gynae Cytology"
    }
    else if(value == "Ovarian Cancer Form") {
      this.newName = "Ovarian Cancer"
    }
    else if(value == "Pap Request Form") {
      this.newName = "Pap Request"
    }
    else if(value == "Prostatectomy Form") {
      this.newName = "Prostate Cancer Prostatectomy"
    }
    else if(value == "Prostate Cancer Form") {
      this.newName = "Prostate Cancer Biopsy"
    }
    else if(value == "Prostate Turp Form") {
      this.newName = "Prostate Turp & Enucleation"
    }
    else if(value == "Renal Core Icision Biopsy Form") {
      this.newName = "Renal Core & Incision Biopsies"
    }
    else if(value == "Wilms Tumor Form") {
      this.newName = "Wilm's Tumor"
    }
    else if(value == "Both Knee Joints") {
      this.newName = "Both Knee Joint"
    }
    else if(value == "Dopper Ultra Sound fo the lt Upper Limb Veins") {
      this.newName = "Doppler Ultrasound of the Left Upper Limb Vein"
    }
    else if(value == "Doppler Ultra Sound of the both Lower Limb Arteries") {
      this.newName = "Doppler Ultrasound of the Both Lower Limb Arteries"
    }
    else if(value == "Doppler Ultra Sound of the both Lower Limb Veins") {
      this.newName = "Doppler Ultrasound of the Both Lower Limb Vein"
    }
    else if(value == "Doppler Ultrasound Of The Left Lower Limb Arteries") {
      this.newName = "Doppler Ultrasound of the Left Lower Limb Arteries"
    }
    else if(value == "Doppler Ultra Sound of the lt Lower Limb Veins") {
      this.newName = "Doppler Ultrasound of the Left Lower Limb Vein"
    }
    else if(value == "Doppler Ultra Sound of the lt Upper Limb Arteries") {
      this.newName = "Doppler Ultrasound of the Left Upper Limb Arteries"
    }
    else if(value == "Doppler Ultrasound Of The Right Lower Limb Arteries") {
      this.newName = "Doppler Ultrasound of the Right Lower Limb Arteries"
    }
    else if(value == "Doppler Ultra Sound of the Right Upper Limb Veins") {
      this.newName = "Doppler Ultrasound of the Right Upper Limb Vein"
    }
    else if(value == "Echocardiographic") {
      this.newName = "Echocardiographic Report "
    }
    else if(value == "General Radiology Specimens") {
      this.newName = "General Radiology Specimen"
    }
    else if(value == "Lumber Spine") {
      this.newName = "Lumbar Spine"
    }
    else if(value == "Neck And Doppler") {
      this.newName = "Ultrasound Neck and Doppler Report"
    }
    else if(value == "Plain Radiology Chest") {
      this.newName = "Plain Radiograph Chest Report"
    }
    else if(value == "Plain Radiology Pelvis") {
      this.newName = "Plain Radiograph Pelvis"
    }
    else if(value == "Right Shoulder Joint") {
      this.newName = "Right Shoulder Joint"
    }
    else if(value == "Ultra Sound Abdomen and Pelvis") {
      this.newName = "Ultrasound Abdomen and Pelvis"
    }
    else if(value == "Ultra Sound Abdomens") {
      this.newName = "Ultrasound Abdomen"
    }
    else if(value == "Ultra Sound Chests") {
      this.newName = "Ultrasound Chest Report"
    }
    else if(value == "Ultra Sound Necks") {
      this.newName = "Ultrasound Neck Report"
    }
    else if(value == "Ultra Sound Obstetrics") {
      this.newName = "Ultrasound Obstetrics"
    }
    else if(value == "Ultra Sound Obstetrics Report Anomaly") {
      this.newName = "Ultrasound Obstetrics Anomaly"
    }
    else if(value == "Ultra Sound Obstetrics Report Lates") {
      this.newName = "Ultrasound Obstetrics Late"
    }
    else if(value == "Ultra Sound Obstetrics Report Twin") {
      this.newName = "Ultrasound Obstetrics Twin"
    }
    else if(value == "Ultar Sound Obstetrics Report Twin") {
      this.newName = "Ultrasound Obstetrics Twin"
    }
    else if(value == "Ultra Sound Pelvis Females") {
      this.newName = "Ultrasound Pelvis (Female)"
    }
    else if(value == "Ultra Sound Pelvis Males") {
      this.newName = "Ultrasound Pelvis (Male)"
    }
    else if(value == "Ultra Sound Pelvis transabdominal Transvaginals") {
      this.newName = "Ultrasound Transabdominal and Transvaginal"
    }
    else if(value == "Ultra Sound Pelvis Transabdominals") {
      this.newName = "Ultrasound Pelvis Transabdominal"
    }
    else if(value == "Ultrasound Anterior Chest Wall") {
      this.newName = "Ultrasound Anterior Chest Wall Report"
    }
    else if(value == "Ultrasound Neonatal Head") {
      this.newName = "Ultrasound Neonatal Head Report"
    }
    else if(value == "Ultrasound Peniles") {
      this.newName = "Ultrasound Penile Report"
    }
    else if(value == "Ultrasound Posterior Chest Walls") {
      this.newName = "Ultrasound Postinterior Chest Wall Report"
    }
    else if(value == "Ultrasound Right Inguinla Mass") {
      this.newName = "Ultrasound Right Inguinla Mass"
    }
    else if(value == "Ultrasound Scan Left Mass") {
      this.newName = "Ultrasound Left Thigh Mass Report"
    }
    else if(value == "Ultrasound Scan Scrotum") {
      this.newName = "Ultrasound Scrotum"
    }
    else if(value == "Ultrasound Supraclaviculars") {
      this.newName = "Ultrasound Supraclavicular Report"
    }
    else if(value == "Electrocardiogram Form") {
      this.newName = "Electrocardiogram"
    }
    else if(value == "Lower Gi Endoscopic Form") {
      this.newName = "Lower Gi Endoscopy"
    }
    else if(value == "Upper Gi Endoscopic Form") {
      this.newName = "Upper Gi Endoscopy"
    }
    else {
      this.newName = value
    }
    return this.newName
    // console.log("New Name", this.newName)
  }

}


