import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { VitalsComponent } from './vitals.component';

const routes: Routes = [
  {
    path:"",
    component:VitalsComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class VitalsRoutingModule { }
