import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class KeyMetricsService {

  private keyActions = new BehaviorSubject<number>(0)
  keyMetrics = this.keyActions.asObservable()

  constructor(
    private auth: AuthenticationService
  ) { }
  
  onSearchForPatient(word){
   return this.auth.get(`staff_collection_details/patients/${word}`).pipe((a) => a)
  }
}
