import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { AuthenticationService } from 'src/app/others/services/authentication.service';
import { FormStateService } from 'src/app/others/services/form-state.service';

@Component({
  selector: 'app-finalize-radiology',
  templateUrl: './finalize-radiology.component.html',
  styleUrls: ['./finalize-radiology.component.scss']
})
export class FinalizeRadiologyComponent {
  pathSignature: FormGroup;
  doctors = [];
  doctor: any;
  doctor2: any;
  doctor3: any;
  authSignature0:any;
  authSignature:any;
  authSignature_:any;
  authSignature2:any;
  authSignature3:any;
  submission_ready: boolean;
  other_doctor = '';
  other_doctor2 = '';
  other_doctor3 = '';
  other_doctor4 = '';
  digital_signature0: any;
  digital_signature: any;
  digital_signature2: any;
  digital_signature3: any;
  digital_signature4: any;
  with_signature: boolean =  true;
  finalize_form : boolean;


  userRole = sessionStorage.getItem("role");
  @Input() peerReview: any;  // Input property to receive peer_review
  @Input() finalPath: boolean = false;
  @Input() isChecked: boolean = false;
  @Output() isCheckedChange = new EventEmitter<boolean>();
  @Output() finalizePayload = new EventEmitter<any>();
  @Output() finalizePay = new EventEmitter<any>();
  @Output() finalizeBoolean = new EventEmitter<any>();
  @Output() finalizeFormChange = new EventEmitter<boolean>();
  curr_user: any;
  selectedDoctor: any;
  selectedDoctor2: any;
  selectedDoctor3: any;
  otherSelectedDoctor: any;
  otherSelectedDoctor2: any;
  otherSelectedDoctor3: any;
  otherSelectedDoctor4: any;
  speciality2: any;
  pathAuthSignature: string;
  specialityy: any;
  loggedInUserId: any;
  peerReviewMatch: boolean = false;
  userId: any;
  userId2: any;
  userId3: any;
  userId4: any;
  pathologists_designation: any;
  pathologists_designation2: any;
  pathologists_designation3: any;
  pathologists_designation4: any;
  pathAuthSignature64: any;
  coSignerName: any;
  coSignerSignature: any;

  constructor(private auth: AuthenticationService, private fb: FormBuilder, private formStateService: FormStateService) {
    this.pathSignature = this.fb.group({
      submission_ready: new FormControl(false),
      pathologists: new FormControl(''),
      pathologists2: new FormControl(''),
      pathologists3: new FormControl(''),
      pathologists4: new FormControl(''),
      other_pathologists: new FormControl(''),
      other_pathologists2: new FormControl(''),
      other_pathologists3: new FormControl(''),
      other_pathologists4: new FormControl(''),
      other_pathologists5: new FormControl(''),
      designation: new FormControl('Releasing Radiologist'),
      designation2: new FormControl(''),
      designation3: new FormControl(''),
      designation4: new FormControl(''),
      finalReview: new FormControl(''),
      finalReview2: new FormControl(''),
      finalReview3: new FormControl(''),
      finalReview4: new FormControl(''),

    });
    this.pathSignature.get('designation').valueChanges.subscribe((value) => {
      let pathologistsValue = this.pathSignature.get('pathologists').value;
    let otherPathologistsValue = this.doctor;
    let otherPathologistsValue2 = this.doctor2;
    let otherPathologistsValue3 = this.doctor3
      if (value === 'Reporting Radiologist') {
        // Disable 'No' radio button and select 'Yes' radio button
        this.pathSignature.get('finalReview').setValue('yes');
        this.createPayLoad()
        this.pathSignature.get('finalReview').disable();
      }else {
        // Enable 'No' radio button
        this.pathSignature.get('finalReview').enable();
      }
    });
    this.pathSignature.get('designation2').valueChanges.subscribe((value) => {
      if (value === 'Reporting Radiologist') {
        // Disable 'No' radio button and select 'Yes' radio button
        this.pathSignature.get('finalReview2').setValue('yes');
        this.pathSignature.get('finalReview2').disable();
      } else {
        // Enable 'No' radio button
        this.pathSignature.get('finalReview2').enable();
      }
    });
    this.pathSignature.get('designation3').valueChanges.subscribe((value) => {
      if (value === 'Reporting Radiologist') {
        // Disable 'No' radio button and select 'Yes' radio button
        this.pathSignature.get('finalReview3').setValue('yes');
        this.pathSignature.get('finalReview3').disable();
      } else {
        // Enable 'No' radio button
        this.pathSignature.get('finalReview3').enable();
      }
    });
    this.pathSignature.get('designation4').valueChanges.subscribe((value) => {
      if (value === 'Reporting Radiologist') {
        // Disable 'No' radio button and select 'Yes' radio button
        this.pathSignature.get('finalReview4').setValue('yes');
        this.pathSignature.get('finalReview4').disable();
      } else {
        // Enable 'No' radio button
        this.pathSignature.get('finalReview4').enable();
      }
    });
  }

  all_radiologists = [];
  all_radiologists_filtered = [];
  all_radiologists2 = [];
  radiologyDoctor = [];
  ngOnInit(): void {
    this.speciality2 = JSON.parse(sessionStorage.getItem("user"));
    // console.log("base64", this.speciality2)
    const fullname = sessionStorage.getItem("fullname");
    const speciality2 = this.speciality2 && this.speciality2.speciality ? `(${this.speciality2.speciality})` : "";;
    this.loggedInUserId = sessionStorage.getItem("userID");

      this.auth.get('doctor_info').subscribe((response) => {
        let rad_array = response['doctor'];
        this.doctors = response['doctor']
        this.all_radiologists = rad_array.map((el) => {
          return `${el.fullname}`;
        });
      })
    this.getUserNamee();
    this.pathSignature.valueChanges.subscribe(() => {
      this.checkFormValidity();
    });
  }

  filteredDoctors(selectedDoc) {
    this.auth.get('doctor_info').subscribe((response) => {
      let rad_array = response['doctor'];
      // Filter out the doctor with the given fullname
      this.all_radiologists_filtered = rad_array
        .filter((el) => el.fullname !== selectedDoc)  // Exclude doctor with matching fullname
        .map((el) => `${el.fullname}`);  // Map the remaining doctors to their full names
  
      // console.log("Filtered radiologists array", this.all_radiologists_filtered);
    });
  
    // console.log("Selected Doctor to exclude:", selectedDoc);
  }
  

  onPeerReviewCheck(){
    this.speciality2 = JSON.parse(sessionStorage.getItem("user"));
    // console.log("base64", this.speciality2)
    const fullname = sessionStorage.getItem("fullname");
    const speciality2 = this.speciality2 && this.speciality2.speciality ? `(${this.speciality2.speciality})` : "";
    this.pathAuthSignature64 = this.speciality2?.digital_signature_64;
    this.loggedInUserId = sessionStorage.getItem("userID");

  if (this.peerReview == this.loggedInUserId){
    this.pathSignature.get('designation').setValue('Releasing Radiologist');
    this.pathSignature.get("pathologists").setValue(fullname + " " + speciality2);
    this.pathAuthSignature = sessionStorage.getItem("digital_signature");
    this.createPayLoad();
    this.peerReviewMatch = true;
  } else {
    this.auth.get('doctor_info').subscribe((response) => {
      let rad_array = response['doctor'];
      this.doctors = response['doctor']
      this.all_radiologists = rad_array.map((el) => {
        return `${el.fullname}`;
      });
    })
    this.peerReviewMatch = false;
  }
    this.getUserNamee();
    this.pathSignature.valueChanges.subscribe(() => {
      this.checkFormValidity();
    });
    // console.log("on peer pathSignature" , this.pathSignature)
    // console.log("on peer pathAuthSignature" , this.pathAuthSignature)
    // console.log("on peer peerReviewMatch" , this.peerReviewMatch)
  }

  getUserName0(e) {
    const email = JSON.parse(sessionStorage.getItem("user"));
    this.curr_user = email?.id;
    // console.log("fullname", email)
    this.selectedDoctor = e
    this.pathSignature.get("pathologists").setValue(this.selectedDoctor);
    const doc =  this.doctors.filter((a) => a.fullname == e)
    this.pathAuthSignature = doc[0]?.digital_signature
    // console.log("PATH AUTH SIGNATURE", this.pathAuthSignature)
    this.authSignature0 = doc[0]?.digital_signature
    this.digital_signature0 = doc[0]?.digital_signature_64
    this.pathAuthSignature64 = doc[0]?.digital_signature_64
    this.createPayLoad();
    this.createPay();
    this.filteredDoctors(this.selectedDoctor);
    console.log("SELECTED DOCTOR", this.selectedDoctor)
  }
  
  checkSubmission(value) {
    // console.log("final form",value)
    this.finalizeBoolean.emit(value.target.checked)
    // console.log("Check", this.finalizeBoolean.emit(value.target.checked))
    this.isCheckedChange.emit(value.target.checked);
    this.submission_ready = value.target.checked;

    if(value.target.checked) {
      this.finalize_form =  true
    } else {
      this.finalize_form =  false
    this.resetForm();
    }
    this.getUserName(null);
    this.pathAuthSignature64 = this.speciality2?.digital_signature_64;
  }

  checkFormValidity() {
    const finalReview = this.pathSignature.get('finalReview').value;
    const otherPathologists = this.pathSignature.get('other_pathologists').value;
    if ((finalReview === 'yes' && otherPathologists) || finalReview === 'no') {
      this.formStateService.updateFormState(true);
    } else {
      this.formStateService.updateFormState(false);
    }
  }

  getUserNamee() {
    const email = JSON.parse(sessionStorage.getItem("user"));
    const doc =  this.doctors.filter((a) => a.email == email.email);
    this.curr_user = email?.id;
    // console.log("ID", this.curr_user);
  }


  private resetForm() {
    // Reset the form control and clear the selected doctor
    this.selectedDoctor = null;
    this.selectedDoctor2 = null;
    this.selectedDoctor3 = null;
    this.otherSelectedDoctor = null;
    this.otherSelectedDoctor2 = null;
    this.otherSelectedDoctor3 = null;
    this.otherSelectedDoctor4 = null;
    this.authSignature2 = null;
    this.authSignature3 = null;
    this.digital_signature2 = null;
    this.digital_signature3 = null;
    this.digital_signature4 = null;
    this.doctor = null;
    this.doctor2 = null;
    this.doctor3 = null;
    this.other_doctor =null;
    this.other_doctor2 =null;
    this.other_doctor3 =null;
    this.other_doctor4 =null;
    this.coSignerName = null;
    this.coSignerSignature = null;
    this.userId = null;
    this.pathAuthSignature = null;
    this.pathSignature.patchValue({
      designation: '',
      designation2: '',
      designation3: '',
      designation4: '',
      finalReview: '',
      finalReview2: '',
      finalReview3: '',
      finalReview4: ''
    });
  }

  noReset(){
    // this.selectedDoctor = null;
    this.selectedDoctor2 = null;
    this.selectedDoctor3 = null;
    this.otherSelectedDoctor = null;
    this.otherSelectedDoctor2 = null;
    this.otherSelectedDoctor3 = null;
    this.otherSelectedDoctor4 = null;
    this.authSignature2 = null;
    this.authSignature3 = null;
    this.digital_signature2 = null;
    this.digital_signature3 = null;
    this.digital_signature4 = null;
    this.doctor = null;
    this.doctor2 = null;
    this.doctor3 = null;
    this.other_doctor =null;
    this.other_doctor2 =null;
    this.other_doctor3 =null;
    this.other_doctor4 =null;
    this.coSignerName = null;
    this.coSignerSignature = null;
this.createPayLoad()
  }
  onPeerReview(){
    this.peerReview
    // console.log("peer review" , this.peerReview)
  }
  public triggerCheckSubmission(value: boolean) {
    this.isChecked = value;
    this.checkSubmission({ target: { checked: value } });
  }
  createPayLoad() {
    let pathologistsValue = this.pathSignature.get('pathologists').value;
    let otherPathologistsValue = this.doctor;
    let otherPathologistsValue2 = this.doctor2;
    let otherPathologistsValue3 = this.doctor3;
    
    let designationValue = this.pathSignature.get('designation').value;
    let designationValue2 = this.pathSignature.get('designation2').value;
  
    if (this.pathSignature.get('finalReview').value === 'yes') {
      // If Yes is selected, swap values
      let temp = pathologistsValue;
      pathologistsValue = otherPathologistsValue;
      otherPathologistsValue = temp;

      let tempD = designationValue;
      designationValue = designationValue2;
      designationValue2 = tempD;
    }else if(this.pathSignature.get('finalReview').value === 'no'){
      let temp = pathologistsValue;
      pathologistsValue = temp;
      otherPathologistsValue = this.coSignerName;
    }
  
    if (this.pathSignature.get('finalReview2').value === 'yes') {
      // If Yes is selected, swap values
      let temp = pathologistsValue;
      pathologistsValue = otherPathologistsValue;
      otherPathologistsValue = temp;

    }

    let pathSignatureValue = this.pathAuthSignature;
    let otherPathologistsAuthSignatureValue = this.userId2;
    let otherPathologists2AuthSignatureValue = this.userId3;
    let otherPathologistsA3uthSignatureValue = this.userId4;

    if (this.pathSignature.get('finalReview').value === 'yes') {
      let authTemp = pathSignatureValue;
      pathSignatureValue = otherPathologistsAuthSignatureValue;
      otherPathologistsAuthSignatureValue = authTemp;

    }else if(this.pathSignature.get('finalReview').value === 'no'){
      let authTemp = pathSignatureValue;
      pathSignatureValue = authTemp;
      otherPathologistsAuthSignatureValue = this.coSignerSignature;
    }
  
    if (this.pathSignature.get('finalReview2').value === 'yes') {
      // If Yes is selected, swap values
      let authTemp = pathSignatureValue;
      pathSignatureValue = otherPathologistsAuthSignatureValue;
      otherPathologistsAuthSignatureValue = authTemp;
    }
  
    const payload = {
      submission_ready: this.submission_ready,
      pathologists: pathologistsValue,
      other_pathologists: otherPathologistsValue,
      pathologists_designation: designationValue,
      other_pathologists_designation: designationValue2,
      auth_signature: pathSignatureValue,
      other_pathologists_auth_signature: otherPathologistsAuthSignatureValue,
      peer_review: this.pathSignature.get('finalReview').value === 'yes' ? this.userId : null,
      with_signature: this.with_signature,
      user_id: this.curr_user,
    };
    this.finalizePayload.emit(payload);
    // console.log("FINAL PAYLOAD" , this.finalizePayload)
    return null
  }

  createPay() {
    const payload = {
      submission_ready: this.submission_ready,
      pathologists: this.pathSignature.get('pathologists').value,
      pathologists_designation: this.pathSignature.get('designation').value,
      auth_signature: this.pathAuthSignature,
      auth_signature_64: this.pathAuthSignature64,
      peer_review: this.userId,
    };
    this.finalizePay.emit(payload);
  }

  getUserName(e) {
    const email = JSON.parse(sessionStorage.getItem("user"));
    // console.log("Doctors", this.doctors)
    // console.log("fullname", email)
    this.doctor = e
    const doc =  this.doctors.filter((a) => a.fullname == e)
    // console.log("Doc : ", doc)
    this.userId2 = doc[0]?.id;
    this.authSignature = doc[0]?.digital_signature;
    this.digital_signature2 = doc[0]?.digital_signature_64;
    this.createPayLoad();
    this.createPay()
    
  }
  getUserName2(e) {
    const email = JSON.parse(sessionStorage.getItem("user"));
    // console.log("Doctors", this.doctors)
    // console.log("fullname", email)
    this.doctor2 = e
    const doc =  this.doctors.filter((a) => a.fullname == e)
    // console.log("Doc : ", doc)
    this.userId3 = doc[0]?.id;
    this.authSignature2 = doc[0]?.digital_signature;
    this.digital_signature3 = doc[0]?.digital_signature_64;
    this.createPayLoad();
    this.createPay();
    
  }
  getUserName3(e) {
    const email = JSON.parse(sessionStorage.getItem("user"));
    // console.log("Doctors", this.doctors)
    // console.log("fullname", email)
    this.doctor3 = e
    const doc =  this.doctors.filter((a) => a.fullname == e)
    // console.log("Doc : ", doc)
    this.userId4 = doc[0]?.id;
    this.authSignature3 = doc[0]?.digital_signature;
    this.digital_signature4 = doc[0]?.digital_signature_64;
    this.createPayLoad();
    this.createPay();
  }

designation(){
  this.pathologists_designation = this.pathSignature.get('designation').value;
  this.createPayLoad();
  this.createPay();

}
designation2(){
  this.pathologists_designation2 = this.pathSignature.get('designation').value;
  this.createPayLoad();
  this.createPay();

}

  otherDoctor(e) {
    this.other_doctor = e;
    const doc =  this.doctors.filter((a) => a.fullname == e)
    // console.log("Doc : ", doc)
    this.userId = doc[0]?.id;
    this.coSignerName = doc[0]?.fullname;
    this.coSignerSignature = doc[0]?.digital_signature;
    this.createPayLoad();
    this.createPay()
    console.log("value of first doctor", this.selectedDoctor)
    console.log("doctors array", this.doctors)
  }
  otherDoctor2(e) {
    this.other_doctor2 = e;
    const doc =  this.doctors.filter((a) => a.fullname == e)
    // console.log("Doc : ", doc)
    this.userId = doc[0]?.id;
    this.createPayLoad();
    this.createPay()
  }
  
  addNewItem() {
    const name = 'Hello'
    this.finalizePayload.emit(name);
  }

}