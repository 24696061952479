import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PathologistWordService {
  patholoBoolean: boolean;
  private _successMsgSource = new Subject<boolean>();
  successMsg$ = this._successMsgSource.asObservable();


  constructor() { }

//  pathologyword(specimen) {
//   const created = specimen
//   const dateOne = new Date(2023,9,21)
//   const oldUpdate = created.split('-');
//   const one = Number(oldUpdate[0]);
//   console.log(one)
//   const two = Number(oldUpdate[1]);
//   console.log(two)
//   const three = Number(oldUpdate[2]);
//   console.log(three)

//   const newUpdate = new Date(one, two, three)
//   console.log('Update one',newUpdate)
//   console.log('DateOne', dateOne)
//   console.log( created)

//   console.log('Testing',dateOne < newUpdate)

//   this.patholoBoolean = dateOne < newUpdate;
//   // return this.patholoBoolean;
//   this.pathAction.next(this.patholoBoolean)

//  }

sendSuccessMsg(specimen) {
    const created = specimen
  const dateOne = new Date(2023,9,25)
  const oldUpdate = created.split('-');
  const one = Number(oldUpdate[0]);
  // console.log(one)
  const two = Number(oldUpdate[1]);
  // console.log(two)
  const three = Number(oldUpdate[2]);
  // console.log(three)

  const newUpdate = new Date(one, two, three)
  // console.log('Update one',newUpdate)
  // console.log('DateOne', dateOne)
  // console.log( created)

  // console.log('Testing',dateOne < newUpdate)

  this.patholoBoolean = dateOne < newUpdate;
  // return this.patholoBoolean;
  this._successMsgSource.next(this.patholoBoolean);
  }


}


