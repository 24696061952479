<div *ngIf="permissions.expenses.view" class="row mb-4 align-items-center" style="margin-top: -133px">
    <div class="col-lg-6 col-md-6">
      <h3 class="">Category</h3>
    </div>
    <div class="col-lg-6 col-md-6 text-right">
      <button *ngIf="permissions.expenses.view " class="btn btn-round btn-warning font-weight-bold text-uppercase mr-2" (click)="addNewCategory()">
        <i class="fa fa-plus"></i> New Category
      </button>
      <!-- <button *ngIf="permissions.expenses.view" class="btn btn-round btn-success font-weight-bold text-uppercase mr-2" (click)="addNewExpense()">
        <i class="fa fa-plus"></i> New Expense
      </button> -->
    </div>
  </div>
  
  <!-- <div class="card" *ngIf="permissions.expenses.view">
    <div class="card-header py-2">
      <div class="row align-items-center">
        <div class="col">
          <h4 class="mb-0">Filter</h4>
        </div>
        <div class="">
          <div class="d-flex align-items-center">
            <div class="">
              <select class="form-control pt-0 pb-1" id="category" (change)="onCategoryChange($event.target.value)">
                <option *ngFor="let cat of categoryList" value={{cat.category_name}}>{{cat.category_name}}</option>
              </select>
            </div>
            <div [ngClass]="searchVal ? 'd-none' : 'd-flex-inline'" class="col text-right">
              <p class="mb-0 small">Current Data</p>
              <h5 class="mr-0">{{year}}</h5>
            </div>
            <div [ngClass]="searchVal ? 'd-flex-inline' : 'd-none'" class="col text-right">
              <button (click)="clear()" class="btn btn-danger btn-sm"
                      type="button">
                Clear
              </button>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <!-- <div class="card-body" *ngIf="permissions.expenses.view">
      <form (submit)="onIntervalSubmit($event)" action="">
        <div class="row">
          <div class="col-md-6 col-sm-12">
            <input class="form-control" id="from" onblur="(this.type='text')"
                   onfocus="(this.type='date')"
                   placeholder="Click to enter date" required type="text">
            <label for="from">From:</label>
          </div>
          <div class="col-md-6 col-sm-12">
            <input class="form-control" id="to" onblur="(this.type='text')"
                   onfocus="(this.type='date')"
                   placeholder="Click to enter date" required type="text">
            <label for="to">To:</label>
          </div>
          <div class="col-md-12 col-sm-12 d-flex justify-content-center">
            <button [disabled]="false"
                    class="btn btn-success"
                    style="padding:10px 15%;"
                    type="submit">
              <span *ngIf="this.loading"   role="status"></span>
              Search<span *ngIf="this.loading" class="load-text">ing...</span>
            </button>
          </div>
        </div>
      </form>
    </div> -->
  <!-- </div> -->
  
  <div class="row" *ngIf="permissions.expenses.view">
    <!-- <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6">
      <div class="card">
        <div class="card-body text-center">
          <h5 class="">Expense Items</h5>
          <hr>
          <h5>{{searchExpenses.length}}</h5>
        </div>
      </div>
    </div> -->
    <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6" *ngIf="permissions.expenses.view">
      <div class="card">
        <div class="card-body text-center">
          <h5 class="">Total Categories</h5>
          <hr>
          <h5>{{allCategory.length}}</h5>
        </div>
      </div>
    </div>
    <!-- <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6" *ngIf="permissions.expenses.view">
      <div class="card">
        <div class="card-body text-center">
          <h5 class="">Total Amount</h5>
          <hr>
          <h5>GH₵ {{totalAmount | number: "1.2"}}</h5>
        </div>
      </div>
    </div> -->
  </div>
  
  <!-- CATEGORY PAGE -->
  <!-- <div class="card" *ngIf="permissions.expenses.view">
    <div class="card-body table-responsive pt-0">
      <div class="row justify-content-end"> -->
        <!-- <div class="col-sm-5">
          &nbsp;
          <h2>Expenses Table</h2>
        </div> -->
        <!-- <div class="col-sm-3">
          &nbsp;
          <input class="form-control" id="search" [(ngModel)]="searchText" type="text">
          <label class="label px-2 py-2" for="search">Search</label>
        </div>
      </div> -->
          <!-- <div>
            <table class="table table-bordered table-striped mb-0" id="report-table">
              <thead>
                  <tr>
                      <th style="padding-left: 10px; padding-right: 10px">Date</th>
                      <th style="padding-left: 10px; padding-right: 10px">Expense</th>
                      <th style="padding-left: 10px; padding-right: 10px">Category</th>
                      <th style="padding-left: 10px; padding-right: 10px">Amount</th>
                      <th style="padding-left: 10px; padding-right: 10px">Last Edit</th>
                      <th style="padding-left: 10px; padding-right: 10px">Actions</th>
                  </tr>
              </thead>
              <tbody>
                <tr *ngFor="let expense of searchExpenses | filter: searchText | paginate: { itemsPerPage: 10, currentPage: p };">
                  <td>{{expense.created_at | date: "MMM d, y":"GMT"}}</td>
                  <td>{{expense.expense}}</td>
                  <td>{{expense.category}}</td>
                  <td>GH₵ {{expense.amount | number: "1.2"}}</td>
                  <td>{{expense.updated_at | date: "MMM d, y":"GMT"}}</td>
                  <td>
                    <button (click)="view(expense?.id); modalView.show()" class="btn waves-effect waves-dark btn-secondary btn-icon" [placement]="'top'" ngbTooltip="VIEW" data-backdrop="static">
                        <i class="feather icon-eye"></i>
                    </button>
  
                    &nbsp;
  
                    <button (click)="edit(expense?.id); modalEdit.show()" class="btn waves-effect waves-dark btn-warning btn-icon" [placement]="'top'" ngbTooltip="EDIT" data-backdrop="static">
                        <i class="feather icon-edit"></i>
                    </button>
  
                    &nbsp;
  
                    <button (click)="delete(expense?.id);" class="btn waves-effect waves-dark btn-danger btn-icon" [placement]="'top'" ngbTooltip="DELETE" data-backdrop="static">
                        <i class="feather icon-trash"></i>
                    </button>
                </td>
                </tr>
              </tbody>
            </table>
          </div> -->
  
    <!-- </div> -->
    <!-- <div class="card-footer">
      <pagination-controls
        (pageBoundsCorrection)="p = $event"
        (pageChange)="p = $event"
        autoHide="false"
        directionLinks="true"
        maxSize="9"
        nextLabel="Next"
        previousLabel="Previous"
        responsive="true"
        screenReaderCurrentLabel="You're on page"
        screenReaderPageLabel="page"
        screenReaderPaginationLabel="Pagination"
        style="float: right"
      >
      </pagination-controls>
    </div> -->
  <!-- </div> -->
  <!-- END CATEGORY PAGE -->
            <div class="card">
              <div class="card-body table-responsive pt-0">
                <div class="row justify-content-end">
                  <div class="col-sm-5">
                    &nbsp;
                    <h4>Categories Table</h4>
                  </div>
                  <div class="col-sm-3">
                    &nbsp;
                    <input class="form-control" id="search" [(ngModel)]="searchText" type="text">
                    <label class="label px-2 py-2" for="search">Search</label>
                  </div>
                </div>
                    <div>
                      <table class="table table-bordered table-striped mb-0" id="report-table">
                        <thead>
                            <tr>
                                <th style="padding-left: 10px; padding-right: 10px">Id</th>
                                <th style="padding-left: 10px; padding-right: 10px">Category</th>
                                <th style="padding-left: 10px; padding-right: 10px">Date Created</th>
                                <th style="padding-left: 10px; padding-right: 10px">Date Updated</th>
                                <th style="padding-left: 10px; padding-right: 10px">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let category of allCategory | filter: searchText | paginate: { itemsPerPage: 10, currentPage: p };">
                            <td>{{category.id}}</td>
                            <td>{{category.category_name}}</td>
                            <td>{{category.created_at | date: "MMM d, y":"GMT"}}</td>
                            <td>{{category.updated_at | date: "MMM d, y":"GMT"}}</td>
                            <td>
                              <button (click)="editCat(category?.id); editCategoryModal.show()" class="btn waves-effect waves-dark btn-warning btn-icon" [placement]="'top'" ngbTooltip="EDIT" data-backdrop="static">
                                  <i class="feather icon-edit"></i>
                              </button>
  
                              &nbsp;
  
                              <button (click)="deleteCat(category?.id);" class="btn waves-effect waves-dark btn-danger btn-icon" [placement]="'top'" ngbTooltip="DELETE" data-backdrop="static">
                                  <i class="feather icon-trash"></i>
                              </button> 
                          </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
  
              </div>
              <div class="card-footer">
                <pagination-controls
                  (pageBoundsCorrection)="p = $event"
                  (pageChange)="p = $event"
                  autoHide="false"
                  directionLinks="true"
                  maxSize="9"
                  nextLabel="Next"
                  previousLabel="Previous"
                  responsive="true"
                  screenReaderCurrentLabel="You're on page"
                  screenReaderPageLabel="page"
                  screenReaderPaginationLabel="Pagination"
                  style="float: right"
                >
                </pagination-controls>
              </div>
            </div>
  
              <!-- -------------------------------------------------------------------------------- VIEW EXPENSES--------------------------------------------------------------------------------------- -->
              <app-ui-modal #modalView [containerClick]="false" [dialogClass]="'modal-xl btn-page'" class="doNotPrint">
                <div class="app-modal-header">
                    <h5 class="modal-title">View Expense</h5>
                </div>
                <div class="app-modal-header">
                    <button type="button" class="btn btn-danger" data-dismiss="modal"  (click)="modalView.hide()">Close</button>
                </div>
                <div class="app-modal-body main-look" id="printThis">
                  <div class="no-gutters">
                      <div class="col-sm-12">
                          <img style="width: 100%; height: 160px;">
                      </div>
                  </div>
                  <div class="row mb-4 border-bottom">
                    <div class="col-sm-12">
                      <h2 class="text-center">Expense Details</h2>
                    </div>
                  </div>
  
                  <!-- <div class="pdf-title row" style="text-align:center;">
                      <div class="col-sm-12">
                          <h5>Expense</h5>
                      </div>
                  </div> -->
                  <div class="row mb-4">
                    <div class="col-sm-8">
                      <address>
                        <p><strong>Expense Date: </strong> {{ viewExpenses?.created_at | date: "MMM d, y":"GMT" }}</p>
                        <p><strong>Expense ID: </strong> {{ viewExpenses?.id }}</p>
                      </address>
                    </div>
                    <div class="col-sm-4">
                     <p>
                        <strong>Expense Category: </strong>
                        <span class="badge badge-success p-1">
                          {{ viewExpenses?.category }}
                         </span>
                      </p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12">
                      <table class="table" width="100%">
                        <thead class="thead-light">
                        <tr class="bg-black table-bordered">
                          <th width="35%">Expense</th>
                          <th>Amount</th>
                          <th class="">Updated at</th>
                        </tr>
                        </thead>
                        <tbody>
                          <tr class="table-bordered">
                            <td>{{ viewExpenses?.expense }}</td>
                            <td>GH₵ {{viewExpenses?.amount | number: "1.2"}}</td>
                            <td>{{viewExpenses?.updated_at | date: "MMM d, y":"GMT"}}</td>
                            </tr>
                        </tbody>
                      </table>
                      </div>
                      </div>
                </div>
                <div class="app-modal-footer">
                  <button type="button" *ngIf="permissions.itemsServices.print" class="btn btn-secondary doNotPrint" (click)="printMe(); modalView.hide()"><i class="feather icon-book"></i>&nbsp; Print</button>&nbsp;
                  <button type="button" class="btn btn-danger" data-dismiss="modal"  (click)="modalView.hide()"><span class="fa fa-times-circle"> </span> Close</button>
              </div>
          </app-ui-modal>
          <!-- .......................................................................................END VIEW EXPENSES............................................................................... -->
          <!-- ........................................................................................EDIT EXPENSES.......................................................................................... -->
          <app-ui-modal #modalEdit [hideFooter]="false" [containerClick]="false" [dialogClass]="'modal-xl'">
            <div class="app-modal-header">
              <h4 class="modal-title" style="color:#0873ee;text-transform:uppercase">Edit Expense</h4>
            </div>
            <div class="app-modal-header">
                <button type="button" class="btn btn-danger" (click)="modalEdit.hide();">Close</button>
            </div>
            <!-- edit here -->
            <div class="app-modal-body main-look">
              <form [formGroup]="expenseEditForm">
                <div class="row">
                  <div class="col-sm-12 mb-4">
                    <input class="form-control" id="expense" formControlName="expense"placeholder="Expense Item" required type="text">
                    <label class="label" for="expense">Expense</label>
                  </div>
                  <div class="col-sm-6 mb-4">
                    <select class="form-control" id="selCategory" formControlName="category" required>
                      <option value="">Select Category</option>
                      <option *ngFor="let cat of allCategory" value={{cat.category_name}}>{{cat.category_name}}</option>
                    </select>
                    <label class="label" for="selCategory">Select Category</label>
                  </div>
                  <div class="col-sm-6 mb-4">
                    <input class="form-control" id="amount" formControlName="amount" placeholder="Amount" required type="number" step="0.01">
                    <label class="label" for="amount">Amount</label>
                  </div>
                  <div class="col-md-12 col-sm-12 d-flex justify-content-center mt-3">
                    <button type="button" 
                    class="btn btn-success"
                    style="padding:10px 15%;" 
                    (click)="editExpenseCard(); expenseEditForm.reset(); modalEdit.hide()"
                    >Submit</button>&nbsp;
                    <!-- <button [disabled]="false"
                            class="btn btn-success"
                            style="padding:10px 15%;"
                            type="submit">
                      <span (click)="editExpenseCard(); expenseEditForm.reset(); modalEdit.hide()"   role="status"></span>
                      Submit<span *ngIf="this.loading" class="load-text">ing...</span>
                    </button> -->
                  </div>
                </div>
              </form>
              </div>
            <div class="app-modal-footer">
              <!-- <button type="button" class="btn btn-success" (click)="editExpenseCard(); expenseEditForm.reset(); modalEdit.hide()">Save</button>&nbsp; -->
              <button type="button" class="btn btn-danger" (click)="modalEdit.hide();">Close</button>
          </div>
      </app-ui-modal>
          <!-- ........................................................................................END EDIT EXPENSES ....................................................................................-->
  
  <!-- -------------------------------------------------------------------------------- VIEW --------------------------------------------------------------------------------------- -->
  <!-- start of create expense #expenseForm="ngForm" (submit)="onAddExpenseSubmit()"-->
  <app-ui-modal #addExpenseModal [containerClick]="false" [dialogClass]="'modal-lg btn-page'" class="doNotPrint">
    <div class="app-modal-header">
      <h4 class="modal-title" style="color:#0873ee;text-transform:uppercase">Add New Expense</h4>
    </div>
    <div class="app-modal-header">
      <button (click)="addExpenseModal.hide()" class="btn btn-danger" data-dismiss="modal" type="button">Close</button>
    </div>
    <div class="app-modal-body main-look">
      <form  [formGroup]="expenseForm">
        <div class="row">
          <div class="col-sm-12 mb-4">
            <input class="form-control" id="expense" formControlName="expense" placeholder="Expense Item" required type="text">
            <label class="label" for="expense">Expense</label>
          </div>
          <div class="col-sm-6 mb-4">
            <select class="form-control" id="selCategory" formControlName="category" required>
              <option value="">Select Category</option>
              <option *ngFor="let cat of allCategory" value={{cat.category_name}}>{{cat.category_name}}</option>
            </select>
            <label class="label" for="selCategory">Select Category</label>
          </div>
          <div class="col-sm-6 mb-4">
            <input class="form-control" id="amount" formControlName="amount" placeholder="Amount" required type="number" step="0.01">
            <label class="label" for="amount">Amount</label>
          </div>
          <div class="col-md-12 col-sm-12 d-flex justify-content-center mt-3">
            <button type="button" 
            class="btn btn-success" 
            style="padding:10px 15%;"
            (click)="createExpenseCard(); expenseForm.reset(); addExpenseModal.hide()"
            >Submit</button>&nbsp;
            <!-- <button [disabled]="false"
                    class="btn btn-success"
                    style="padding:10px 15%;"
                    type="submit">
              <span (click)="createExpenseCard(); expenseForm.reset(); addExpenseModal.hide()"   role="status"></span>
              Submit<span *ngIf="this.loading" class="load-text">ing...</span>
            </button> -->
          </div>
        </div>
      </form>
    </div>
    <div class="app-modal-footer">
      <!-- <button type="button" class="btn btn-success" (click)="createExpenseCard(); expenseForm.reset(); addExpenseModal.hide()">Save</button>&nbsp; -->
      <button (click)="addExpenseModal.hide();" class="btn btn-danger" type="button">Close</button>
    </div>
  </app-ui-modal>
  <!-- end of create expense -->
  
  <app-ui-modal #addCategoryModal [containerClick]="false" [dialogClass]="'modal-lg btn-page'" class="doNotPrint">
    <div class="app-modal-header">
      <h4 class="modal-title" style="color:#0873ee;text-transform:uppercase">Add New Category</h4>
    </div>
    <div class="app-modal-header">
      <button (click)="addCategoryModal.hide()" class="btn btn-danger" data-dismiss="modal" type="button">Close</button>
    </div>
    <div class="app-modal-body main-look">
      <form [formGroup]="categoryForm">
        <div class="row">
          <div class="col-sm-12 mb-4">
            <input class="form-control" id="addCategory" formControlName="category_name" placeholder="" required type="text">
            <label class="label" for="addCategory">Category Name</label>
          </div>
          <div class="col-md-12 col-sm-12 d-flex justify-content-center">
            <button type="button" 
            class="btn btn-success" 
            style="padding:10px 15%;"
            (click)="createCategoryCard(); categoryForm.reset(); addCategoryModal.hide()"
            >Submit</button>&nbsp;
            <!-- <button [disabled]="false"
                    class="btn btn-success"
                    style="padding:10px 15%;"
                    type="submit">
              <span (click)="createCategoryCard(); categoryForm.reset(); addCategoryModal.hide()" role="status"></span>
              Submit<span *ngIf="this.loading" class="load-text">ing...</span>
            </button> -->
          </div>
        </div>
      </form>
    </div>
    <div class="app-modal-footer">
      <!-- <button type="button" class="btn btn-success" (click)="createCategoryCard(); categoryForm.reset(); addCategoryModal.hide()">Save</button>&nbsp; -->
      <button (click)="addCategoryModal.hide();" class="btn btn-danger" type="button">Close</button>
    </div>
  </app-ui-modal>
  <!----------------------------------------------------------- EDIT CATEGORY ------------------------------------------------------>
  <app-ui-modal #editCategoryModal [containerClick]="false" [dialogClass]="'modal-lg btn-page'" class="doNotPrint">
    <div class="app-modal-header">
      <h4 class="modal-title" style="color:#0873ee;text-transform:uppercase">Add New Category</h4>
    </div>
    <div class="app-modal-header">
      <button (click)="editCategoryModal.hide()" class="btn btn-danger" data-dismiss="modal" type="button">Close</button>
    </div>
    <div class="app-modal-body main-look">
      <form [formGroup]="categoryEditForm">
        <div class="row">
          <div class="col-sm-12 mb-4">
            <input class="form-control" id="addCategory" placeholder="" formControlName="category_name" required type="text">
            <label class="label" for="addCategory">Category Name</label>
          </div>
          <div class="col-md-12 col-sm-12 d-flex justify-content-center">
            <button type="button" 
            class="btn btn-success"
            style="padding:10px 15%;" 
            (click)="editCategoryCard(); categoryEditForm.reset(); editCategoryModal.hide()"
            >Submit</button>&nbsp;
            <!-- <button [disabled]="false"
                    class="btn btn-success"
                    style="padding:10px 15%;"
                    type="submit">
              <i class="fa fa-spinner fa-pulse" *ngIf="loading"></i>
              <span  (click)="editCategoryCard(); categoryEditForm.reset(); editCategoryModal.hide()" role="status"></span>
              Submit
              <span *ngIf="this.loading" class="load-text">ing...</span>
            </button> -->
          </div>
        </div>
      </form>
    </div>
    <div class="app-modal-footer">
      <!-- <button type="button" class="btn btn-success" (click)="editCategoryCard(); categoryEditForm.reset(); editCategoryModal.hide()">Save</button>&nbsp; -->
      <button (click)="editCategoryModal.hide();" class="btn btn-danger" type="button">Close</button>
    </div>
  </app-ui-modal>
  <!-- ------------------------------------------------------------------------------ END VIEW ------------------------------------------------------------------------------------- -->
  