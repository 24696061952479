// import { Component, OnInit } from '@angular/core';
// import { AuthenticationService } from 'src/app/others/services/authentication.service';
// import Swal, { SweetAlertOptions } from 'sweetalert2';
// @Component({
//   selector: 'app-key-metrics',
//   templateUrl: './key-metrics.component.html',
//   styleUrls: ['./key-metrics.component.scss']
// })
// export class KeyMetricsComponent implements OnInit {
//   actionButtons = true;
//   permissions: any = [];
//   isCardDisabled: boolean = true; // Set the initial value based on the disabled state of the card

//   constructor(private auth: AuthenticationService,) {
//     this.permissions = {
//     keyMetrics: {
//       view: false
//     },
//   }
//   this.getPermissions();
//    }

//   ngOnInit() {
//     this.getPermissions();
//   }

//   //   getPermissions() {
//   //   this.auth.get('user_permissions/' + sessionStorage.getItem('userID')).subscribe(
//   //     (response: any) => {
//   //       this.permissions = response.permissions.permissions[0];
//   //     },
//   //     (error: any) => {

//   //     }
//   //   );
//   // }
//   getPermissions() {
//     this.auth
//       .get("user_permissions/" + sessionStorage.getItem("userID"))
//       .toPromise()
//       .then((response: any) => {
//         this.permissions = response.permissions.permissions[0];
//         this.actionButtons = Object.keys(this.permissions.keyMetrics).every((key) => {
//           return key == "create" ? false : this.permissions.keyMetrics[key];
//         });

//         if (!this.actionButtons) {
//           // Show a Swal alert if the user has no permissions
//           Swal.fire({
//             icon: 'error',
//             title: 'Permission Denied',
//             text: 'You do not have permission to access this page.',
//           });
//         }
//       });
//   }
// }

// import { Component, OnInit } from '@angular/core';

// @Component({
//   selector: 'app-key-metrics',
//   templateUrl: './key-metrics.component.html',
//   styleUrls: ['./key-metrics.component.scss']
// })
// export class KeyMetricsComponent implements OnInit {


//   isCardDisabled: boolean = true; // Set the initial value based on the disabled state of the card

//   constructor() { }

//   ngOnInit() {
//   }

// }



import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/others/services/authentication.service';
import Swal, { SweetAlertOptions } from 'sweetalert2';
@Component({
  selector: 'app-key-metrics',
  templateUrl: './key-metrics.component.html',
  styleUrls: ['./key-metrics.component.scss']
})
export class KeyMetricsComponent implements OnInit {
  actionButtons = true;
  permissions: any = [];
  isCardDisabled: boolean = true; // Set the initial value based on the disabled state of the card

  constructor(private auth: AuthenticationService,) {
  }

  ngOnInit() {
    this.getPermissions();
  }

  //   getPermissions() {
  //   this.auth.get('user_permissions/' + sessionStorage.getItem('userID')).subscribe(
  //     (response: any) => {
  //       this.permissions = response.permissions.permissions[0];
  //     },
  //     (error: any) => {

  //     }
  //   );
  // }
  getPermissions() {
    this.permissions = {
      keyMetrics: {
        access: false
      },
    };
    this.auth
      .get("user_permissions/" + sessionStorage.getItem("userID"))
      .toPromise()
      .then((response: any) => {
        this.permissions = response.permissions.permissions[0];
        // this.permissions.keyMetrics.access = Object.keys(this.permissions.keyMetrics).every((key) => {
        //   return key == "create" ? false : this.permissions.keyMetrics[key];
        // });
        if (!this.permissions.keyMetrics.access) {
          // Show a Swal alert if the user has no permissions
          Swal.fire({
            icon: 'error',
            title: 'Permission Denied',
            text: 'You do not have permission to access this page.',
          });
        }
      });
  }

}
