import { Component, OnInit } from "@angular/core";
import { AuthenticationService } from "../../../others/services/authentication.service";
import { NgxSpinnerService } from "ngx-spinner";
import { GeneralService } from "../../../services/general.service";
import { AlertService } from "../../../others/services/alert.service";
import { isAfter, isBefore, isEqual } from "date-fns";
declare let $: any;

@Component({
  selector: "app-balance",
  templateUrl: "./balance.component.html",
  styleUrls: ["./balance.component.scss"],
})
export class BalanceComponent implements OnInit {
  searchVal = false;
  loading = false;
  balanceList = [];
  incomeList = [];
  expenseList = [];
  yearIncomeList = [];
  yearExpenseList = [];
  searchedIncomeList = [];
  searchedExpenseList = [];
  fromDate = "";
  toDate = "";

  totalIncome = 0;
  totalExpense = 0;

  year = new Date().getFullYear();
  actionButtons = true;

  permissions: any = [];
  constructor(
    public auth: AuthenticationService,
    private spinner: NgxSpinnerService,
    private generalService: GeneralService,
    private alert: AlertService
  ) {}

  user_array = (str) => {
    return str.trim().split(",");
   };

  singleCurrency = this.user_array(sessionStorage.getItem("currency"));
  currencyNme = this.singleCurrency[0];
  currencySym = this.singleCurrency[1];
  currencyBase = this.singleCurrency[2];

  ngOnInit() {
    this.getBalance();
    this.getPermissions();
  }


   // Get user permissions
  //  getPermissions() {
  //   this.permissions = {
  //     balance: {
  //       view: false
  //     },
  //   };

  //   this.auth
  //     .get("user_permissions/" + sessionStorage.getItem("userID")).toPromise()
  //     .then(
  //       (response: any) => {
  //         this.permissions = response.permissions.permissions[0];
  //         this.actionButtons = Object.keys(this.permissions.balance).every((key) => {
  //           return key == "create" ? false : this.permissions.balance[key];
  //         })
  //       }
  //     );
  // }
  getPermissions() {
    this.auth.get('user_permissions/' + sessionStorage.getItem('userID')).subscribe(
      (response: any) => {
        this.permissions = response.permissions.permissions[0];
      },
      (error: any) => {

      }
    );
  }

  getBalance(): void {
    this.auth.get("balance").subscribe((response: any) => {
      this.incomeList = response.amount_paid;
      this.expenseList = response.expenses;

      this.searchedExpenseList = this.yearExpenseList = this.getYearList(
        this.expenseList
      );
      this.searchedIncomeList = this.yearIncomeList = this.getYearList(
        this.incomeList
      );
      this.getTotalExpense();
      this.getTotalIncome();
    });
  }

  getYearList(lists: any) {
    return lists.filter(
      (ele) =>
        new Date(ele.created_at).getFullYear() === new Date().getFullYear()
    );
  }

  getTotalIncome() {
    let a = 0;
    for (const income of this.searchedIncomeList) {
      a += income.amount_paid;
    }

    this.totalIncome = a;
  }

  getTotalExpense() {
    let a = 0;
    for (const expense of this.searchedExpenseList) {
      a += expense.amount;
    }
    this.totalExpense = a;
  }

  clear() {
    $("#to").val("");
    $("#from").val("");
    this.searchedExpenseList = this.yearExpenseList;
    this.searchedIncomeList = this.yearIncomeList;
    this.getTotalExpense();
    this.getTotalIncome();
    this.searchVal = false;
  }

  onIntervalSubmit($event: Event) {
    $event.preventDefault();
    const fromVal = $("#from").val();
    const toVal = $("#to").val();

    if (fromVal !== "" && toVal !== "") {
      this.searchVal = true;
      this.loading = true;

      /* Converting the date to a string and then converting it back to a date. */
      const from = new Date(new Date(fromVal).toDateString());
      const to = new Date(new Date(toVal).toDateString());

      this.fromDate = from.toDateString();
      this.toDate = to.toDateString();

      /* Filtering the tatList array to only include the elements that have a created_at date that is equal to or after the
     from date and equal to or before the to date. */
      this.searchedIncomeList = this.incomeList.filter((ele) => {
        const createdAt = new Date(new Date(ele.created_at).toDateString());
        return (
          (isEqual(from, createdAt) || isAfter(createdAt, from)) &&
          (isEqual(to, createdAt) || isBefore(createdAt, to))
        );
      });

      this.searchedExpenseList = this.expenseList.filter((ele) => {
        const createdAt = new Date(new Date(ele.created_at).toDateString());
        return (
          (isEqual(from, createdAt) || isAfter(createdAt, from)) &&
          (isEqual(to, createdAt) || isBefore(createdAt, to))
        );
      });
      this.getTotalExpense();
      this.getTotalIncome();
      this.loading = false;
    } else {
      this.generalService.emptyDateFieldError();
    }
  }
}
