<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="medium" color="#fff" type="ball-clip-rotate-pulse"></ngx-spinner>
<perfect-scrollbar *ngIf="this.nextConfig['layout'] === 'vertical'" (clickOutside)="navMob()" [excludeBeforeClick]="true" [exclude]="'#mobile-collapse'" id="nav-ps-next" ngClass="able-pro-scroll" [style.max-width]="'300px'" [style.max-height]="'calc(100vh - 60px)'" [usePSClass]="'next'" [disabled]="null">
  <div class="navbar-content">
    <div class="">
      <div class="main-menu-header">
        <img class="img-radius" src="{{ user?.profile_picture !== null ? user?.profile_picture : 'assets/images/user/avatar.png' }}" alt="User-Profile-Image" style="width: 60px; height: 60px;">
        <div class="user-details" (click)="isNavProfile = !isNavProfile">
          <div id="more-details">{{ user?.othernames !== null ? user?.othernames : username }} <i class="fa fa-caret-down" [ngClass]="{'fa-caret-up': isNavProfile }"></i></div>
        </div>
      </div>
      <div class="collapse" id="nav-user-link" *ngIf="isNavProfile" [style.display]="'block'">
        <ul class="list-unstyled">
          <a [routerLink]="['/profile']"><li class="list-group-item mainHover mainActive"><i class="feather icon-user m-r-5"></i>View Profile</li></a>
          <a [routerLink]="['/facility-profile']"><li class="list-group-item mainHover mainActive"><i class="feather icon-settings m-r-5"></i>Settings</li></a>
          <a (click)="logout()"><li class="list-group-item aHover mainActive"><i class="feather icon-power m-r-5"></i>Logout</li></a>
        </ul>
      </div>
    </div>
    <ul class="nav pcoded-inner-navbar" (clickOutside)="fireOutClick()">

      <ng-container *ngFor="let item of navigation">
        <!-- <div>{{navigation | json}}</div> -->
        <app-nav-group *ngIf="item.type=='group'" [item]="item"></app-nav-group>
        <app-nav-collapse *ngIf="item.type=='collapse'" [item]="item"></app-nav-collapse>
        <app-nav-item *ngIf="item.type=='item'" [item]="item"></app-nav-item>
      </ng-container>
    </ul>
    <app-card [hidHeader]="true" cardClass="text-center">
      <i class="feather icon-sunset f-40"></i>
      <h6 class="mt-3">Help?</h6>
      <p>Need support or have suggestions?</p>
      <a [routerLink]="['/contact-support']" routerLinkActive="router-link-active" class="btn btn-primary btn-sm text-white m-0 contact-btn">Contact Us</a>
    </app-card>
  </div>
</perfect-scrollbar>

<div *ngIf="this.nextConfig['layout'] === 'horizontal'" #navbarWrapper class="navbar-content sidenav-horizontal" id="layout-sidenav">
  <a href="javascript:" class="sidenav-horizontal-prev" [ngClass]="prevDisabled" (click)="scrollMinus()"></a>
  <div class="sidenav-horizontal-wrapper">
    <!-- add 14-4 viral -->
    <ul #navbarContent id="side-nav-horizontal" class="nav pcoded-inner-navbar sidenav-inner" (clickOutside)="fireLeave()" (mouseleave)="fireLeave()">
      <ng-container *ngFor="let item of navigation">
        <app-nav-group *ngIf="item.type=='group'" [item]="item"></app-nav-group>
        <app-nav-collapse *ngIf="item.type=='collapse'" [item]="item"></app-nav-collapse>
        <app-nav-item *ngIf="item.type=='item'" [item]="item"></app-nav-item>
      </ng-container>
    </ul>
  </div>
  <a href="javascript:" class="sidenav-horizontal-next" [ngClass]="nextDisabled" (click)="scrollPlus()"></a>
</div>
