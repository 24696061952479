import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ageTransform'
})
export class AgeTransformPipe implements PipeTransform {
  age:any;
  transform(value: any) {
    let now = new Date();
    let today = new Date(now.getFullYear(), now.getMonth(), now.getDate());

    let yearNow = now.getFullYear();
    let monthNow = now.getMonth();
    let dateNow = now.getDate();

    if (value == null) {
      value = "null";
    }

    let dob = new Date(
      value.substr(0, 4),
      value.substr(5, 2) - 1,
      value.substr(8, 2)
    );

    let yearDob = dob.getFullYear();
    let monthDob = dob.getMonth();
    let dateDob = dob.getDate();

    let yearString = "";
    let monthString = "";
    let dayString = "";
    let dateAge;
    let monthAge;

    let yearAge = yearNow - yearDob;

    if (monthNow >= monthDob) monthAge = monthNow - monthDob;
    else {
      yearAge--;
      monthAge = 12 + monthNow - monthDob;
    }

    if (dateNow >= dateDob) dateAge = dateNow - dateDob;
    else {
      monthAge--;
      dateAge = 31 + dateNow - dateDob;

      if (monthAge < 0) {
        monthAge = 11;
        yearAge--;
      }
    }

    let age = {
      years: yearAge,
      months: monthAge,
      days: dateAge,
    };

    if (age.years > 1) yearString = " years";
    else yearString = " year";
    if (age.months > 1) monthString = " months";
    else monthString = " month";
    if (age.days > 1) dayString = " days";
    else dayString = " day";

    if (age.years > 0 && age.months > 0 && age.days > 0) {
      this.age = age.years + yearString + " old";
    } else if (age.years == 0 && age.months == 0 && age.days > 0) {
      this.age = age.days + dayString + " old";
    } else if (age.years > 0 && age.months == 0 && age.days == 0) {
      this.age = age.years + yearString + " old";
    } else if (age.years > 0 && age.months > 0 && age.days == 0) {
      this.age = age.years + yearString + " old";
    } else if (age.years == 0 && age.months > 0 && age.days > 0) {
      this.age = age.months + monthString + " old";
    } else if (age.years > 0 && age.months == 0 && age.days > 0) {
      this.age = age.years + yearString + " old";
    } else if (age.years == 0 && age.months > 0 && age.days == 0) {
      this.age = age.months + monthString + " old";
    } else {
      this.age = "0";
    }

    return this.age;
  }

}
