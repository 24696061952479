import { UtilityService } from './../../../../../others/services/utility.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nav-left',
  templateUrl: './nav-left.component.html',
  styleUrls: ['./nav-left.component.scss']
})
export class NavLeftComponent implements OnInit {

  constructor(private utility:UtilityService) { }

  ngOnInit() {
    this.utility.idleLogout();
    this.utility.checkMultipleLogins();
  }

}
