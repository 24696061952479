import { Component, NgZone, OnInit } from '@angular/core';
import { NextConfig } from '../../../app-config';
import { Location } from '@angular/common';
import { AuthenticationService } from "src/app/others/services/authentication.service";
import { NavigationEnd, Router } from "@angular/router";

@Component({
  selector: "app-admin",
  templateUrl: "./admin.component.html",
  styleUrls: ["./admin.component.scss"],
})
export class AdminComponent implements OnInit {
  public nextConfig: any;
  public navCollapsed: boolean;
  public navCollapsedMob: boolean;
  public windowWidth: number;

  loggedIn: boolean = false;
  isExpire: boolean = false;/* check for subscription */

  constructor(
    private auth: AuthenticationService,
    private zone: NgZone,
    private location: Location,
    private router: Router
  ) {
    this.checkLogin();
    // this.loggedIn = JSON.parse(sessionStorage.getItem("checkIn"));
    this.nextConfig = NextConfig.config;
    let currentURL = this.location.path();
    const baseHerf = this.location["_baseHref"];
    if (baseHerf) {
      currentURL = baseHerf + this.location.path();
    }

    this.windowWidth = window.innerWidth;

    if (
      (currentURL === baseHerf + "/layout/collapse-menu" ||
        currentURL === baseHerf + "/layout/box") &&
      this.windowWidth >= 992 &&
      this.windowWidth <= 1024
    ) {
      this.nextConfig.collapseMenu = true;
    }

    this.navCollapsed =
      this.windowWidth >= 992 ? this.nextConfig.collapseMenu : false;
    this.navCollapsedMob = false;
  }

  ngOnInit() {
    if (this.windowWidth < 992) {
      this.nextConfig.layout = "vertical";
      setTimeout(() => {
        document
          .querySelector(".pcoded-navbar")
          .classList.add("menupos-static");
        (document.querySelector(
          "#nav-ps-next"
        ) as HTMLElement).style.maxHeight = "100%"; // 100% amit
      }, 500);
    }
  }

  navMobClick() {
    if (this.windowWidth < 992) {
      if (
        this.navCollapsedMob &&
        !document
          .querySelector("app-navigation.pcoded-navbar")
          .classList.contains("mob-open")
      ) {
        this.navCollapsedMob = !this.navCollapsedMob;
        setTimeout(() => {
          this.navCollapsedMob = !this.navCollapsedMob;
        }, 100);
      } else {
        this.navCollapsedMob = !this.navCollapsedMob;
      }
    }
  }

  checkLogin() {
    const subscription = JSON.parse(
      sessionStorage.getItem("subscription_data")
    );/* retrieve subscription data  */
    if (this.auth.isLoggedIn() === true) {
      this.loggedIn = true;
      this.isExpire = /* subscription.subscriptionExpired */false; //TODO: change this to subscription expire value
      //  console.log(this.isExpire)
    } else {
      this.loggedIn = false;
      this.isExpire = false;
      this.router.navigate(["auth/signin"]);
      // this.logout();
    }
  }

  logout() {
    this.auth.logout();
  }
}
